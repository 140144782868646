import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Stack } from "@mui/system";
import auth from "../../api/auth/auth";
import BackDrop from "../common/backDrop";

const useStyles = makeStyles((theme) => ({
  input_label: {
    color: `${theme.palette.color.text_color_white} !important`,
    marginBottom: "8px !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "15px !important",
    lineHeight: "16px !important",
  },
  input_field: {
    "& .MuiOutlinedInput-root": {
      "& input": {
        color: `rgba(0, 0, 0, 0.87) !important`,
        backgroundColor: "#FFFFFF !important",
        borderRadius: "8px !important",
        "&::placeholder": {
          color: "rgba(0, 0, 0, 0.6) !important",
          opacity: 1,
        },
      },
      "&.Mui-focused fieldset": {
        borderWidth: "0px !important",
      },
    },
    "& .MuiFormHelperText-root": {
      color: "#FFFFFF",
      marginLeft: "0px !important",
    },
  },
  button_container: {
    "& button": {
      backgroundColor: `#606B8A !important`,
      color: "white !important",
      width: "100%",
      height: "50px",
      "&:hover": {
        backgroundColor: "#171B42 !important",
      },
    },
  },
}));

const VerifyEmail = (props) => {
  const { setSection } = props;
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [clientID, setClientID] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showBackDrop, setShowBackDrop] = useState(false);

  const checkFields = async () => {
    email === ""
      ? setErrorMsg("email")
      : clientID === ""
      ? setErrorMsg("clientID")
      : await emailVerification(email, clientID);
  };

  const emailVerification = async (email, clientID) => {
    try {
      setShowBackDrop(true);
      await auth.accountRecovery(email, clientID);
      setShowBackDrop(false);
      setSection("otp");
    } catch (err) {
      setShowBackDrop(false);
      if (err.response.status == 404) {
        setErrorMsg("email");
        setErrorMsg("clientID");
      } else {
        err.response.data.error.includes("email")
          ? setErrorMsg("email")
          : err.response.data.error.includes("clientID")
          ? setErrorMsg("clientID")
          : "";
      }
    }
  };

  return (
    <>
      <Stack>
        <Typography className={classes.input_label}>Email</Typography>
        <TextField
          placeholder="Enter email"
          className={classes.input_field}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          helperText={errorMsg === "email" ? "Incorrect Entry" : ""}
          error={errorMsg === "email" ? true : false}
        />
      </Stack>
      <Stack>
        <Typography className={classes.input_label}>Client ID</Typography>
        <TextField
          placeholder="Enter client ID"
          className={classes.input_field}
          value={clientID}
          onChange={(e) => setClientID(e.target.value)}
          helperText={errorMsg === "clientID" ? "Incorrect Entry" : ""}
          error={errorMsg === "clientID" ? true : false}
        />
      </Stack>

      <Stack className={classes.button_container}>
        <Button onClick={checkFields}>Verify Email</Button>
      </Stack>

      <BackDrop showBackDrop={showBackDrop} />
    </>
  );
};
export default VerifyEmail;
