import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, Container } from "@mui/material";
import Form3CComponent from "../../components/acquisitionForm/Form3CComponent";
import BackDrop from "../../components/common/backDrop";
//import formAPI from "../../api/aquisitionForm/form";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles(() => ({
  select_form_container: {
    paddingLeft: "30px",
    marginBottom: "20px",
  },
  title_text: {
    height: "50px",
    display: "flex",
    alignItems: "center",
    fontSize: "24px",
    fontWeight: 600,
    ["@media (max-width:425px)"]: {
      fontSize: "16px",
    },
  },
}));

const Form3C = () => {
  const classes = useStyles();

  const [showBackDrop, setShowBackDrop] = useState(false);
  const [formName, setFormName] = useState(null);
  const [showSuccessMsg, setShowSuccessMsg] = useState("");
  const [showError, setShowError] = useState(false);

  const [sp] = useSearchParams();
  //let token = sp.get("token");
  const [companyName, setCompanyName] = useState("");
  const [operatorList, setOperatorList] = useState([]);
  const [dailyWeeklycheckList2, setDailyWeeklycheckList2] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        //const data = await formAPI.getUserFormWithToken(token);
        //setFormName(data.form_reference);
        //setCompanyName(data.client_company);
        //setOperatorList(data.operators);
        //setDailyWeeklycheckList2(data.dailyWeeklycheckList);
      } catch (error) {
        setShowError(true);
      }
    };
    fetchData();
  }, []); //token

  return (
    <Box>
      <Box className={classes.select_form_container}>
        <Container
          sx={{ textAlign: "center", fontSize: "30px", fontWeight: "700" }}
        >
          {companyName}
        </Container>
      </Box>

      <Container sx={{ textAlign: "center", color: "green" }}>
        {showSuccessMsg && (
          <>
            <h2>Saved {showSuccessMsg} data successfully </h2>

            <Button
              variant="contained"
              onClick={(e) => {
                window.location.reload(false);
              }}
            >
              Submit Another
            </Button>
          </>
        )}
      </Container>

      {showError ? (
        <Container sx={{ textAlign: "center", color: "red" }}>
          Unauthorised access! Please contact admin.
        </Container>
      ) : (
        ""
      )}

      {!showSuccessMsg ? (
        <Container sx={{ width: "100%", minHeight: "65vh" }}>
          <Form3CComponent setShowSuccessMsg={setShowSuccessMsg} />
        </Container>
      ) : (
        ""
      )}

      <BackDrop showBackDrop={showBackDrop} />
    </Box>
  );
};

export default Form3C;
