import axios from "axios";
import serverUrl from "../serverUrl";
axios.defaults.withCredentials = true;
var url = serverUrl;

const signup = async (
  firstName,
  lastName,
  email,
  password,
  confirmPassword,
  clientID
) => {
  const response = await axios.post(`${url}/signup`, {
    firstName: firstName,
    lastName: lastName,
    email: email.toLowerCase(),
    password: password,
    passwordConfirmation: confirmPassword,
    client_uuid: clientID,
  });
  return response;
};

const login = async (email, password) => {
  const response = await axios.post(`${url}/login`, {
    email: email.toLowerCase(),
    password: password,
  });
  return response;
};

const remoteLogin = async (token) => {
  const response = await axios.get(`${url}/remote_login?token=${token}`);
  return response;
};

const logout = async () => {
  const response = await axios.post(`${url}/logout`);
  return response;
};

const isUserLoggedIn = async () => {
  const response = await axios.post(`${url}/is_user_logged_in`);
  return response;
};

const otpVerification = async (otp) => {
  const response = await axios.post(`${url}/verify_otp`, {
    otp: otp,
  });
  return response;
};

const accountRecovery = async (email, clientID) => {
  try {
    const response = await axios.post(`${url}/account_recovery`, {
      email: email,
      client_uuid: clientID,
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const accountVerify = async (otp) => {
  try {
    const response = await axios.post(`${url}/account_recovery/verify`, {
      otp: otp,
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const updatePassword = async (password, passwordConfirmation) => {
  try {
    const response = await axios.patch(`${url}/account_recovery/update`, {
      password: password,
      passwordConfirmation: passwordConfirmation,
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export default {
  signup,
  login,
  remoteLogin,
  otpVerification,
  logout,
  isUserLoggedIn,
  accountRecovery,
  accountVerify,
  updatePassword,
};
