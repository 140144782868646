import React from "react";

const Tile1Svg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="485"
    height="177"
    viewBox="0 0 485 177"
    fill="none"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H481C483.209 0 485 1.79086 485 4V177H0V4Z"
      fill={props.fill}
    />

    <g clipPath="url(#clip0_2184_4280)">
      <path
        d="M256 153.055C153.6 153.055 102.4 201.055 0 201.055"
        stroke={props.color}
        strokeOpacity="0.2"
        strokeWidth="24"
      />
      <path
        d="M256 105.055C153.6 105.055 102.4 153.055 0 153.055"
        stroke={props.color}
        strokeOpacity="0.2"
        strokeWidth="24"
      />
    </g>
    <g clipPath="url(#clip1_2184_4280)">
      <path
        d="M335.846 153.055C233.446 153.055 182.246 201.055 79.8457 201.055"
        stroke={props.color}
        strokeOpacity="0.2"
        strokeWidth="24"
      />
      <path
        d="M335.846 105.055C233.446 105.055 182.246 153.055 79.8457 153.055"
        stroke={props.color}
        strokeOpacity="0.2"
        strokeWidth="24"
      />
    </g>
    <g clipPath="url(#clip2_2184_4280)">
      <path
        d="M415.691 153.055C313.291 153.055 262.091 201.055 159.691 201.055"
        stroke={props.color}
        strokeOpacity="0.2"
        strokeWidth="24"
      />
      <path
        d="M415.691 105.055C313.291 105.055 262.091 153.055 159.691 153.055"
        stroke={props.color}
        strokeOpacity="0.2"
        strokeWidth="24"
      />
    </g>
    <g clipPath="url(#clip3_2184_4280)">
      <path
        d="M495.537 153.055C393.137 153.055 341.937 201.055 239.537 201.055"
        stroke={props.color}
        strokeOpacity="0.2"
        strokeWidth="24"
      />
      <path
        d="M495.537 105.055C393.137 105.055 341.937 153.055 239.537 153.055"
        stroke={props.color}
        strokeOpacity="0.2"
        strokeWidth="24"
      />
    </g>
    <g clipPath="url(#clip4_2184_4280)">
      <path
        d="M575.381 153.055C472.981 153.055 421.781 201.055 319.381 201.055"
        stroke={props.color}
        strokeOpacity="0.2"
        strokeWidth="24"
      />
      <path
        d="M575.381 105.055C472.981 105.055 421.781 153.055 319.381 153.055"
        stroke={props.color}
        strokeOpacity="0.2"
        strokeWidth="24"
      />
    </g>
    <g clipPath="url(#clip5_2184_4280)">
      <path
        d="M655.227 153.055C552.827 153.055 501.627 201.055 399.227 201.055"
        stroke={props.color}
        strokeOpacity="0.2"
        strokeWidth="24"
      />
      <path
        d="M655.227 105.055C552.827 105.055 501.627 153.055 399.227 153.055"
        stroke={props.color}
        strokeOpacity="0.2"
        strokeWidth="24"
      />
    </g>
    <g clipPath="url(#clip6_2184_4280)">
      <path
        d="M735.072 153.055C632.672 153.055 581.472 201.055 479.072 201.055"
        stroke={props.color}
        strokeOpacity="0.2"
        strokeWidth="24"
      />
      <path
        d="M735.072 105.055C632.672 105.055 581.472 153.055 479.072 153.055"
        stroke={props.color}
        strokeOpacity="0.2"
        strokeWidth="24"
      />
    </g>
    <defs>
      <clipPath id="clip0_2184_4280">
        <rect
          width="79.8457"
          height="43.9448"
          fill="white"
          transform="translate(0 133.055)"
        />
      </clipPath>
      <clipPath id="clip1_2184_4280">
        <rect
          width="79.8457"
          height="43.9448"
          fill="white"
          transform="translate(79.8457 133.055)"
        />
      </clipPath>
      <clipPath id="clip2_2184_4280">
        <rect
          width="79.8457"
          height="43.9448"
          fill="white"
          transform="translate(159.691 133.055)"
        />
      </clipPath>
      <clipPath id="clip3_2184_4280">
        <rect
          width="79.8457"
          height="43.9448"
          fill="white"
          transform="translate(239.537 133.055)"
        />
      </clipPath>
      <clipPath id="clip4_2184_4280">
        <rect
          width="79.8457"
          height="43.9448"
          fill="white"
          transform="translate(319.381 133.055)"
        />
      </clipPath>
      <clipPath id="clip5_2184_4280">
        <rect
          width="79.8457"
          height="43.9448"
          fill="white"
          transform="translate(399.227 133.055)"
        />
      </clipPath>
      <clipPath id="clip6_2184_4280">
        <rect
          width="5.92605"
          height="43.9448"
          fill="white"
          transform="translate(479.072 133.055)"
        />
      </clipPath>
    </defs>
  </svg>
);
export { Tile1Svg };
