import { React, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link,
  Chip,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const useStyles = makeStyles(() => ({
  table_head_color: {
    backgroundColor: "#E0EFFF",
  },
}));

const StatusData = (props) => {
  const { selectedPartObject } = props;
  const classes = useStyles();
  const [open] = useState([]);
  const [update, setUpdate] = useState(false);

  const invertOpen = (batch) => {
    open[batch] = !open[batch];
    setUpdate(!update);
  };

  return (
    <Table>
      <TableHead className={classes.table_head_color}>
        <TableRow>
          <TableCell>Batch Numbers</TableCell>
        </TableRow>
      </TableHead>
      {selectedPartObject &&
        selectedPartObject.map((batch_obj) => (
          <TableBody key={batch_obj.batch}>
            <TableRow>
              <TableCell>
                <Typography variant="h4">
                  {batch_obj.batch}{" "}
                  <Chip
                    label={batch_obj.delivery}
                    color={batch_obj.delivery_color}
                  />
                  {batch_obj.delivery === "Delivered" ? (
                    <Chip label={batch_obj.delivered_at} color="primary" />
                  ) : (
                    ""
                  )}
                </Typography>

                <IconButton onClick={() => invertOpen(batch_obj.batch)}>
                  {open[batch_obj.batch] ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 10, paddingTop: 10 }}>
                <Collapse
                  in={open[batch_obj.batch]}
                  timeout="auto"
                  unmountOnExit
                >
                  <Table>
                    <TableHead className={classes.table_head_color}>
                      <TableRow>
                        <TableCell>Serial Numbers</TableCell>
                        <TableCell>Booked In</TableCell>
                        <TableCell>DAQ</TableCell>
                        <TableCell>Process</TableCell>
                        <TableCell>Report</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {batch_obj.items.length == 0 ? (
                        <TableRow key="1">
                          <TableCell colSpan={6}>No Items to display</TableCell>
                        </TableRow>
                      ) : (
                        batch_obj.items.map((item, j) => (
                          <TableRow key={j}>
                            <TableCell>{item.serial}</TableCell>
                            <TableCell>
                              {item.booked_in ? "Yes" : "No"}
                            </TableCell>
                            <TableCell>{item.DAQ ? "Yes" : "No"}</TableCell>
                            <TableCell>{item.process ? "Yes" : "No"}</TableCell>
                            <TableCell>
                              {item.report.length > 2 ? (
                                <Link href={item.report} color="inherit">
                                  {"Link Text"}
                                </Link>
                              ) : (
                                "Not Available"
                              )}
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </Collapse>
              </TableCell>
            </TableRow>
          </TableBody>
        ))}
    </Table>
  );
};
export default StatusData;
