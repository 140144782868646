import React from "react";
import { Card, CardContent, Grid } from "@mui/material";
import ReportCardTile from "./report-card-tile";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";

const OverviewDetailsPart2 = (props) => {
  let duration = props.duration;
  const no_of_features = props.partDetails
    ? props.partDetails.no_of_features
    : "";
  const out_of_tol_features = props.partDetails
    ? props.partDetails.out_of_tol_features
    : "";

  const no_of_locations = props.partDetails
    ? props.partDetails.no_of_locations
    : "";

  const col_width = props.width == 8 ? 4 : 6;
  const temp_total_beyond_repair =
    Math.round((out_of_tol_features / no_of_features) * 1000) / 10;
  return (
    <Grid item xl={props.width} lg={props.width} sm={props.width} xs={12}>
      <Card className="overview-details-2">
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <strong>Statistics</strong>
            </Grid>
            <Grid item xs={col_width}>
              <ReportCardTile
                tile_bg_color="#efefef"
                color="#0D1444"
                icon={<AccessTimeIcon fontSize="large" />}
                main_heading={duration}
                sub_heading="Duration"
                tooltip={props.duration_tooltip}
              />
            </Grid>
            <Grid item xs={col_width}>
              <ReportCardTile
                tile_bg_color={"#efefef"}
                color="#0D1444"
                icon={<DisplaySettingsIcon fontSize="large" />}
                main_heading={props.features}
                sub_heading="Features Measured"
              />
            </Grid>
            {col_width == 4 ? (
              <Grid item xs={col_width}>
                <Grid item xs={12}>
                  <div
                    className="float-right view-all-btn see-details-btn"
                    onClick={() => props.setLocationDetailsOpen(true)}
                  >
                    See Details
                  </div>
                </Grid>
                <ReportCardTile
                  tile_bg_color={"#efefef"}
                  color="#0D1444"
                  icon={<DisplaySettingsIcon fontSize="large" />}
                  main_heading={no_of_locations}
                  sub_heading="Locations"
                />
              </Grid>
            ) : null}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default OverviewDetailsPart2;
