import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import auth from "../../api/auth/auth";
import {
  AppBar,
  Container,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import SettingsApplicationsRoundedIcon from "@mui/icons-material/SettingsApplicationsRounded";
import BackDrop from "../common/backDrop";
import config_vars from "../../env.config";

const useStyles = makeStyles(() => ({
  pages_main_container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  navbar_item: {
    fontSize: "20px !important",
    fontWeight: "700 !important",
    letterSpacing: "1px",
    marginLeft: "5% !important",
    color: "black",
    textDecoration: "none",
    "&:hover": {
      color: "#333D94",
      cursor: "pointer",
    },
    "&.active": {
      color: "#333D94",
    },
    ["@media (max-width:1024px)"]: {
      margin: "5px 0 5px 5%",
    },
  },
  nav_bar_setting_button: {
    "&:hover": {
      color: "#333D94 !important",
      cursor: "pointer",
    },
  },
  navbar_item_company: {
    fontSize: "15px !important",
    fontWeight: "700 !important",
    letterSpacing: "1px",
    color: "#fff",
    textTransform: "uppercase",
    background: "#333",
    padding: "5px 10px",
    borderRadius: "5px",
    whiteSpace: "nowrap",
    marginTop: "-5px",
    marginRight: "10px",
  },
}));
const settings = ["Logout"];

const TopBar = () => {
  const classes = useStyles();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [user, setUser] = useState({});

  const navigate = useNavigate();
  const is_beta = config_vars.is_beta == "true";

  useEffect(() => {
    async function get_user() {
      try {
        setShowBackDrop(true);

        const user1 = await auth.isUserLoggedIn();
        setUser(user1.data.data.data);

        setShowBackDrop(false);
      } catch (error) {
        setShowBackDrop(false);
        navigate("/login");
      }
    }
    get_user();
  }, [navigate]);

  function first_word_company_name() {
    let first = "";
    if (user.company_name) first = user.company_name.split(" ")[0];
    return first;
  }

  function full_company_name() {
    return user.company_name;
  }

  function user_info() {
    return user.is_remote ? "Remote Access" : user.email;
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    try {
      setShowBackDrop(true);
      await auth.logout();
      setShowBackDrop(false);
      navigate("/login");
    } catch (error) {
      setShowBackDrop(false);
      navigate("/login");
    }
  };

  const openMobileMenu = async () => {
    setMobileMenu(!mobileMenu);
  };
  return (
    <>
      {/* Top bar */}
      <AppBar
        className="top-bar-custom"
        position="sticky"
        sx={{
          backgroundColor: "white",
          marginBottom: "10px",
          boxShadow: "0px 1px 4px rgba(100, 116, 139, 0.12)",
          borderBottom: "2px solid #333D94",
          ["@media (max-width:1024px)"]: {
            display: "none",
          },
        }}
      >
        <Container maxWidth="100vw">
          <Toolbar disableGutters>
            <Box
              sx={{ height: "80px" }}
              className={classes.pages_main_container}
            >
              <div className="top-bar-page-heading">
                <span>Report</span> Overview
              </div>
            </Box>
            {is_beta ? (
              <Box>
                <div className="beta-tag">Beta Testing</div>
              </Box>
            ) : null}
            <Box>
              <div
                className={classes.navbar_item_company}
                title={full_company_name()}
              >
                {full_company_name()}
              </div>
            </Box>
            <Box>
              <SettingsApplicationsRoundedIcon
                onClick={handleOpenUserMenu}
                sx={{ color: "black", width: "45px", height: "45px" }}
                className={classes.nav_bar_setting_button}
              />
              <Menu
                sx={{
                  mt: "45px",
                  "& .MuiPaper-root": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  key="-1"
                  sx={{
                    "&.Mui-focusVisible": {
                      backgroundColor: "transparent",
                    },
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.12)",
                    },
                  }}
                >
                  <Typography textAlign="center">{user_info()}</Typography>
                </MenuItem>
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={handleLogout}
                    sx={{
                      "&.Mui-focusVisible": {
                        backgroundColor: "transparent",
                      },
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.12)",
                      },
                    }}
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
        <BackDrop showBackDrop={showBackDrop} />
      </AppBar>
    </>
  );
};
export default TopBar;
