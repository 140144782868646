import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Container,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import formAPI from "../../api/aquisitionForm/form";
import BackDrop from "../common/backDrop";

const useStyles = makeStyles(() => ({
  form_page_header: {
    fontSize: "38px",
    color: "white",
    backgroundColor: "#333D94",
    padding: "10px",
    marginBottom: "20px",
    ["@media (max-width:425px)"]: {
      fontSize: "18px",
    },
  },
  form_inner_header: {
    border: "1px solid gray",
    borderRadius: "15px",
    marginBottom: "32px",
  },
  datepicker_main_container: {
    "& .react-datepicker__tab-loop": {
      "& .react-datepicker-popper": {
        zIndex: "2",
      },
    },
  },
  datepicker: {
    height: "57px",
    fontSize: "20px",
    paddingLeft: "10px",
    ["@media (max-width:425px)"]: {
      fontSize: "14px",
    },
  },
  title_text: {
    height: "50px",
    display: "flex",
    alignItems: "center",
    fontSize: "24px",
    fontWeight: 600,
    ["@media (max-width:425px)"]: {
      fontSize: "16px",
    },
  },
  submit_button: {
    width: "200px",
    height: "60px",
    backgroundColor: "#333D94 !important",
    marginBottom: "32px !important",
    color: "white !important",
  },
}));

const AQ_DigForm0005 = (props) => {
  const {
    setFormName,
    setShowSuccessMsg,
    operators,
    dailyWeeklycheckList2,
    token,
  } = props;
  const classes = useStyles();
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ msg: "", type: "" });
  const [defaultOperator, setDefaultOperator] = useState(
    "Please Select Operator"
  );
  const [operatorList, setOperatorList] = useState([]);
  const [dailyWeeklyCheck, setDailyWeeklyCheck] = useState({});
  const [formData, setFormData] = useState({
    form_id: 5,
    form_name: "Metrology (Contact)",
    form_reference: "AQ_DigForm0005",
    date: new Date(),
    operator: "",
    temperature1: "",
    temperature2: "",
    weekly_checks: null,
    daily_checks: null,
    form: "",
  });

  useEffect(() => {
    const getOperators = async () => {
      try {
        setShowSuccessMsg("");
        const operatorList = await formAPI.getOperators();
        const dailyWeeklycheckList = await formAPI.dailyWeeklyCheck(
          formData.form_reference
        );
        setDailyWeeklyCheck(dailyWeeklycheckList);
        setOperatorList(operatorList);
      } catch (error) {
        alert(error.msg);
      }
    };
    if (operators == null) getOperators();
    else {
      setOperatorList(operators);
      setDailyWeeklyCheck(dailyWeeklycheckList2);
    }
  }, [
    setShowSuccessMsg,
    operators,
    dailyWeeklycheckList2,
    formData.form_reference,
  ]);

  const handleSubmit = async () => {
    try {
      setShowBackDrop(true);
      let url = "";
      if (token != null) {
        url = "/save_user_form_with_token?token=" + token;
      }
      await formAPI.submitMetrologyData(formData, url);
      setErrorMsg("");
      setShowBackDrop(false);
      setShowSuccessMsg("Metrology (Contact)");
      setFormName(null);
    } catch (error) {
      setFormData({ ...formData, weekly_checks: null, daily_checks: null });
      setErrorMsg({ msg: error.msg, type: error.type });
      setShowBackDrop(false);
    }
  };

  return (
    <>
      {/* Metrology Weekly form inputs => start*/}
      {dailyWeeklyCheck && dailyWeeklyCheck.weekly_checks ? (
        <Typography variant="h4" color="green" textAlign="center" mb={5}>
          Weekly Check Already Completed
        </Typography>
      ) : (
        <>
          <Box className={classes.form_page_header}>
            Weekly Metrology (Contact)
          </Box>
          <Stack spacing={4} className={classes.form_inner_header}>
            <Container className={classes.datepicker_main_container}>
              <Box className={classes.title_text}>1.Date *</Box>
              <DatePicker
                disabled={true}
                className={classes.datepicker}
                selected={formData.date}
              />
            </Container>

            <Container>
              <Box className={classes.title_text}>2.Operator *</Box>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                error={
                  errorMsg.msg === "operator" && errorMsg.type === "weekly"
                }
                value={defaultOperator}
                onChange={(e) => setDefaultOperator(e.target.value)}
              >
                <MenuItem value="Please Select Operator">
                  Please Select Operator
                </MenuItem>
                {operatorList &&
                  operatorList.map((data) => (
                    <MenuItem
                      key={data.id}
                      value={data.id}
                      onClick={() => {
                        formData.operator = data.operator_name;
                      }}
                    >
                      {data.operator_name}
                    </MenuItem>
                  ))}
              </Select>
            </Container>

            <Container>
              <Box className={classes.title_text}>3.Temperature (Deg C) *</Box>
              <TextField
                error={
                  errorMsg.msg === "temperature1" && errorMsg.type === "weekly"
                }
                label="Min 19 and Max 26"
                type="number"
                placeholder="The value must be a number"
                helperText={
                  errorMsg.msg === "temperature1" && errorMsg.type === "weekly"
                    ? "Incorrect entry"
                    : ""
                }
                sx={{ width: "100%" }}
                variant="outlined"
                onChange={(e) => {
                  formData.temperature1 = e.target.value;
                }}
              />
            </Container>

            <Container>
              <Box className={classes.title_text}>4.Temperature (Deg C) *</Box>
              <TextField
                error={
                  errorMsg.msg === "temperature2" && errorMsg.type === "weekly"
                }
                label="Min 19 and Max 26"
                type="number"
                placeholder="The value must be a number"
                helperText={
                  errorMsg.msg === "temperature2" && errorMsg.type === "weekly"
                    ? "Incorrect entry"
                    : ""
                }
                sx={{ width: "100%" }}
                variant="outlined"
                onChange={(e) => {
                  formData.temperature2 = e.target.value;
                }}
              />
            </Container>

            <Container sx={{ textAlign: "center" }}>
              <Button
                className={classes.submit_button}
                onClick={() =>
                  handleSubmit(
                    ((formData.weekly_checks = true),
                    (formData.form = "weekly"))
                  )
                }
              >
                Submit Weekly
              </Button>
            </Container>
          </Stack>
        </>
      )}
      {/* end */}

      {/* Metrology Daily form inputs => start*/}
      {dailyWeeklyCheck && dailyWeeklyCheck.daily_checks ? (
        <Typography variant="h4" color="green" textAlign="center" mb={5}>
          Daily Check Already Completed
        </Typography>
      ) : (
        <>
          <Box className={classes.form_page_header}>
            Daily Metrology (Contact)
          </Box>
          <Stack spacing={4} className={classes.form_inner_header}>
            <Container className={classes.datepicker_main_container}>
              <Box className={classes.title_text}>1.Date *</Box>
              <DatePicker
                disabled={true}
                className={classes.datepicker}
                selected={formData.date}
              />
            </Container>

            <Container>
              <Box className={classes.title_text}>2.Operator *</Box>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                error={errorMsg.msg === "operator" && errorMsg.type === "daily"}
                value={defaultOperator}
                onChange={(e) => setDefaultOperator(e.target.value)}
              >
                <MenuItem value="Please Select Operator">
                  Please Select Operator
                </MenuItem>
                {operatorList &&
                  operatorList.map((data) => (
                    <MenuItem
                      key={data.id}
                      value={data.id}
                      onClick={() => {
                        formData.operator = data.operator_name;
                      }}
                    >
                      {data.operator_name}
                    </MenuItem>
                  ))}
              </Select>
            </Container>

            <Container>
              <Box className={classes.title_text}>3.Temperature (Deg C) *</Box>
              <TextField
                error={errorMsg === "temperature1" && errorMsg.type === "daily"}
                label="Min 19 and Max 26"
                type="number"
                placeholder="The value must be a number"
                helperText={
                  errorMsg === "temperature1" && errorMsg.type === "daily"
                    ? "Incorrect entry"
                    : ""
                }
                sx={{ width: "100%" }}
                variant="outlined"
                onChange={(e) => {
                  formData.temperature1 = e.target.value;
                }}
              />
            </Container>

            <Container>
              <Box className={classes.title_text}>4.Temperature (Deg C) *</Box>
              <TextField
                error={errorMsg === "temperature2" && errorMsg.type === "daily"}
                label="Min 19 and Max 26"
                type="number"
                placeholder="The value must be a number"
                helperText={
                  errorMsg === "temperature2" && errorMsg.type === "daily"
                    ? "Incorrect entry"
                    : ""
                }
                sx={{ width: "100%" }}
                variant="outlined"
                onChange={(e) => {
                  formData.temperature2 = e.target.value;
                }}
              />
            </Container>

            <Container sx={{ textAlign: "center" }}>
              <Button
                className={classes.submit_button}
                onClick={() =>
                  handleSubmit(
                    ((formData.daily_checks = true), (formData.form = "daily"))
                  )
                }
              >
                Submit Daily
              </Button>
            </Container>
          </Stack>
        </>
      )}
      {/* end */}
      <BackDrop showBackDrop={showBackDrop} />
    </>
  );
};

export default AQ_DigForm0005;
