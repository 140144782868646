import React, { useState } from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Stack } from "@mui/system";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import auth from "../../api/auth/auth";
import BackDrop from "../common/backDrop";
import DialogBox from "../common/dialogBox";

const useStyles = makeStyles((theme) => ({
  input_label: {
    color: `${theme.palette.color.text_color_white} !important`,
    marginBottom: "8px !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "15px !important",
    lineHeight: "16px !important",
  },
  password_input: {
    backgroundColor: "#FFFFFF !important",
    borderRadius: "8px !important",
    "& .MuiOutlinedInput-root": {
      "& input": {
        color: `rgba(0, 0, 0, 0.87) !important`,
        "&::placeholder": {
          color: "rgba(0, 0, 0, 0.6) !important",
          opacity: 1,
        },
      },
      "& input[type=password]": {
        color: `rgba(0, 0, 0, 0.6) !important`,
      },
      "&.Mui-focused fieldset": {
        borderWidth: "0px !important",
      },
    },
  },
  input_error_message: {
    color: "#F43454",
    marginTop: "5px !important",
  },
  button_container: {
    "& button": {
      backgroundColor: `#606B8A !important`,
      color: "white !important",
      width: "100%",
      height: "50px",
      "&:hover": {
        backgroundColor: "#171B42 !important",
      },
    },
  },
}));

const UpdatePassword = () => {
  const classes = useStyles();
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [openDailogBox, setOpenDailogBox] = useState(false);
  const [text, setText] = useState("");
  const [passwordMissing, setPasswordMissing] = useState("");
  const [confirmPassMissing, setConfirmPassMissing] = useState("");

  const [passwordValues, setPasswordValues] = useState({
    password: "",
    showPassword: false,
  });
  const [confirmPasswordValues, setConfirmPasswordValues] = useState({
    confirmPassword: "",
    showConfirmPassword: false,
  });

  const handleClickShowPassword = () => {
    setPasswordValues({
      ...passwordValues,
      showPassword: !passwordValues.showPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setConfirmPasswordValues({
      ...confirmPasswordValues,
      showConfirmPassword: !confirmPasswordValues.showConfirmPassword,
    });
  };

  const checkFields = async () => {
    if (passwordValues.password !== confirmPasswordValues.confirmPassword) {
      setPasswordMissing("password should match with confirm password");
      setConfirmPassMissing("confirm password should match with password");
    }
    passwordValues.password === ""
      ? setPasswordMissing("Password Missing")
      : confirmPasswordValues.confirmPassword === ""
      ? setConfirmPassMissing("Confirm Password Missing")
      : await resetPassword(
          passwordValues.password,
          confirmPasswordValues.confirmPassword
        );
  };

  const resetPassword = async (password, confirmPassword) => {
    try {
      setShowBackDrop(true);
      await auth.updatePassword(password, confirmPassword);
      setShowBackDrop(false);
      setText("Password Changed Successfully");
      setOpenDailogBox(true);
    } catch (err) {
      setShowBackDrop(false);
      if (err.response.status == 400) {
        setPasswordMissing(err.response.data.error);
      }
      if (err.response.status == 401) {
        setText("the session has been expired please regenerate OTP code");
        setOpenDailogBox(true);
      }
    }
  };

  return (
    <>
      <Stack>
        <List sx={{ color: "lime", fontSize: "16px !important" }}>
          Hint
          <ListItem className={classes.input_label}>.Min 8 and Max 20</ListItem>
          <ListItem className={classes.input_label}>
            .At least 1 LowerCase and 1 UpperCase
          </ListItem>
          <ListItem className={classes.input_label}>
            .At least 1 Numeric and 1 Symbol
          </ListItem>
        </List>
      </Stack>
      <Stack>
        <Typography className={classes.input_label}>Password</Typography>
        <FormControl variant="outlined" className={classes.password_input}>
          <OutlinedInput
            placeholder="Enter password"
            type={passwordValues.showPassword ? "text" : "password"}
            onChange={(e) => {
              passwordValues.password = e.target.value;
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} edge="end">
                  {passwordValues.showPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Typography className={classes.input_error_message}>
          {passwordMissing}
        </Typography>
      </Stack>

      <Stack>
        <Typography className={classes.input_label}>
          Confirm Password
        </Typography>
        <FormControl variant="outlined" className={classes.password_input}>
          <OutlinedInput
            placeholder="Enter password"
            type={
              confirmPasswordValues.showConfirmPassword ? "text" : "password"
            }
            onChange={(e) => {
              confirmPasswordValues.confirmPassword = e.target.value;
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowConfirmPassword} edge="end">
                  {confirmPasswordValues.showConfirmPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Typography className={classes.input_error_message}>
          {confirmPassMissing}
        </Typography>
      </Stack>

      <Stack className={classes.button_container}>
        <Button onClick={checkFields}>Update Password</Button>
      </Stack>

      {openDailogBox ? (
        <DialogBox
          open={openDailogBox}
          setOpenDailogBox={setOpenDailogBox}
          text={text}
        />
      ) : (
        ""
      )}
      <BackDrop showBackDrop={showBackDrop} />
    </>
  );
};
export default UpdatePassword;
