import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import DialogBox from "../../components/common/dialogBox";
import { makeStyles } from "@mui/styles";
import { Logo } from "../../assets/images/svgimages";
import auth from "../../api/auth/auth";
import BackDrop from "../../components/common/backDrop";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  login_page_main_container: {
    backgroundColor: `${theme.palette.color.background_color} !important`,
    width: "100%",
    height: "100%",
    display: "flex",
    overflow: "auto",
  },
  login_page_logo: {
    alignItems: "center",
    textAlign: "center",
    height: "96px",
    "& svg": {
      width: "100%",
    },
  },
}));

const RemoteLogin = () => {
  const [otpSuccess, setOtpSuccess] = useState(true);
  const [openDailogBox, setOpenDailogBox] = useState(false);
  const classes = useStyles({ otpSuccess });

  const [sp] = useSearchParams();
  let token = sp.get("token");

  useEffect(() => {
    async function calllogin() {
      try {
        const res = await auth.remoteLogin(token);
        if (res.status == 200) {
          window.location = "/";
        }
      } catch (e) {
        console.log(e);
        setOpenDailogBox(true);
      }
    }
    calllogin();
  }, [token]);

  return (
    <Box
      className={classes.login_page_main_container}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Stack className={classes.login_page_logo}>
        <Logo className="white" />
      </Stack>

      <DialogBox
        open={openDailogBox}
        setOpenDailogBox={setOpenDailogBox}
        text="Could not log in using this session. Session expired or doesn't exist. Please try again!"
      />

      <BackDrop showBackDrop={true} />
    </Box>
  );
};
export default RemoteLogin;
