import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  typography: {
    fontFamily: ["sans-serif"].join(","),
  },
  palette: {
    color: {
      background_color: "#333D94",
      text_color_white: "#FFFFFF",
    },
  },
});
export default Theme;
