import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import formAPI from "../../api/aquisitionForm/form";
import BackDrop from "../common/backDrop";
import "../../styles/custom.css";
import { DeleteOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  form_page_header: {
    fontSize: "38px",
    color: "white",
    backgroundColor: "#333D94",
    padding: "10px",
    marginBottom: "20px",
    ["@media (max-width:425px)"]: {
      fontSize: "18px",
    },
  },
  form_inner_header: {
    border: "1px solid gray",
    borderRadius: "15px",
    marginBottom: "32px",
  },
  datepicker_main_container: {
    "& .react-datepicker__tab-loop": {
      "& .react-datepicker-popper": {
        zIndex: "2",
      },
    },
  },
  datepicker: {
    height: "57px",
    fontSize: "20px",
    paddingLeft: "10px",
    ["@media (max-width:425px)"]: {
      fontSize: "14px",
    },
  },
  title_text: {
    height: "50px",
    display: "flex",
    alignItems: "center",
    fontSize: "24px",
    fontWeight: 600,
    ["@media (max-width:425px)"]: {
      fontSize: "16px",
    },
  },
  small: {
    fontSize: "16px",
    fontWeight: 100,
  },
  submit_button: {
    width: "200px",
    height: "60px",
    backgroundColor: "#333D94 !important",
    marginBottom: "32px !important",
    color: "white !important",
  },
}));

const Form3CComponent = (props) => {
  const navigate = useNavigate();
  const areaArray = [
    { id: 1, name: "WS-01 Goods In" },
    { id: 2, name: "WS-02 Vision" },
    { id: 3, name: "WS-03 3D Print / Clean / Part Prep" },
    { id: 4, name: "WS-04 Tool Build / Part Sort" },
    { id: 5, name: "WS-05 GOM" },
    { id: 6, name: "WS-06 CMM" },
    { id: 7, name: "WS-07 Ultrasonic" },
    { id: 8, name: "WS-08 Dispatch / Goods Out" },
    { id: 9, name: "WS-09 Stores" },
    { id: 10, name: "WS-10 Conference Room" },
    { id: 11, name: "WS-11 Utilities (Toilet, Kitchen, External)" },
    { id: 12, name: "WS-12 Development Area (Cobot)" },
  ];
  const issueTypeArray = [
    { id: 1, name: "Safety" },
    { id: 2, name: "Quality" },
    { id: 3, name: "Delivery" },
  ];

  const priotiyList = [
    { id: 1, name: "Low" },
    { id: 2, name: "Normal" },
    { id: 3, name: "High" },
    { id: 4, name: "Critical" },
  ];
  const classes = useStyles();
  const { setShowSuccessMsg } = props;
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [operatorList, setOperatorList] = useState([]);
  const [defaultOperator, setDefaultOperator] = useState(
    "Please Select Operator"
  );

  const [partList, setPartList] = useState([]);
  const [defaultPart, setDefaultPart] = useState("Please Select Part");

  const [projectList, setProjectList] = useState([]);
  const [defaultProject, setDefaultProject] = useState("Please Select Project");

  const [defaultPriority, setDefaultPriority] = useState(
    "Please Select Priority"
  );

  const [areaList, setAreaList] = useState(areaArray);
  const [selectedAreas, setSelectedAreas] = useState([]);

  const [issueTypeList, setIssueTypeList] = useState(issueTypeArray);
  const [selectedIssueTypes, setSelectedIssueTypes] = useState([]);

  const [otherChecked, setOtherChecked] = useState({
    area_other_field_check: false,
    issue_type_other_field_check: false,
  });
  const [otherInputFields, setOtherInputFields] = useState({
    area_other_field: "",
    issue_type_other_field: "",
  });

  const files_limit = 5;
  const max_file_size = 5; //5 MB
  const max_file_size_bytes = max_file_size * (1024 * 1024);

  const concern_max_word_limit = 250;
  const action_max_word_limit = 250;

  const [issueFilesList, setIssueFilesList] = useState([]);
  const inputIssueFileElement = useRef(null);

  const [containmentFilesList, setContainmentFilesList] = useState([]);
  const inputContainmentFileElement = useRef(null);

  const update_project_list = async () => {
    try {
      setDefaultProject("Please Select Project");
      setProjectList([]);
      const part = formData.part;
      let data = await formAPI.getProjectList(part);
      data.push({ project: "Not Applicable" });
      setProjectList(data);
    } catch (error) {
      setShowError(true);
    }
  };

  const onOtherInputChange = (event, list) => {
    let value = event.target.value.replace(",", "-").trim();
    setOtherInputFields({ ...otherInputFields, [event.target.name]: value });

    for (let i = 0; i < list.length; i++) {
      if (list[i].id === 0) {
        list.splice(i, 1);
        break;
      }
    }
    setOtherChecked({
      ...otherChecked,
      [event.target.name + "_check"]: value.length > 0,
    });
    if (value.length > 0) list.push({ id: 0, value: value });
  };

  const handle_cb = (id, value, checked, list, name) => {
    if (id > 0) {
      if (checked) list.push({ id: id, value: value });
      else {
        for (let i = 0; i < list.length; i++) {
          if (list[i].id === id) {
            list.splice(i, 1);
            break;
          }
        }
      }
    } else {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === 0) {
          list.splice(i, 1);
          break;
        }
      }
      setOtherInputFields({
        ...otherInputFields,
        [name]: checked ? "Other" : "",
      });
      setOtherChecked({ ...otherChecked, [name + "_check"]: checked });
      if (checked) list.push({ id: 0, value: "Other" });
    }
  };

  const handleIssueFileChange = (event) => {
    for (const f in event.target.files) {
      if (Object.hasOwn(event.target.files, f)) {
        let file = event.target.files[f];
        if (file.size > max_file_size_bytes) {
          alert(
            `Max file size limit is ${max_file_size} MB. Attached file size is ${(
              file.size /
              1024 /
              1024
            ).toFixed(2)} MB.`
          );
          continue;
        }
        let i = 1;
        let fname = file.name;
        while (issueFilesList.filter((e) => e.name == fname).length > 0)
          fname = i++ + " " + file.name;
        if (issueFilesList.length >= files_limit) {
          alert(`You cannot add more than ${files_limit} files`);
          break;
        }
        issueFilesList.push({ name: fname, file: file });
        setIssueFilesList([...issueFilesList]);
      }
    }
    inputIssueFileElement.current.value = "";
  };

  const deleteIssueFile = (idx) => {
    issueFilesList.splice(idx, 1);
    setIssueFilesList([...issueFilesList]);
  };

  const handleContainmentFileChange = (event) => {
    for (const f in event.target.files) {
      if (Object.hasOwn(event.target.files, f)) {
        let file = event.target.files[f];
        if (file.size > max_file_size_bytes) {
          alert(
            `Max file size limit is ${max_file_size} MB. Attached file size is ${(
              file.size /
              1024 /
              1024
            ).toFixed(2)} MB.`
          );
          continue;
        }
        let i = 1;
        let fname = file.name;
        while (containmentFilesList.filter((e) => e.name == fname).length > 0)
          fname = i++ + " " + file.name;
        if (containmentFilesList.length >= files_limit) {
          alert(`You cannot add more than ${files_limit} files`);
          break;
        }
        containmentFilesList.push({ name: fname, file: file });
        setContainmentFilesList([...containmentFilesList]);
      }
    }
    inputContainmentFileElement.current.value = "";
  };

  const deleteContainmentFile = (idx) => {
    containmentFilesList.splice(idx, 1);
    setContainmentFilesList([...containmentFilesList]);
  };

  const word_count = (val, limit) => {
    return val.split(/[\s]+/).length - 1 + " / " + limit;
  };

  const checkWordLen = (val, limit) => {
    var len = val.trim().split(/[\s]+/);
    return len.length <= limit;
  };

  const [formData, setFormData] = useState({
    operator: "",
    date: new Date(),
    area: [],
    issue: "",
    part: "",
    project: "",
    issue_type: "",
    concern: "",
    quantity: 0,
    priority: "",
    issue_files: [],
    containment_action: "",
    containment_files: [],
    countermeasure_action: "",
  });

  useEffect(() => {
    const getOperators = async () => {
      try {
        setShowSuccessMsg("");
        const operatorList = await formAPI.getAddQualOperators();
        setOperatorList(operatorList);
      } catch (error) {
        navigate("/login");
      }
    };

    const getParts = async () => {
      try {
        setShowSuccessMsg("");
        let partsList = await formAPI.getAllParts();
        partsList.push({ part_number: "Other", created_at: "other" });
        setPartList(partsList);
      } catch (error) {
        //alert(error.msg);
        navigate("/login");
      }
    };

    getOperators();
    getParts();
  }, [setShowSuccessMsg, navigate]);

  const handleSubmit = async () => {
    try {
      setShowBackDrop(true);

      formData.area = selectedAreas;
      formData.containment_files = containmentFilesList;
      formData.issue_files = issueFilesList;
      formData.issue_type = selectedIssueTypes;

      await formAPI.submit3cData(formData);
      setErrorMsg("");
      setShowBackDrop(false);
      setShowSuccessMsg("3C Raising Tool");
      setFormName(null);
    } catch (error) {
      setErrorMsg(error.msg);
      setShowBackDrop(false);
    }
  };

  return (
    <>
      <Box className={classes.form_page_header}>AddQual 3C RAISING TOOL</Box>
      <Stack spacing={4} className={classes.form_inner_header}>
        <Container>
          <Box className={classes.title_text}>
            1. Operator Identifying Issue *
          </Box>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            error={errorMsg == "operator" ? true : false}
            value={defaultOperator}
            onChange={(e) => setDefaultOperator(e.target.value)}
          >
            <MenuItem value="Please Select Operator">
              Please Select Operator
            </MenuItem>
            {operatorList &&
              operatorList.map((data) => (
                <MenuItem
                  key={data.id}
                  value={data.id}
                  onClick={() => {
                    formData.operator = data.operator_name;
                  }}
                >
                  {data.operator_name}
                </MenuItem>
              ))}
          </Select>
        </Container>

        <Container className={classes.datepicker_main_container}>
          <Box className={classes.title_text}>2. Date Issue Identified *</Box>
          <DatePicker
            className={classes.datepicker}
            selected={formData.date}
            error={errorMsg == "date" ? true : false}
            onChange={(date) => {
              setFormData({ ...formData, date: date });
            }}
          />
        </Container>

        <Container>
          <Box className={classes.title_text}>3. Area *</Box>
          <p className="red">
            {errorMsg == "area" ? "Please select at least one option" : ""}
          </p>

          <FormGroup>
            {areaList &&
              areaList.map((data) => (
                <FormControlLabel
                  key={data.id}
                  control={
                    <Checkbox
                      onClick={(e) => {
                        handle_cb(
                          data.id,
                          data.name,
                          e.target.checked,
                          selectedAreas
                        );
                      }}
                    />
                  }
                  label={data.name}
                />
              ))}
            <FormControlLabel
              key="Other"
              control={
                <Checkbox
                  checked={otherChecked.area_other_field_check}
                  onClick={(e) => {
                    handle_cb(
                      0,
                      "",
                      e.target.checked,
                      selectedAreas,
                      "area_other_field"
                    );
                  }}
                />
              }
              label={
                <TextField
                  inputProps={{ style: { padding: "5px" } }}
                  placeholder="Other"
                  onChange={(e) => onOtherInputChange(e, selectedAreas)}
                  value={otherInputFields.area_other_field}
                  name="area_other_field"
                />
              }
            />
          </FormGroup>
        </Container>

        <Container>
          <Box className={classes.title_text}>4. Issue *</Box>
          <TextField
            error={errorMsg == "issue" ? true : false}
            label="This field is required"
            type="text"
            placeholder="Enter your answer"
            helperText={errorMsg == "issue" ? "Incorrect entry" : ""}
            sx={{ width: "100%" }}
            variant="outlined"
            value={formData.issue}
            onChange={(e) => {
              const new_value = e.target.value;
              setFormData({ ...formData, issue: new_value });
            }}
          />
        </Container>

        <Container>
          <Box className={classes.title_text}>5. Part *</Box>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            error={errorMsg == "part" ? true : false}
            value={defaultPart}
            onChange={(e) => setDefaultPart(e.target.value)}
          >
            <MenuItem value="Please Select Part">Please Select Part</MenuItem>
            {partList &&
              partList.map((data) => (
                <MenuItem
                  key={data.created_at}
                  value={data.part_number}
                  onClick={() => {
                    formData.part = data.part_number;
                    update_project_list();
                  }}
                >
                  {data.part_number}
                </MenuItem>
              ))}
          </Select>
        </Container>

        {defaultPart == "Other" && (
          <Container>
            <TextField
              label="Other Part"
              placeholder="Describe Other Part here..."
              onChange={(e) =>
                setFormData({ ...formData, part: e.target.value })
              }
              value={formData.part}
              name="part_other_field"
            />
          </Container>
        )}

        <Container>
          <Box className={classes.title_text}>6. Project *</Box>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            error={errorMsg == "project" ? true : false}
            value={defaultProject}
            onChange={(e) => setDefaultProject(e.target.value)}
          >
            <MenuItem value="Please Select Project">
              Please Select Project
            </MenuItem>
            {projectList &&
              projectList.map((data) => (
                <MenuItem
                  key={data.project}
                  value={data.project}
                  onClick={() => {
                    formData.project = data.project;
                  }}
                >
                  {data.project}
                </MenuItem>
              ))}
          </Select>
        </Container>

        <Container>
          <Box className={classes.title_text}>7. Issue Type *</Box>
          <p className="red">
            {errorMsg == "issue_type"
              ? "Please select at least one option"
              : ""}
          </p>
          <FormGroup>
            {issueTypeList &&
              issueTypeList.map((data) => (
                <FormControlLabel
                  key={data.id}
                  control={
                    <Checkbox
                      onClick={(e) => {
                        handle_cb(
                          data.id,
                          data.name,
                          e.target.checked,
                          selectedIssueTypes
                        );
                      }}
                    />
                  }
                  label={data.name}
                />
              ))}
            <FormControlLabel
              key="Other"
              control={
                <Checkbox
                  checked={otherChecked.issue_type_other_field_check}
                  onClick={(e) => {
                    handle_cb(
                      0,
                      "",
                      e.target.checked,
                      selectedIssueTypes,
                      "issue_type_other_field"
                    );
                  }}
                />
              }
              label={
                <TextField
                  inputProps={{ style: { padding: "5px" } }}
                  placeholder="Other"
                  onChange={(e) => onOtherInputChange(e, selectedIssueTypes)}
                  value={otherInputFields.issue_type_other_field}
                  name="issue_type_other_field"
                />
              }
            />
          </FormGroup>
        </Container>

        <Container>
          <Box className={classes.title_text}>
            8. CONCERN - DESCRIPTION OF THE PROBLEM / INITIAL CAUSE [QCD] *
          </Box>
          <TextField
            error={errorMsg == "concern" ? true : false}
            label={
              "This field is required (Limit: " +
              word_count(formData.concern, concern_max_word_limit) +
              " words)"
            }
            type="text"
            placeholder="Enter your answer"
            helperText={errorMsg == "concern" ? "Incorrect entry" : ""}
            sx={{ width: "100%" }}
            variant="outlined"
            multiline
            value={formData.concern}
            rows={4}
            onChange={(e) => {
              const new_value = e.target.value;
              if (checkWordLen(new_value, concern_max_word_limit))
                setFormData({ ...formData, concern: new_value });
            }}
          />
        </Container>

        <Container>
          <Box className={classes.title_text}>9. Quantity of Parts *</Box>
          <TextField
            error={errorMsg == "quantity" ? true : false}
            label="This field is required"
            type="number"
            placeholder="The value must be a number"
            helperText={errorMsg == "quantity" ? "Incorrect entry" : ""}
            sx={{ width: "100%" }}
            variant="outlined"
            onChange={(e) => {
              formData.quantity = e.target.value;
            }}
          />
        </Container>

        <Container>
          <Box className={classes.title_text}>10. Priority *</Box>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            error={errorMsg == "priority" ? true : false}
            value={defaultPriority}
            onChange={(e) => setDefaultPriority(e.target.value)}
          >
            <MenuItem
              value="Please Select Priority"
              onClick={() => {
                formData.priority = "";
              }}
            >
              Please Select Priority
            </MenuItem>
            {priotiyList.map((v) => (
              <MenuItem
                key={v.id}
                value={v.name}
                onClick={() => {
                  formData.priority = v.id;
                }}
              >
                {v.name}
              </MenuItem>
            ))}
          </Select>
        </Container>

        <Container>
          <Box className={classes.title_text}>
            11. Picture of the Issues
            <small className={classes.small}>(Non-anonymous question)</small>
          </Box>
          <p className="red">
            {errorMsg == "issue_files" ? "At least one file is required" : ""}
          </p>
          <div>
            {issueFilesList &&
              issueFilesList.map((data, idx) => (
                <div className="uploaded_file" key={data.name}>
                  {data.name}
                  <DeleteOutline
                    className="custom_icon"
                    onClick={(e) => {
                      deleteIssueFile(idx);
                    }}
                  ></DeleteOutline>
                </div>
              ))}
          </div>
          <Button
            variant="contained"
            onClick={(e) => {
              inputIssueFileElement.current.click();
            }}
          >
            Upload File
          </Button>
          <input
            type="file"
            name="issue_file_input"
            style={{ display: "none" }}
            multiple
            ref={inputIssueFileElement}
            onChange={handleIssueFileChange}
            accept="image/*"
          ></input>
        </Container>

        <Container>
          <Box className={classes.title_text}>
            12.CONTAINMENT ACTION - VERIFIED ACTION THAT IMMEDIATELY STOPS THE
            SYMPTOM FROM REACHING THE NEXT PROCESS / CUSTOMER
          </Box>
          <br />
          <TextField
            error={errorMsg == "containment_action" ? true : false}
            label={
              "This field is optional (Limit: " +
              word_count(formData.containment_action, action_max_word_limit) +
              " words)"
            }
            type="text"
            placeholder="Enter your answer"
            helperText={
              errorMsg == "containment_action" ? "Incorrect entry" : ""
            }
            sx={{ width: "100%" }}
            variant="outlined"
            multiline
            rows={4}
            value={formData.containment_action}
            onChange={(e) => {
              const new_value = e.target.value;
              if (checkWordLen(new_value, action_max_word_limit))
                setFormData({
                  ...formData,
                  containment_action: new_value,
                });
            }}
          />
        </Container>

        <Container>
          <Box className={classes.title_text}>
            13.ADD CONTAINMENT INFORMATION - IMAGES / DOCUMENTS / INFO
            <small className={classes.small}>(Non-anonymous question)</small>
          </Box>
          <p className="red">
            {errorMsg == "containment_files"
              ? "Please upload at least one file"
              : ""}
          </p>
          <br />
          <div>
            {containmentFilesList &&
              containmentFilesList.map((data, idx) => (
                <div className="uploaded_file" key={data.name}>
                  {data.name}
                  <DeleteOutline
                    className="custom_icon"
                    onClick={(e) => {
                      deleteContainmentFile(idx);
                    }}
                  ></DeleteOutline>
                </div>
              ))}
          </div>
          <Button
            onClick={(e) => {
              inputContainmentFileElement.current.click();
            }}
            variant="contained"
          >
            Upload File
          </Button>
          <input
            type="file"
            name="containment_file_input"
            style={{ display: "none" }}
            multiple
            ref={inputContainmentFileElement}
            onChange={handleContainmentFileChange}
            accept="image/*"
          ></input>
        </Container>

        <Container>
          <Box className={classes.title_text}>14.COUNTERMEASURE ACTION</Box>
          <br />
          <TextField
            error={errorMsg == "countermeasure_action" ? true : false}
            label={
              "This field is optional (Limit: " +
              word_count(
                formData.countermeasure_action,
                action_max_word_limit
              ) +
              " words)"
            }
            type="text"
            placeholder="Enter your answer"
            helperText={
              errorMsg == "countermeasure_action" ? "Incorrect entry" : ""
            }
            sx={{ width: "100%" }}
            variant="outlined"
            multiline
            rows={4}
            value={formData.countermeasure_action}
            onChange={(e) => {
              const new_value = e.target.value;
              if (checkWordLen(new_value, action_max_word_limit))
                setFormData({
                  ...formData,
                  countermeasure_action: new_value,
                });
            }}
          />
        </Container>

        <Container sx={{ textAlign: "center" }}>
          <Button className={classes.submit_button} onClick={handleSubmit}>
            Submit
          </Button>
        </Container>
      </Stack>
      <BackDrop showBackDrop={showBackDrop} />
    </>
  );
};

export default Form3CComponent;
