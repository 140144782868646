import { React, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Chip,
  Collapse,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import MasterLayout from "../../components/common/masterLayout";
import BackDrop from "../../components/common/backDrop";
import SearchIcon from "@mui/icons-material/Search";
import reportApi from "../../api/report/reportApi";
import OverviewDetailsPart from "../../components/report/overviewDetailsPart";
import OverviewDetailsPart2 from "../../components/report/overviewDetailsPart2";
import OverviewDetailsPart3 from "../../components/report/overviewDetailsPart3";
import { BarChart } from "@mui/x-charts/BarChart";
import MenuIcon from "@mui/icons-material/Menu";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import "../../styles/timeline.css";
import CustomTabPanel from "../../components/report/CustomTabPanel";
import RightSideBarJC from "../../components/common/rightSideBarJC";
import RightSideBarLocationDetail from "../../components/common/rightSideBarLocationDetail";
import ComparisonOkNok from "../../components/report/comparisonOkNok";
import RightSideBarCleaningDetail from "../../components/common/rightSideBarCleaningDetail";
import OverviewDetails1 from "../../components/report/overviewDetails1";
import ComparisonBatchSerial from "../../components/report/comparisonBatchSerial";
import RightSideBarLegend from "../../components/common/rightSideBarLegend";
import InfoIcon from "@mui/icons-material/Info";

const useStyles = makeStyles(() => ({
  home_main_container: {
    width: "100%",
    overflow: "auto",
  },
}));

const PartLevelSummaryPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [showBackDrop, setShowBackDrop] = useState(false);
  const [showRightBar, setShowRightBar] = useState(false);

  const [allJobCards, setAllJobCards] = useState([]); // job cards for this client //
  const [allParts, setAllParts] = useState([]); // job cards for this client //
  const [partDetails, setPartDetails] = useState(null);
  const [fullPartObject, setFullPartObject] = useState(null);
  const [jobCardDetails, setJobCardDetails] = useState(null);
  const [serialisedItems, setSerialisedItems] = useState([]);
  const [resultOverviewFilter, setResultOverviewFilter] = useState("serial");
  const [timelineOpen, setTimelineOpen] = useState(true);
  const [locationDetailsOpen, setLocationDetailsOpen] = useState(false);
  const [cleaningDetailsOpen, setCleaningDetailsOpen] = useState(false);
  const [legendRBOpen, setLegendRBOpen] = useState(false);
  const [compareFeature, setCompareFeature] = useState(1);
  const [compareUnit, setCompareUnit] = useState(1);
  const [batchSerialBatch, setBatchSerialBatch] = useState("CNG8292");
  const [timeline, setTimeline] = useState([
    {
      title: (
        <div>
          <div>AQ</div>
          <div>
            S02 Vision{" "}
            <span title="S02 - Parts evaluated with vision before cleaning">
              <InfoIcon
                sx={{ verticalAlign: "middle" }}
                style={{ fontSize: "12px" }}
              />
            </span>
          </div>
          <div>
            S03 Gom Scan{" "}
            <span title="S03 - Parts evaluated dimensionally before cleaning">
              <InfoIcon
                sx={{ verticalAlign: "middle" }}
                style={{ fontSize: "12px" }}
              />
            </span>
          </div>
        </div>
      ),
      route_captured: true,
      type: "aq",
    },
    {
      title: (
        <div>
          <div>30</div>
          <div>Remove Details</div>
        </div>
      ),
      route_captured: true,
      type: "trt",
      quantity: 3,
      quantity_type: "failed",
    },
    {
      title: (
        <div>
          <div>80</div>
          <div>Dress to remove remnant coating</div>
        </div>
      ),
      route_captured: false,
      type: "trt",
      quantity: 19,
      quantity_type: "failed",
    },
    {
      title: (
        <div>
          <div>AQ</div>
          <div>
            S04 GOM Scan{" "}
            <span title="S04 - Parts evaluated dimensionally post cleaning">
              <InfoIcon
                sx={{ verticalAlign: "middle" }}
                style={{ fontSize: "12px" }}
              />
            </span>
          </div>
          <div>
            S05 Vision{" "}
            <span title="S05 - Parts evaluated with vision post cleaning">
              <InfoIcon
                sx={{ verticalAlign: "middle" }}
                style={{ fontSize: "12px" }}
              />
            </span>
          </div>
        </div>
      ),
      route_captured: true,
      type: "aq",
    },
    {
      title: (
        <div>
          <div>120</div>
          <div>FPI Crack Test</div>
        </div>
      ),
      route_captured: false,
      type: "trt",
      quantity: 100,
      quantity_type: "failed",
    },
    {
      title: (
        <div>
          <div>130</div>
          <div>Ultrasonics</div>
        </div>
      ),
      route_captured: true,
      type: "trt",
      quantity: 77,
      quantity_type: "failed",
    },
    {
      title: (
        <div>
          <div>150</div>
          <div>Sentence Inspection</div>
        </div>
      ),
      route_captured: false,
      type: "trt",
      quantity: 11,
      quantity_type: "umr",
      onClick: () => {
        setTabValue(2);
        setTab2Value(1);
      },
    },
    {
      title: (
        <div>
          <div>AQ</div>
          <div>
            S06 Vision{" "}
            <span title="S06 - Parts evaluated with vision post Sentence Inspection">
              <InfoIcon
                sx={{ verticalAlign: "middle" }}
                style={{ fontSize: "12px" }}
              />
            </span>
          </div>
        </div>
      ),
      route_captured: true,
      type: "aq",
    },
  ]);
  const cleaning_data = [
    {
      serial_item: "HLDSOO-01",
      s03_ok: "10",
      s03_nok: "1",
      difference: "-10",
      s04_ok: "9",
      s04_nok: "2",
    },
    {
      serial_item: "HLDSOO-02",
      s03_ok: "8",
      s03_nok: "3",
      difference: "0",
      s04_ok: "8",
      s04_nok: "3",
    },
    {
      serial_item: "HLDSOO-03",
      s03_ok: "8",
      s03_nok: "3",
      difference: "37.5",
      s04_ok: "11",
      s04_nok: "0",
    },
    {
      serial_item: "HLDSOO-04",
      s03_ok: "4",
      s03_nok: "7",
      difference: "75",
      s04_ok: "7",
      s04_nok: "4",
    },
    {
      serial_item: "HLDSOO-05",
      s03_ok: "10",
      s03_nok: "1",
      difference: "0",
      s04_ok: "10",
      s04_nok: "1",
    },
    {
      serial_item: "HLDSOO-06",
      s03_ok: "4",
      s03_nok: "7",
      difference: "150",
      s04_ok: "10",
      s04_nok: "1",
    },
    {
      serial_item: "HLDSOO-07",
      s03_ok: "10",
      s03_nok: "1",
      difference: "-50",
      s04_ok: "5",
      s04_nok: "6",
    },
    {
      serial_item: "HLDSOO-08",
      s03_ok: "8",
      s03_nok: "3",
      difference: "37.5",
      s04_ok: "11",
      s04_nok: "0",
    },
    {
      serial_item: "HLDSOO-09",
      s03_ok: "5",
      s03_nok: "6",
      difference: "120",
      s04_ok: "11",
      s04_nok: "0",
    },
    {
      serial_item: "HLDSOO-10",
      s03_ok: "9",
      s03_nok: "2",
      difference: "22.22",
      s04_ok: "11",
      s04_nok: "0",
    },
    {
      serial_item: "HLDSOO-11",
      s03_ok: "7",
      s03_nok: "4",
      difference: "42.86",
      s04_ok: "10",
      s04_nok: "1",
    },
    {
      serial_item: "HLDSOO-12",
      s03_ok: "5",
      s03_nok: "6",
      difference: "80",
      s04_ok: "9",
      s04_nok: "2",
    },
    {
      serial_item: "HLDSOO-13",
      s03_ok: "10",
      s03_nok: "1",
      difference: "-10",
      s04_ok: "9",
      s04_nok: "2",
    },
    {
      serial_item: "HLDSOO-14",
      s03_ok: "11",
      s03_nok: "0",
      difference: "-27.27",
      s04_ok: "8",
      s04_nok: "3",
    },
    {
      serial_item: "HLDSOO-15",
      s03_ok: "6",
      s03_nok: "5",
      difference: "33.33",
      s04_ok: "8",
      s04_nok: "3",
    },
    {
      serial_item: "HLDSOO-16",
      s03_ok: "7",
      s03_nok: "4",
      difference: "0",
      s04_ok: "7",
      s04_nok: "4",
    },
    {
      serial_item: "HLDSOO-17",
      s03_ok: "9",
      s03_nok: "2",
      difference: "11.11",
      s04_ok: "10",
      s04_nok: "1",
    },
    {
      serial_item: "HLDSOO-18",
      s03_ok: "7",
      s03_nok: "4",
      difference: "14.29",
      s04_ok: "8",
      s04_nok: "3",
    },
    {
      serial_item: "HLDSOO-19",
      s03_ok: "11",
      s03_nok: "0",
      difference: "-36.36",
      s04_ok: "7",
      s04_nok: "4",
    },
  ];

  const repairLegend = [
    {
      title: "FRSD011",
      description: "BRAZE REPAIR",
    },
    {
      title: "FRSD014",
      description: "RESTORE THE AEROFOIL SURFACE FINISH",
    },
    {
      title: "FRSD394",
      description: "WELD REPAIR WORN LOCATION RAILS",
    },
    {
      title: "FRSD160",
      description: "SEAL STRIP REPAIR AND REMOVAL OF CEMENT FROM SEAL SLOTS",
    },
    {
      title: "FRS3253",
      description: "REPAIR MINOR DAMAGE TO SURFACES",
    },
  ];
  const valueFormatter = (value) => `${value}%`;

  const [overAllAqBias, setOverAllAqBias] = useState(78.98);
  const [overAllTrtBias, setOverAllTrtBias] = useState(45.98);

  const [overAllAqBiasBatch, setOverAllAqBiasBatch] = useState(78.98);
  const [overAllTrtBiasBatch, setOverAllTrtBiasBatch] = useState(45.98);

  const { id } = useParams();

  const partSearchChange = (val) => {
    let part = val.target.innerText;
    navigate("/report/part-level-summary/" + part);
  };

  const handleBCClick = (event) => {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  };

  const updateBatchSerialitems = (jc_id) => {
    setShowRightBar(false);
    setBatchSerialBatch(jc_id);
  };

  const to_unit = (mm) => {
    if (!mm) return "N/A";
    if (compareUnit == 1) return Math.round((1000 * mm) / 25.4) / 1000;
    return mm;
  };

  const translate_feature = (f) => {
    switch (f) {
      case 1:
        return "57 total Front Outer Flange";
      case 2:
        return "57 total Front Inner Flange";
      case 3:
        return "57 total Rear Inner Flange";
      case 4:
        return "19 total Slot";
      case 5:
        return "19 total LUG";
    }
  };

  const feature_count = (f) => {
    return f < 4 ? 57 : 19;
  };

  const handleFeatureChange = (event, newValue) => {
    setCompareFeature(newValue.props.value);
  };
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="#"
      onClick={handleBCClick}
    >
      Report
    </Link>,
    <Typography key="4" color="text.primary" component="span">
      Part No: {partDetails ? partDetails.part_number : ""}
    </Typography>,
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        const to_unit2 = (mm) => {
          if (!mm) return "N/A";
          if (compareUnit == 1) return Math.round((1000 * mm) / 25.4) / 1000;
          return mm;
        };
        const getPartDetails = async (id) => {
          setShowBackDrop(true);
          //try {
          const part_data = await reportApi.getPartDetails(
            id,
            compareFeature,
            batchSerialBatch
          );
          if (!part_data.success) {
            setPartDetails(part_data.details);
            setAllJobCards(part_data.job_cards);
            setJobCardDetails(part_data.so3_4.details);
            setFullPartObject(part_data);
            //set overall bias.

            /// for umr serials
            setOverAllAqBias(
              (
                part_data.umr_serials
                  .map((obj) => {
                    return (
                      Math.round(
                        ((to_unit2(obj.aq_max) - to_unit2(obj.nominal)) /
                          to_unit2(obj.nominal)) *
                          100000
                      ) / 1000
                    );
                  })
                  .reduce((a, b) => a + b, 0) / part_data.umr_serials.length ||
                0
              ).toFixed(2)
            );

            setOverAllTrtBias(
              (
                part_data.umr_serials
                  .map((obj) => {
                    return (
                      Math.round(
                        ((to_unit2(
                          find_min_max(obj.trt_min, obj.trt_max, "max")
                        ) -
                          to_unit2(obj.nominal)) /
                          to_unit2(obj.nominal)) *
                          100000
                      ) / 1000
                    );
                  })
                  .reduce((a, b) => a + b, 0) / part_data.umr_serials.length ||
                0
              ).toFixed(2)
            );

            //for batch serials //
            setOverAllAqBiasBatch(
              (
                part_data.batch_serials
                  .map((obj) => {
                    return (
                      Math.round(
                        ((to_unit2(obj.aq_max) - to_unit2(obj.nominal)) /
                          to_unit2(obj.nominal)) *
                          100000
                      ) / 1000
                    );
                  })
                  .reduce((a, b) => a + b, 0) /
                  part_data.batch_serials.length || 0
              ).toFixed(2)
            );

            setOverAllTrtBiasBatch(
              (
                part_data.batch_serials
                  .map((obj) => {
                    return (
                      Math.round(
                        ((to_unit2(
                          find_min_max(obj.trt_min, obj.trt_max, "max")
                        ) -
                          to_unit2(obj.nominal)) /
                          to_unit2(obj.nominal)) *
                          100000
                      ) / 1000
                    );
                  })
                  .reduce((a, b) => a + b, 0) /
                  part_data.batch_serials.length || 0
              ).toFixed(2)
            );
          }

          setShowBackDrop(false);
        };

        getPartsData();
        getPartDetails(id);
      } catch (error) {
        navigate("/login");
      }
    }
    fetchData();
  }, [navigate, id, compareFeature, compareUnit, batchSerialBatch]);

  const getJobCardsData = async () => {
    setShowBackDrop(true);
    try {
      const jc_data = await reportApi.getJobCards();
      setAllJobCards(jc_data.job_cards);
      //setAllSerialItems(jc_data.serialized_items);
    } catch (e) {
      console.log(e);
    }
    setShowBackDrop(false);
  };

  const getPartsData = async () => {
    setShowBackDrop(true);
    try {
      const parts_data = await reportApi.getParts();
      setAllParts(parts_data.parts);
    } catch (e) {
      console.log(e);
    }
    setShowBackDrop(false);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const a11yProps2 = (index) => {
    return {
      id: `simple-tab2-${index}`,
      "aria-controls": `simple-tabpanel2-${index}`,
    };
  };
  const [tabValue, setTabValue] = useState(1);
  const [tab2Value, setTab2Value] = useState(0);

  const find_min_max = (n1, n2, type) => {
    switch (type) {
      case "max":
        return n1 > n2 ? n1 : n2;
      case "min":
        if (!n1 || !n2) return false;
        return n1 > n2 ? n2 : n1;
    }
  };

  const bias_color = (percent) => {
    if (percent < 10 && percent > -10) return "#6fab60";
    if (percent < 15 && percent > -15) return "#FFBF00";
    return "#d82c2c";
  };

  return (
    <MasterLayout>
      <Box className={classes.home_main_container}>
        <Container maxWidth={false}>
          <Grid item className="margin-overview-row">
            <Card>
              <CardContent>
                <Autocomplete
                  sx={{
                    backgroundColor: "rgb(245, 254, 254);",
                    "& .MuiOutlinedInput-root": {
                      paddingRight: "10px!important",
                    },
                  }}
                  id="checkboxes-tags-demo"
                  options={allParts}
                  onChange={partSearchChange}
                  getOptionLabel={(option) => option.part_number}
                  isOptionEqualToValue={(option, value) =>
                    option.jc_id === value.jc_id
                  }
                  style={{ minWidth: 350, display: "inline-flex" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search by Part Number"
                      placeholder="Type a Part number"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon
                              fontSize="large"
                              style={{ color: "#000" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid container>
            <Grid item xs={6}>
              <Breadcrumbs
                className="bc-left-margin"
                separator="›"
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={(event, newValue) => {
                setTabValue(newValue);
                if (newValue < 2) page_id = "v1_p" + (newValue + 1);
                else page_id = "v1_p" + (tab2Value + 3);
              }}
              aria-label="basic tabs example"
              className="custom-tab-style"
            >
              <Tab label="AddQual" {...a11yProps(0)} />
              <Tab label="TRT" {...a11yProps(1)} />
              <Tab label="Compare" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <div className="no-padding-tabs">
            <CustomTabPanel value={tabValue} index={0}>
              <Grid container className="margin-overview-row">
                <OverviewDetailsPart
                  partDetails={partDetails}
                  batches={partDetails ? partDetails.no_of_batches_trt : false}
                  fill="#0d1444"
                  color="#fff"
                  setShowRightBar={setShowRightBar}
                  viewBtn="hide"
                />
                <OverviewDetailsPart2
                  partDetails={partDetails}
                  duration={partDetails ? partDetails.aq_duration : ""}
                  features={partDetails ? partDetails.aq_features_measured : ""}
                  duration_tooltip={
                    partDetails ? partDetails.aq_duration_tooltip : ""
                  }
                  setShowRightBar={setShowRightBar}
                  width={8}
                  setLocationDetailsOpen={setLocationDetailsOpen}
                />
              </Grid>

              <h4 className="serial-key-summary-heading">
                <strong>OK/NOK Comparison for Batch CNG8292</strong> (Before &
                After Cleaning)
              </h4>

              <br />
              <Grid container>
                <ComparisonOkNok
                  width={6}
                  ok={149}
                  nok={60}
                  color="#fff"
                  title="Before Cleaning (209 Locations)"
                />
                <ComparisonOkNok
                  width={6}
                  ok={169}
                  nok={40}
                  trend={169 / 149}
                  color="#ededed"
                  title="After Cleaning (209 Locations)"
                />
              </Grid>
              <br />
              <div className="comparison-table">
                <table cellSpacing="0" cellPadding="0">
                  <tbody>
                    <tr>
                      <td className="center tborder" colSpan={4}>
                        S03 (11 Total Locations Before Cleaning)
                      </td>
                      <td className="center tborder" colSpan={4}>
                        S04 (11 Total Locations After Cleaning)
                      </td>
                    </tr>
                    <tr className="dark-bg">
                      <td>SERIAL ITEM</td>
                      <td>OK</td>
                      <td>NOK</td>
                      <td width={200}></td>

                      <td>SERIAL ITEM</td>
                      <td>OK</td>
                      <td>NOK</td>
                      <td width={200}></td>
                    </tr>
                    {cleaning_data.slice(0, 6).map((obj) => (
                      <tr key={obj.serial_item} className="data">
                        <td>{obj.serial_item}</td>
                        <td>{obj.s03_ok}</td>
                        <td>{obj.s03_nok}</td>
                        <td></td>

                        <td>{obj.serial_item}</td>
                        <td>{obj.s04_ok}</td>
                        <td>{obj.s04_nok}</td>
                        <td></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="expand-btn">
                <button
                  className="view-all-btn see-details-btn"
                  onClick={() => {
                    setCleaningDetailsOpen(true);
                  }}
                >
                  Expand
                </button>
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <>
                <Grid container className="margin-overview-row">
                  <OverviewDetailsPart
                    partDetails={partDetails}
                    batches={
                      partDetails ? partDetails.no_of_batches_trt : false
                    }
                    fill="#0d1444"
                    color="#fff"
                    setShowRightBar={setShowRightBar}
                  />
                  <OverviewDetailsPart2
                    partDetails={partDetails}
                    duration={partDetails ? partDetails.trt_duration : ""}
                    features={
                      partDetails ? partDetails.trt_features_measured : ""
                    }
                    duration_tooltip={
                      partDetails ? partDetails.trt_duration_tooltip : ""
                    }
                    setShowRightBar={setShowRightBar}
                    width={5}
                  />
                  <OverviewDetailsPart3
                    partDetails={partDetails}
                    timelineOpen={timelineOpen}
                    setTimelineOpen={setTimelineOpen}
                  />
                </Grid>

                <Grid>
                  <Collapse in={timelineOpen}>
                    <Card>
                      <CardContent>
                        <Grid container>
                          <Grid item sm={12}>
                            <h4>
                              <strong>Parts Process flow</strong>
                              &nbsp;&nbsp;&nbsp;&nbsp;Total Parts{" "}
                              <span className="addqual-label">100</span>
                              <div className="route-legend">
                                <span className="not-captured"></span> Routes
                                not captured
                                <span className="failed"></span> Failed
                                <span className="umr"></span> UMR
                              </div>
                            </h4>
                          </Grid>
                        </Grid>
                        <div className="app-timeline-wrapper">
                          <div className="app-timeline">
                            <span className="app-timeline-display-line"></span>

                            {timeline &&
                              timeline.map((obj, i) => (
                                <div
                                  key={i}
                                  className={
                                    "app-timeline-card-wrapper " +
                                    (i % 2 == 0
                                      ? "app-timeline-card-align-top"
                                      : "app-timeline-card-align-bottom") +
                                    " app-timeline-darkblue-box"
                                  }
                                >
                                  <span
                                    className={
                                      "app-timeline-dot " +
                                      (obj.route_captured ? "" : "not-recorded")
                                    }
                                  ></span>
                                  <div
                                    className={
                                      "app-time-line-card " +
                                      (obj.type == "aq" ? "aq-card" : "")
                                    }
                                  >
                                    <div className="app-timeline-text">
                                      {obj.title}
                                    </div>
                                    {obj.quantity ? (
                                      <span
                                        className={
                                          "app-timeline-notification " +
                                          obj.quantity_type
                                        }
                                        onClick={obj.onClick}
                                      >
                                        {obj.quantity}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Collapse>
                </Grid>

                <Grid container className="margin-overview-row">
                  <Grid item xs={7}>
                    <Card className="report-overview-chart-inner">
                      <CardContent>
                        <Grid container>
                          <Grid item xs={6}>
                            <h4>
                              <strong>Quality Outputs</strong>
                            </h4>
                            <p>By Batch Number</p>
                          </Grid>
                          {/* <Grid item xs={6}>
                      <div
                        className="float-right view-all-btn see-details-btn"
                        onClick={() => setShowRightBar(true)}
                      >
                        View Process
                      </div>
                    </Grid> */}
                        </Grid>
                        <br />
                        {/* {barData ? <Bar data={barData} /> : ""} */}
                        {allJobCards && allJobCards.length > 0 ? (
                          <BarChart
                            series={[
                              {
                                data: allJobCards.map((obj) => {
                                  return (
                                    Math.round(
                                      (obj.per_scrapped / obj.total) * 10000
                                    ) / 100
                                  );
                                }),
                                stack: "A",
                                label: "UMR",
                                color: "#0d1444",
                                valueFormatter,
                              },
                              {
                                data: allJobCards.map((obj) => {
                                  return (
                                    Math.round(
                                      ((obj.total - obj.per_scrapped) /
                                        obj.total) *
                                        10000
                                    ) / 100
                                  );
                                }),
                                stack: "A",
                                label: "Repair",
                                color: "#c6c8e8",
                                valueFormatter,
                              },
                            ]}
                            height={350}
                            xAxis={[
                              {
                                scaleType: "band",
                                data: allJobCards.map((obj) => {
                                  return obj.jc_id + `\n (x${obj.total})`;
                                }),
                                categoryGapRatio: 0.4,
                              },
                            ]}
                          />
                        ) : null}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={5}>
                    <Card>
                      <CardContent>
                        <h4>
                          <strong>Features</strong>
                        </h4>
                        <p>Top 5 Features with most recorded defects </p>
                        <div className="location-part-cover">
                          <div className="big tile-c">
                            <strong>Convex Aerofoil Surface 2</strong>
                            <span>343 Cracks</span>
                          </div>
                          <div className="small-main">
                            <div className="small-main-inner">
                              <div className="small-1 tile-c">
                                <strong>Nodule Array 14 (4 Positions)</strong>
                                <span>141 Cracks</span>
                              </div>
                              <div className="small-2 tile-c">
                                <strong>Leading Edge Area 3</strong>
                                <span>120 Cracks</span>
                              </div>
                            </div>
                            <div className="small-main-inner">
                              <div className="medium-1 tile-c">
                                <strong>Trailing Edge 4</strong>
                                <span>211 Cracks</span>
                              </div>
                              <div className="medium-2 tile-c">
                                <strong>Air Hole 7B</strong>
                                <span>109 Cracks</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Grid container className="margin-overview-row">
                  <Grid item xs={6}>
                    <Card className="report-overview-chart-inner">
                      <CardContent className="job-card-comparison-chart-parent">
                        <Grid container>
                          <Grid item xs={8}>
                            <h4>
                              <MenuIcon style={{ verticalAlign: "middle" }} />
                              &nbsp;
                              <strong>Sentence overview - Repairs</strong>
                              <br />
                              <button
                                className="view-all-btn"
                                onClick={() => setLegendRBOpen(true)}
                              >
                                <VisibilityIcon
                                  style={{ verticalAlign: "middle" }}
                                />{" "}
                                <span>Code Details</span>
                              </button>
                            </h4>
                          </Grid>
                        </Grid>
                        <div className="cg2">
                          <div className="circle-graphs">
                            <div className="s1">
                              <h4>FRSD011</h4>
                              <div>Applies to 93 serial items</div>
                            </div>
                            <div className="s2">
                              <h4>FRSD014</h4>
                              <div>Applies to 92 serial items</div>
                            </div>
                            <div className="s3">
                              <h4>FRSD160</h4>
                              <div>Applies to 92 serial items</div>
                            </div>
                            <div className="s4">
                              <h4>FRSD394</h4>
                              <div>Applies to 90 serial items</div>
                            </div>
                            <div className="s5">
                              <h4>FRS3253</h4>
                              <div>Applies to 17 serial items</div>
                            </div>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card>
                      <CardContent className="job-card-comparison-chart-parent">
                        <h4>
                          <MenuIcon style={{ verticalAlign: "middle" }} />
                          &nbsp;
                          <strong>Sentence overview - UMR Reasons</strong>
                        </h4>

                        <div className="cg2 cgh">
                          <div className="circle-graphs">
                            <div className="s6">
                              <h4>Burning/Erosion</h4>
                              <div>Applies to 3 serial items</div>
                            </div>
                            <div className="s7">
                              <h4>Cracked</h4>
                              <div>Applies to 2 serial items</div>
                            </div>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tab2Value}
                  onChange={(event, newValue) => {
                    setTab2Value(newValue);
                    page_id = "v1_p" + (newValue + 3);
                  }}
                  aria-label="compare tabs"
                  className="custom-tab-style"
                >
                  <Tab label="AddQual Operations" {...a11yProps2(0)} />
                  <Tab label="UMR Serial Items" {...a11yProps2(1)} />
                  <Tab label="Batch Serial Items" {...a11yProps2(2)} />
                </Tabs>
              </Box>
              <div className="no-padding-tabs">
                <CustomTabPanel value={tab2Value} index={0}>
                  <Grid container>
                    <OverviewDetails1
                      jobCardDetails={jobCardDetails}
                      fill="#000"
                      color="#fff"
                    />
                    <Grid
                      container
                      item
                      xl={8}
                      lg={8}
                      sm={8}
                      xs={12}
                      style={{ position: "relative" }}
                    >
                      <div className="ok-nok-label">
                        Comparison of OK/NOK{" "}
                        <strong>
                          {translate_feature(compareFeature)} locations
                        </strong>{" "}
                        across all <strong>19 Serial Items</strong>
                      </div>
                      <ComparisonOkNok
                        width={6}
                        ok={
                          fullPartObject &&
                          fullPartObject.so3_4_feature_counts.s03_ok
                        }
                        nok={
                          fullPartObject &&
                          fullPartObject.so3_4_feature_counts.s03_nok
                        }
                        color="#fff"
                        title={
                          "Before Cleaning (" +
                          feature_count(compareFeature) +
                          " Locations)"
                        }
                      />
                      <ComparisonOkNok
                        width={6}
                        ok={
                          fullPartObject &&
                          fullPartObject.so3_4_feature_counts.s04_ok
                        }
                        nok={
                          fullPartObject &&
                          fullPartObject.so3_4_feature_counts.s04_nok
                        }
                        trend={
                          fullPartObject &&
                          fullPartObject.so3_4_feature_counts.s04_ok /
                            fullPartObject.so3_4_feature_counts.s03_ok
                        }
                        color="#ededed"
                        title={
                          "After Cleaning (" +
                          feature_count(compareFeature) +
                          " Locations)"
                        }
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <h4 className="">
                    <FormControl style={{ width: "250px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Comparison for
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={compareFeature}
                        label="Comparison for"
                        onChange={handleFeatureChange}
                      >
                        <MenuItem value={1}>Front Outer Flange</MenuItem>
                        <MenuItem value={2}>Front Inner Flange</MenuItem>
                        <MenuItem value={3}>Rear Inner Flange</MenuItem>
                        <MenuItem value={4}>Slot</MenuItem>
                        <MenuItem value={5}>LUG</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl style={{ width: "150px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Measurements
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={compareUnit}
                        label="Measurements in"
                        onChange={(event, newValue) => {
                          setCompareUnit(newValue.props.value);
                        }}
                      >
                        <MenuItem value={1}>Inches</MenuItem>
                        <MenuItem value={2}>mm</MenuItem>
                      </Select>
                    </FormControl>
                    <div className="compare-header">
                      <div>
                        <span>Nominal </span>
                        <span>
                          {jobCardDetails && to_unit(jobCardDetails.nominal)}
                        </span>
                      </div>
                      <div>
                        <span>Tol+ </span>
                        <span>
                          {jobCardDetails && to_unit(jobCardDetails.tol_p)}
                        </span>
                      </div>
                      <div>
                        <span>Tol- </span>
                        <span>
                          {jobCardDetails && to_unit(jobCardDetails.tol_n)}
                        </span>
                      </div>
                    </div>
                  </h4>
                  <div className="comparison-table">
                    <table cellSpacing="0" cellPadding="0">
                      <tbody>
                        <tr>
                          <td className="tborder" colSpan={2}>
                            S03 (Parts Before Cleaning)
                          </td>
                          <td className="compare-col" width={130}>
                            Difference (Actual)
                          </td>
                          <td className="tborder" colSpan={2}>
                            S04 (Parts After Cleaning)
                          </td>
                        </tr>
                        <tr className="dark-bg">
                          <td>SERIAL ITEM</td>
                          <td>
                            Actual (Max) ({compareUnit == 2 ? "mm" : "Inches"})
                          </td>

                          <td className="compare-col">S03 &gt; S04</td>

                          <td style={{ color: "#40466d" }}>SERIAL ITEM</td>
                          <td>
                            Actual (Max) ({compareUnit == 2 ? "mm" : "Inches"})
                          </td>
                        </tr>
                        {jobCardDetails &&
                          jobCardDetails.values.map((obj) => (
                            <tr key={obj.serial_number} className="data3">
                              <td>{obj.serial_number}</td>
                              <td>{to_unit(obj.s03_max)}</td>

                              <td className="compare-col">
                                {to_unit(obj.diff)}
                              </td>
                              <td></td>
                              <td>{to_unit(obj.s04_max)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <hr />
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={tab2Value} index={1}>
                  <h4 className="">
                    <strong>Summary: </strong>All Measurements by AddQual GOM
                    appear more consistent and are higher than TRT Measurements
                    <div className="converter-scale">
                      <FormControl style={{ width: "250px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Measurements in
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={compareUnit}
                          label="Measurements in"
                          onChange={(event, newValue) => {
                            setCompareUnit(newValue.props.value);
                          }}
                        >
                          <MenuItem value={1}>Inches</MenuItem>
                          <MenuItem value={2}>mm</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </h4>
                  <br />
                  <h4 className="">
                    <FormControl style={{ width: "250px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Comparison for
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={compareFeature}
                        label="Comparison for"
                        onChange={handleFeatureChange}
                      >
                        <MenuItem value={1}>Front Outer Flange</MenuItem>
                        <MenuItem value={2}>Front Inner Flange</MenuItem>
                        <MenuItem value={3}>Rear Inner Flange</MenuItem>
                        <MenuItem value={4}>Slot</MenuItem>
                        <MenuItem value={5}>LUG</MenuItem>
                      </Select>
                    </FormControl>
                    <div className="compare-header">
                      <div>
                        <span>Nominal </span>
                        <span>
                          {fullPartObject &&
                            to_unit(fullPartObject.umr_serials_details.nominal)}
                        </span>
                      </div>
                      <div>
                        <span>Tol+ </span>
                        <span>
                          {fullPartObject &&
                            to_unit(fullPartObject.umr_serials_details.tol_p)}
                        </span>
                      </div>
                      <div>
                        <span>Tol- </span>
                        <span>
                          {fullPartObject &&
                            to_unit(fullPartObject.umr_serials_details.tol_n)}
                        </span>
                      </div>
                    </div>
                  </h4>
                  <div className="comparison-table">
                    <table cellSpacing="0" cellPadding="0">
                      <tbody>
                        <tr>
                          <td className="tborder2" colSpan={4}>
                            AddQual
                          </td>
                          <td className="compare-col" width={200}>
                            Bias (MAX)
                          </td>
                          <td className="compare-col2" width={200}>
                            Bias (MAX)
                          </td>
                          <td className="tborder2" colSpan={4}>
                            TRT
                          </td>
                        </tr>
                        <tr className="dark-bg">
                          <td>SERIAL ITEM</td>
                          <td>Min</td>
                          <td>Max</td>
                          <td>Range</td>

                          <td className="compare-col">AQ</td>
                          <td className="compare-col2">TRT</td>

                          <td style={{ color: "#40466d" }}>SERIAL ITEM</td>
                          <td>Min</td>
                          <td>Max</td>
                          <td>Range</td>
                        </tr>
                        {fullPartObject &&
                          fullPartObject.umr_serials.map((obj) => (
                            <tr key={obj.serial_number} className="data3">
                              <td>{obj.serial_number}</td>
                              <td>{to_unit(obj.aq_min)}</td>
                              <td>{to_unit(obj.aq_max)}</td>
                              <td>
                                {(
                                  to_unit(obj.aq_max) - to_unit(obj.aq_min)
                                ).toFixed(3)}
                              </td>

                              <td className="compare-col">
                                {(
                                  to_unit(obj.aq_max) - to_unit(obj.nominal)
                                ).toFixed(3)}{" "}
                                <Chip
                                  label={
                                    (
                                      ((to_unit(obj.aq_max) -
                                        to_unit(obj.nominal)) /
                                        to_unit(obj.nominal)) *
                                      100
                                    ).toFixed(2) + "%"
                                  }
                                  color="success"
                                  style={{
                                    backgroundColor: bias_color(
                                      ((to_unit(obj.aq_max) -
                                        to_unit(obj.nominal)) /
                                        to_unit(obj.nominal)) *
                                        100
                                    ),
                                  }}
                                />
                              </td>
                              <td className="compare-col2">
                                {(
                                  to_unit(
                                    find_min_max(
                                      obj.trt_min,
                                      obj.trt_max,
                                      "max"
                                    )
                                  ) - to_unit(obj.nominal)
                                ).toFixed(3)}{" "}
                                <Chip
                                  label={
                                    (
                                      ((to_unit(
                                        find_min_max(
                                          obj.trt_min,
                                          obj.trt_max,
                                          "max"
                                        )
                                      ) -
                                        to_unit(obj.nominal)) /
                                        to_unit(obj.nominal)) *
                                      100
                                    ).toFixed(2) + "%"
                                  }
                                  color="success"
                                  style={{
                                    backgroundColor: bias_color(
                                      ((to_unit(
                                        find_min_max(
                                          obj.trt_min,
                                          obj.trt_max,
                                          "max"
                                        )
                                      ) -
                                        to_unit(obj.nominal)) /
                                        to_unit(obj.nominal)) *
                                        100
                                    ),
                                  }}
                                />
                              </td>

                              <td></td>
                              <td>
                                {to_unit(
                                  find_min_max(obj.trt_min, obj.trt_max, "min")
                                )}
                              </td>
                              <td>
                                {to_unit(
                                  find_min_max(obj.trt_min, obj.trt_max, "max")
                                )}
                              </td>
                              <td>
                                {!obj.trt_min || !obj.trt_max
                                  ? "N/A"
                                  : to_unit(
                                      find_min_max(
                                        obj.trt_min,
                                        obj.trt_max,
                                        "max"
                                      ) -
                                        find_min_max(
                                          obj.trt_min,
                                          obj.trt_max,
                                          "min"
                                        )
                                    ).toFixed(3)}
                              </td>
                            </tr>
                          ))}
                        <tr className="data3">
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>

                          <td className="compare-col">
                            Overall Bias &nbsp;
                            <Chip
                              label={overAllAqBias + "%"}
                              color="success"
                              style={{
                                backgroundColor: bias_color(overAllAqBias),
                              }}
                            />
                          </td>
                          <td className="compare-col2">
                            Overall Bias &nbsp;
                            <Chip
                              label={overAllTrtBias + "%"}
                              color="success"
                              style={{
                                backgroundColor: bias_color(overAllTrtBias),
                              }}
                            />
                          </td>

                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={tab2Value} index={2}>
                  <Grid container>
                    <OverviewDetails1
                      jobCardDetails={jobCardDetails}
                      fill="#000"
                      color="#fff"
                      setShowRightBar={setShowRightBar}
                      batchSerialBatch={batchSerialBatch}
                    />
                    <ComparisonBatchSerial
                      handleFeatureChange={handleFeatureChange}
                      compareFeature={compareFeature}
                      compareUnit={compareUnit}
                      setCompareUnit={setCompareUnit}
                      fullPartObject={fullPartObject}
                      to_unit={to_unit}
                    />
                  </Grid>
                  <div className="comparison-table">
                    <table cellSpacing="0" cellPadding="0">
                      <tbody>
                        <tr>
                          <td className="tborder2" colSpan={4}>
                            AddQual
                          </td>
                          <td className="compare-col" width={200}>
                            Bias (MAX)
                          </td>
                          <td className="compare-col2" width={200}>
                            Bias (MAX)
                          </td>
                          <td className="tborder2" colSpan={4}>
                            TRT
                          </td>
                        </tr>

                        <tr className="dark-bg">
                          <td>SERIAL ITEM</td>
                          <td>Min</td>
                          <td>Max</td>
                          <td>Range</td>

                          <td className="compare-col">AQ</td>
                          <td className="compare-col2">TRT</td>

                          <td style={{ color: "#40466d" }}>SERIAL ITEM</td>
                          <td>Min</td>
                          <td>Max</td>
                          <td>Range</td>
                        </tr>

                        {fullPartObject &&
                          fullPartObject.batch_serials.map((obj) => (
                            <tr key={obj.serial_number} className="data3">
                              <td>{obj.serial_number}</td>
                              <td>{to_unit(obj.aq_min)}</td>
                              <td>{to_unit(obj.aq_max)}</td>
                              <td>
                                {(
                                  to_unit(obj.aq_max) - to_unit(obj.aq_min)
                                ).toFixed(3)}
                              </td>

                              <td className="compare-col">
                                {(
                                  to_unit(obj.aq_max) - to_unit(obj.nominal)
                                ).toFixed(3)}{" "}
                                <Chip
                                  label={
                                    (
                                      ((to_unit(obj.aq_max) -
                                        to_unit(obj.nominal)) /
                                        to_unit(obj.nominal)) *
                                      100
                                    ).toFixed(2) + "%"
                                  }
                                  color="success"
                                  style={{
                                    backgroundColor: bias_color(
                                      ((to_unit(obj.aq_max) -
                                        to_unit(obj.nominal)) /
                                        to_unit(obj.nominal)) *
                                        100
                                    ),
                                  }}
                                />
                              </td>
                              <td className="compare-col2">
                                {(
                                  to_unit(
                                    find_min_max(
                                      obj.trt_min,
                                      obj.trt_max,
                                      "max"
                                    )
                                  ) - to_unit(obj.nominal)
                                ).toFixed(3)}{" "}
                                <Chip
                                  label={
                                    (
                                      ((to_unit(
                                        find_min_max(
                                          obj.trt_min,
                                          obj.trt_max,
                                          "max"
                                        )
                                      ) -
                                        to_unit(obj.nominal)) /
                                        to_unit(obj.nominal)) *
                                      100
                                    ).toFixed(2) + "%"
                                  }
                                  color="success"
                                  style={{
                                    backgroundColor: bias_color(
                                      ((to_unit(
                                        find_min_max(
                                          obj.trt_min,
                                          obj.trt_max,
                                          "max"
                                        )
                                      ) -
                                        to_unit(obj.nominal)) /
                                        to_unit(obj.nominal)) *
                                        100
                                    ),
                                  }}
                                />
                              </td>

                              <td></td>
                              <td>
                                {to_unit(
                                  find_min_max(obj.trt_min, obj.trt_max, "min")
                                )}
                              </td>
                              <td>
                                {to_unit(
                                  find_min_max(obj.trt_min, obj.trt_max, "max")
                                )}
                              </td>
                              <td>
                                {!obj.trt_min || !obj.trt_max
                                  ? "N/A"
                                  : to_unit(
                                      find_min_max(
                                        obj.trt_min,
                                        obj.trt_max,
                                        "max"
                                      ) -
                                        find_min_max(
                                          obj.trt_min,
                                          obj.trt_max,
                                          "min"
                                        )
                                    ).toFixed(3)}
                              </td>
                            </tr>
                          ))}

                        <tr className="data3">
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>

                          <td className="compare-col">
                            Overall Bias &nbsp;
                            <Chip
                              label={overAllAqBiasBatch + "%"}
                              color="success"
                              style={{
                                backgroundColor: bias_color(overAllAqBiasBatch),
                              }}
                            />
                          </td>
                          <td className="compare-col2">
                            Overall Bias &nbsp;
                            <Chip
                              label={overAllTrtBiasBatch + "%"}
                              color="success"
                              style={{
                                backgroundColor:
                                  bias_color(overAllTrtBiasBatch),
                              }}
                            />
                          </td>

                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CustomTabPanel>
              </div>
            </CustomTabPanel>
          </div>
        </Container>
      </Box>

      <RightSideBarJC
        showRightBar={showRightBar}
        setShowRightBar={setShowRightBar}
        items={allJobCards}
        name={jobCardDetails ? jobCardDetails.part_number : ""}
        tab_values={{ first: tabValue, second: tab2Value }}
        updateBatchSerialitems={updateBatchSerialitems}
      />
      <RightSideBarLocationDetail
        showRightBar={locationDetailsOpen}
        setShowRightBar={setLocationDetailsOpen}
        items={allJobCards}
        name={jobCardDetails ? jobCardDetails.jc_id : ""}
      />
      <RightSideBarCleaningDetail
        showRightBar={cleaningDetailsOpen}
        setShowRightBar={setCleaningDetailsOpen}
        cleaning_data={cleaning_data}
        items={allJobCards}
        name={jobCardDetails ? jobCardDetails.jc_id : ""}
      />
      <RightSideBarLegend
        title="Repair code details"
        showRightBar={legendRBOpen}
        setShowRightBar={setLegendRBOpen}
        legends={repairLegend}
      />

      <BackDrop showBackDrop={showBackDrop} />
    </MasterLayout>
  );
};

export default PartLevelSummaryPage;
