import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./styles/global.css";
import Login from "./pages/auth/login";
import RemoteLogin from "./pages/auth/remoteLogin";
import Signup from "./pages/auth/signup";
import Home from "./pages/home/home";
import Summary from "./pages/summary/summary";
import Status from "./pages/status/status";
import NoMatch from "./pages/noMatch/noMatch";
import Form from "./pages/form/form";
import AccountRecovery from "./pages/auth/accountRecovery";
import RecordForm from "./pages/form/record_form";
import Form3C from "./pages/form/form_3c";
import JobCardPage from "./pages/reports/job_card_page";
import SerializedItemPage from "./pages/reports/serialized_item_page";
import SerializedItemDetailsPage from "./pages/reports/serialized_item_details_page";
import Form3CClient from "./pages/form/form_3c_client";
import PartLevelSummaryPage from "./pages/reports/part_level_summary_page";

const App = () => {
  const localhosts = ["localhost", "127.0.0.1"];
  if (location.protocol == "http:" && !localhosts.includes(location.hostname))
    location.protocol = "https:";

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/summary" element={<Summary />} />
        <Route path="/status" element={<Status />} />
        <Route
          path="/report/part-level-summary/:id"
          element={<PartLevelSummaryPage />}
        />
        <Route path="/report/job-card/:id" element={<JobCardPage />} />
        <Route
          path="/report/jc/serialized-item/:id"
          element={<SerializedItemPage />}
        />
        <Route
          path="/report/jc/serialized-item-details/:id"
          element={<SerializedItemDetailsPage />}
        />
        <Route path="/acquisition_forms" element={<Form />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/account_recovery" element={<AccountRecovery />} />
        <Route path="/remote_login" element={<RemoteLogin />} />
        <Route path="/record_form_data" element={<RecordForm />} />
        <Route path="/record_3c" element={<Form3C />} />
        <Route path="/client/register_3c" element={<Form3CClient />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
