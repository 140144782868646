import React from "react";
import { Grid } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";

const ComparisonOkNok = (props) => {
  return (
    <Grid item xl={props.width} lg={props.width} sm={props.width} xs={12}>
      <div
        style={{ background: props.color }}
        className="ok-nok-graph-container"
      >
        <h5>{props.title}</h5>
        <div>
          <div className="ok">OK</div>
          <div className="ok-1"></div>
          <div className="ok-2">{props.ok}</div>

          <div className="nok">NOK</div>
          <div className="nok-1"></div>
          <div className="nok-2">{props.nok}</div>

          {props.trend ? (
            <div className={"trend " + (props.trend - 1 < 0 ? "down" : "")}>
              {props.trend - 1 > 0 ? (
                <TrendingUpIcon style={{ verticalAlign: "bottom" }} />
              ) : props.trend - 1 < 0 ? (
                <TrendingDownIcon style={{ verticalAlign: "bottom" }} />
              ) : null}
              {((props.trend - 1) * 100).toFixed(2)}%
            </div>
          ) : null}
        </div>
      </div>
    </Grid>
  );
};
export default ComparisonOkNok;
