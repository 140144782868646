import { React, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Container,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import MasterLayout from "../../components/common/masterLayout";
import BackDrop from "../../components/common/backDrop";
import SearchIcon from "@mui/icons-material/Search";
// import DownloadIcon from "@mui/icons-material/Download";
import OverviewDetails1 from "../../components/report/overviewDetails1";
import OverviewDetails2 from "../../components/report/overviewDetails2";
import InfoIcon from "@mui/icons-material/Info";
import reportApi from "../../api/report/reportApi";
import RightSideBar from "../../components/common/rightSideBar";
import ReportCardTile from "../../components/report/report-card-tile";
import LayersIcon from "@mui/icons-material/Layers";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ReplayIcon from "@mui/icons-material/Replay";
// import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { ResponsiveFunnel } from "@nivo/funnel";
import RightSideBarJC from "../../components/common/rightSideBarJC";
import RightSideBarLegend from "../../components/common/rightSideBarLegend";
import VisibilityIcon from "@mui/icons-material/Visibility";

const useStyles = makeStyles(() => ({
  home_main_container: {
    width: "100%",
    overflow: "auto",
  },
}));

const JobCardPage = () => {
  page_id = "v1_p6";
  const classes = useStyles();
  const navigate = useNavigate();

  const [showBackDrop, setShowBackDrop] = useState(false);
  const [showRightBar, setShowRightBar] = useState(false);
  const [showRightBarJC, setShowRightBarJC] = useState(false);
  const [legendRBOpen, setLegendRBOpen] = useState(false);

  const [allJobCards, setAllJobCards] = useState([]); // job cards for this client //
  const [jobCardDetails, setJobCardDetails] = useState(null);
  const [serialisedItems, setSerialisedItems] = useState([]);
  const [overallYield, setOverallYield] = useState("");

  const [rdata, setRData] = useState([
    {
      id: "Parts In",
      value: 100,
      label: "Parts In",
    },
    {
      id: "Cleaning",
      value: 100,
      label: "Cleaning",
    },
    {
      id: "Heat Tint",
      value: 100,
      label: "Heat Tint",
    },
    {
      id: "FPI",
      value: 100,
      label: "FPI",
    },
  ]);

  const stagesLegend = [
    {
      title: "Parts In",
      data: [
        {
          number: "RC 999999",
          value: "Parts In",
        },
        {
          number: "10",
          value: "Check quantity received",
        },
        {
          number: "20",
          value: "Book parts on PLH",
        },
        {
          number: "25",
          value: "Divide parts into batches",
        },
        {
          number: "40",
          value: "Inspect Obvious damage",
        },
        {
          number: "57",
          value: "Pass visual Inspection",
        },
      ],
    },
    {
      title: "Cleaning",
      data: [
        {
          number: "RC 001496",
          value: "Cleaning",
        },
        {
          number: 20,
          value: "Degrease",
        },
        {
          number: 25,
          value: "Remove Seal Strips",
        },
        {
          number: 30,
          value: "Remove Details - Cooling tubes and welds",
        },
        {
          number: 35,
          value: "Blast to Remove TBC",
        },
        {
          number: 37,
          value: "Remove bond coat",
        },
        {
          number: 65,
          value: "Pre-Strip abrasive blasting",
        },
        {
          number: 67,
          value: "Strip aluminised coating",
        },
        {
          number: 68,
          value: "Pre heat tint blast",
        },
        {
          number: 69,
          value: "Compressed air clean to remove blasting",
        },
      ],
    },
    {
      title: "Heat Tint",
      data: [
        {
          number: "RC 001496",
          value: "Heat Tint",
        },
        {
          number: "70",
          value: "Heat tint to discover the apperance of coating",
        },

        {
          number: "71",
          value: "Dress to remove remnant aluminised coating",
        },

        {
          number: "80",
          value: "Remove blasting by air clean",
        },

        {
          number: "90",
          value: "Strip inspection using heat tint",
        },
      ],
    },
    {
      title: "FPI",
      data: [
        {
          number: "RC 001496",
          value: "FPI - Crack test",
        },

        {
          number: "120",
          value: "Inspection check-00",
        },
        {
          number: "120",
          value: "UMR number",
        },
        {
          number: "120",
          value: "FPI Log number",
        },
        {
          number: "130",
          value: "Ultrasonic wall thickness check",
        },
        {
          number: "140",
          value: "Hot water wash and dry",
        },
      ],
    },
    {
      title: "Sentence Inspection",
      data: [
        {
          number: "RC 001496",
          value: "Sentence Inspection",
        },
        {
          number: "150",
          value: "Apply measurements in parts areas",
        },
        {
          number: "150",
          value: "Pass inspection test",
        },
      ],
    },
  ];

  const { id } = useParams();

  const jcSearchChange = (val) => {
    let jc_id = val.target.innerText;
    navigate("/report/job-card/" + jc_id);
  };

  const handleBCClick = (event) => {
    event.preventDefault();
    window.location = event.target.href;
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="#"
      onClick={handleBCClick}
    >
      Report
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href={
        "/report/part-level-summary/" +
        (jobCardDetails ? jobCardDetails.part_number : "")
      }
      onClick={handleBCClick}
    >
      Part No: {jobCardDetails ? jobCardDetails.part_number : ""}
    </Link>,
    <Link
      underline="hover"
      key="3"
      color="inherit"
      href="#"
      onClick={handleBCClick}
    >
      GRN No: {jobCardDetails ? jobCardDetails.grn_id : ""}
    </Link>,
    <Typography key="4" color="text.primary">
      Batch No: {jobCardDetails ? jobCardDetails.jc_id : ""}
    </Typography>,
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        getJobCardsData();
        getJobCardDetails(id);
      } catch (error) {
        navigate("/login");
      }
    }
    fetchData();
  }, [navigate, id]);

  const getJobCardsData = async () => {
    setShowBackDrop(true);
    try {
      const jc_data = await reportApi.getJobCards();
      setAllJobCards(jc_data.job_cards);
      //setAllSerialItems(jc_data.serialized_items);
    } catch (e) {
      console.log(e);
    }
    setShowBackDrop(false);
  };

  const getJobCardDetails = async (id) => {
    setShowBackDrop(true);
    //try {
    const jc_data = await reportApi.getJobCardDetails(id);
    if (!jc_data.success) {
      setJobCardDetails(jc_data.details);
      setRData([
        {
          id: "Parts In",
          value: 100,
          label: "Parts In",
          color: "#202b59",
        },
        {
          id: "Cleaning",
          value: 100,
          label: "Cleaning",
          color: "#485283",
        },
        {
          id: "Heat Tint",
          value: 100,
          label: "Heat Tint",
          color: "#6d76b2",
        },
        {
          id: "FPI",
          value: 100,
          label: "FPI",
          color: "#8b8fc2",
        },
        {
          id: "Sentence Inspection",
          value: (
            100 -
            (100 * jc_data.details.beyond_repair) / jc_data.details.total_parts
          ).toFixed(2),
          label: "Sentence Inspection",
          color: "#c2d1e2",
        },
      ]);
      setOverallYield(
        (
          100 -
          (100 * jc_data.details.beyond_repair) / jc_data.details.total_parts
        ).toFixed(2)
      );
    }
    setSerialisedItems(jc_data.serialised_items);

    setShowBackDrop(false);
  };

  return (
    <MasterLayout>
      <Box className={classes.home_main_container}>
        <Container maxWidth={false}>
          <Grid item className="margin-overview-row">
            <Card>
              <CardContent>
                <Autocomplete
                  sx={{
                    backgroundColor: "rgb(245, 254, 254);",
                    "& .MuiOutlinedInput-root": {
                      paddingRight: "10px!important",
                    },
                  }}
                  id="checkboxes-tags-demo"
                  options={allJobCards}
                  onChange={jcSearchChange}
                  getOptionLabel={(option) => option.jc_id}
                  isOptionEqualToValue={(option, value) =>
                    option.jc_id === value.jc_id
                  }
                  style={{ minWidth: 350, display: "inline-flex" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search by Job Card Number"
                      placeholder="Type a job card number"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon
                              fontSize="large"
                              style={{ color: "#000" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid container>
            <Grid item xs={6}>
              <Breadcrumbs
                className="bc-left-margin"
                separator="›"
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Grid>
            {/* <Grid item xs={6} className="text-end custom-padding-10">
              <span>
                <strong>Delivery Date:</strong> 5th October 2023
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span>
                <DownloadIcon
                  className="download-icon-custom"
                  fontSize="small"
                />
                <strong>Download Report</strong>
              </span>
            </Grid> */}
          </Grid>

          <Grid container className="margin-overview-row">
            <OverviewDetails1
              jobCardDetails={jobCardDetails}
              fill="#000"
              color="#fff"
              setShowRightBar={setShowRightBarJC}
            />
            <OverviewDetails2
              jc={jobCardDetails}
              setShowRightBar={setShowRightBar}
            />
          </Grid>

          <Grid container className="report-overview-bar-row">
            <Grid item xs={10}>
              <InfoIcon sx={{ verticalAlign: "bottom" }} /> &nbsp;
              <strong>
                Report Summary:{" "}
                {jobCardDetails &&
                  (
                    (100 * jobCardDetails.beyond_repair) /
                    jobCardDetails.total_parts
                  ).toFixed(2)}
                %
              </strong>
              &nbsp; of parts were scrapped at{" "}
              <strong>Sentence Inspection</strong>
            </Grid>
          </Grid>

          <Grid container className="margin-overview-row">
            <Grid item xs={9}>
              <Card className="report-overview-chart-inner">
                <CardContent>
                  <h4>
                    <strong>Yield through stages</strong>
                  </h4>
                  <br />
                  <div className="yield">
                    <div className="legend">
                      {rdata &&
                        rdata.map((x) => (
                          <div
                            key={x.label}
                            className="legend-section"
                            style={{ marginRight: "35px" }}
                          >
                            <span style={{ backgroundColor: x.color }}></span>
                            <strong>{x.label}</strong> - {x.value}%
                          </div>
                        ))}
                      <br />
                      <br />
                      <button
                        className="view-all-btn"
                        onClick={() => setLegendRBOpen(true)}
                      >
                        <VisibilityIcon style={{ verticalAlign: "middle" }} />{" "}
                        <span>View Legend</span>
                      </button>
                    </div>
                    <div className="yieldGraph custom-funnel">
                      {
                        <ResponsiveFunnel
                          data={rdata}
                          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                          direction="horizontal"
                          interpolation="linear"
                          shapeBlending={0}
                          valueFormat=">-.4s"
                          colors={{ scheme: "purple_blue" }}
                          borderWidth={0}
                          borderOpacity={0}
                          labelColor={{
                            from: "color",
                            modifiers: [["darker", 3]],
                          }}
                          beforeSeparatorLength={20}
                          afterSeparatorLength={20}
                          currentPartSizeExtension={10}
                          currentBorderWidth={40}
                          motionConfig="wobbly"
                        />
                      }
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
                <CardContent>
                  <ReportCardTile
                    tile_bg_color={"#E6E6EB"}
                    color="#0D1444"
                    icon={<LayersIcon fontSize="large" color="#000" />}
                    main_heading={5}
                    sub_heading="Total Top Level Stages"
                    info="Total Top Level Stages"
                  />
                  <ReportCardTile
                    tile_bg_color={"#E6E6EB"}
                    color="#0D1444"
                    icon={<ReplayIcon fontSize="large" color="#000" />}
                    main_heading={0}
                    sub_heading="Times Reworked"
                  />
                  <ReportCardTile
                    tile_bg_color={"#E6E6EB"}
                    color="#0D1444"
                    icon={
                      <FormatListBulletedIcon fontSize="large" color="#000" />
                    }
                    main_heading={jobCardDetails && jobCardDetails.most_scrap}
                    sub_heading="Most scrap operation(s)"
                  />
                  <div className="overall-yield">
                    <div>{overallYield}%</div>
                    <div>
                      <span>Overall Yield</span>
                      {/* <span style={{ color: "green" }}>
                        <TrendingUpIcon
                          style={{ verticalAlign: "sub" }}
                          color="success"
                        />{" "}
                        12%
                      </span> */}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <RightSideBar
        showRightBar={showRightBar}
        setShowRightBar={setShowRightBar}
        items={serialisedItems}
        name={jobCardDetails ? jobCardDetails.jc_id : ""}
      />
      <RightSideBarJC
        showRightBar={showRightBarJC}
        setShowRightBar={setShowRightBarJC}
        items={allJobCards}
        name={jobCardDetails && jobCardDetails.part_number}
      />
      <RightSideBarLegend
        title="Stages Legend"
        showRightBar={legendRBOpen}
        setShowRightBar={setLegendRBOpen}
        legends={stagesLegend}
      />

      <BackDrop showBackDrop={showBackDrop} />
    </MasterLayout>
  );
};

export default JobCardPage;
