import React from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";

const ComparisonBatchSerial = (props) => {
  const compareFeature = props.compareFeature;

  const handleFeatureChange = props.handleFeatureChange;

  return (
    <Grid item xl={8} lg={8} sm={8} xs={12}>
      <div className="comparison-batch-tile">
        <div className="comparison-inner">
          <FormControl style={{ width: "250px" }}>
            <InputLabel id="demo-simple-select-label">
              Comparison for
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={compareFeature}
              label="Comparison for"
              onChange={handleFeatureChange}
            >
              <MenuItem value={1}>Front Outer Flange</MenuItem>
              <MenuItem value={2}>Front Inner Flange</MenuItem>
              <MenuItem value={3}>Rear Inner Flange</MenuItem>
              <MenuItem value={4}>Slot</MenuItem>
              <MenuItem value={5}>LUG</MenuItem>
            </Select>
          </FormControl>
          <FormControl style={{ width: "150px" }}>
            <InputLabel id="demo-simple-select-label">Measurements</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.compareUnit}
              label="Measurements in"
              onChange={(event, newValue) => {
                props.setCompareUnit(newValue.props.value);
              }}
            >
              <MenuItem value={1}>Inches</MenuItem>
              <MenuItem value={2}>mm</MenuItem>
            </Select>
          </FormControl>

          <div>
            <div className="compare-header left">
              <div>
                <span>Nominal </span>
                <span>
                  {props.fullPartObject &&
                    props.to_unit(
                      props.fullPartObject.batch_serials_details.nominal
                    )}
                </span>
              </div>
              <div>
                <span>Tol+ </span>
                <span>
                  {props.fullPartObject &&
                    props.to_unit(
                      props.fullPartObject.batch_serials_details.tol_p
                    )}
                </span>
              </div>
              <div>
                <span>Tol- </span>
                <span>
                  {props.fullPartObject &&
                    props.to_unit(
                      props.fullPartObject.batch_serials_details.tol_n
                    )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};
export default ComparisonBatchSerial;
