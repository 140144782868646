import { Chip } from "@mui/material";
import React, { useState } from "react";

const RightSideBarCleaningDetail = (props) => {
  const show = props.showRightBar;
  const setShow = props.setShowRightBar;
  const items = props.items;
  const name = props.name;
  const [filter, setFilter] = useState("");

  const onChange = (e) => {
    setFilter(e.target.value);
  };

  const test_filter = (r) => {
    if (filter.length == 0) return true;
    if (r.jc_id.includes(filter)) return true;
    return false;
  };

  const handleDialogOpen = (item) => {
    window.location = "/report/job-card/" + item.jc_id;
  };

  return show ? (
    <div
      className="report-right-side-serialised-items-bar"
      onClick={(e) => {
        if (
          e.target.classList.contains("report-right-side-serialised-items-bar")
        )
          setShow(false);
      }}
    >
      <div className="full">
        <h4 className="head">
          Comparison of OK/NOK 209 Measurements of all 19 Serial Items in Batch
          CNG8292
          <div
            className="close-btn"
            onClick={() => {
              setShow(false);
            }}
          >
            X
          </div>
        </h4>
        <div className="right-sidebar-inner">
          <div className="comparison-table ctv2">
            <table cellSpacing="0" cellPadding="0">
              <tbody>
                <tr>
                  <td className="tborder" colSpan={4}>
                    S03 (Parts Before Cleaning)
                  </td>
                  <td className="compare-col" width={130}>
                    Difference (OK)
                  </td>
                  <td className="tborder" colSpan={4}>
                    S04 (Parts After Cleaning)
                  </td>
                </tr>
                <tr className="dark-bg">
                  <td>SERIAL ITEM</td>
                  <td>OK</td>
                  <td>NOK</td>
                  <td width={200}></td>

                  <td className="compare-col">S03 to S04</td>

                  <td style={{ color: "#40466d" }}>SERIAL ITEM</td>
                  <td>OK</td>
                  <td>NOK</td>
                  <td width={200}></td>
                </tr>
                {props.cleaning_data.map((obj) => (
                  <tr key={obj.serial_item} className="data2">
                    <td>{obj.serial_item}</td>
                    <td>{obj.s03_ok}</td>
                    <td>{obj.s03_nok}</td>
                    <td></td>

                    <td className="compare-col">
                      <Chip
                        label={obj.difference + "%"}
                        color="success"
                        style={{
                          backgroundColor:
                            obj.difference > 0 ? "#6fab60" : "#d94d4d",
                        }}
                      />
                    </td>

                    <td></td>
                    <td>{obj.s04_ok}</td>
                    <td>{obj.s04_nok}</td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
export default RightSideBarCleaningDetail;
