import React from "react";
import NavBar from "../navBar/navBar";
import Footer from "../footer/footer";
import TopBar from "../navBar/topBar";

const MasterLayout = ({ children }) => {
  return (
    <>
      <NavBar />
      <TopBar />
      <div className="second-pane">
        {children}
        <Footer />
      </div>
    </>
  );
};
export default MasterLayout;
