import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Box, Container, MenuItem, Select } from "@mui/material";
import AQ_DigForm0001 from "../../components/acquisitionForm/AQ_DigForm0001";
import MasterLayout from "../../components/common/masterLayout";
import AQ_DigForm0002 from "../../components/acquisitionForm/AQ_DigForm0002";
import AQ_DigForm0003 from "../../components/acquisitionForm/AQ_DigForm0003";
import AQ_DigForm0004 from "../../components/acquisitionForm/AQ_DigForm0004";
import AQ_DigForm0005 from "../../components/acquisitionForm/AQ_DigForm0005";
import AQ_DigForm0006 from "../../components/acquisitionForm/AQ_DigForm0006";
import BackDrop from "../../components/common/backDrop";
import formAPI from "../../api/aquisitionForm/form";

const useStyles = makeStyles(() => ({
  select_form_container: {
    paddingLeft: "30px",
    marginBottom: "20px",
  },
  title_text: {
    height: "50px",
    display: "flex",
    alignItems: "center",
    fontSize: "24px",
    fontWeight: 600,
    ["@media (max-width:425px)"]: {
      fontSize: "16px",
    },
  },
}));

const Form = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [showBackDrop, setShowBackDrop] = useState(false);
  const [userForms, setUserForms] = useState([]);
  const [defaultForm, setDefaultForm] = useState(
    "Please Select Acquisition Form"
  );
  const [formName, setFormName] = useState(null);
  const [showSuccessMsg, setShowSuccessMsg] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getUserForms();
      } catch (error) {
        navigate("/login");
      }
    };
    fetchData();
  }, [navigate]);

  const getUserForms = async () => {
    try {
      setShowBackDrop(true);
      var formData = await formAPI.getForms();
      setUserForms(formData);
      setShowBackDrop(false);
    } catch (error) {
      setShowBackDrop(false);
      alert(error.msg);
    }
  };

  return (
    <MasterLayout>
      <Box>
        <Box className={classes.select_form_container}>
          <Box className={classes.title_text}>Please Choose Form</Box>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={defaultForm}
            onChange={(e) => setDefaultForm(e.target.value)}
          >
            <MenuItem value="Please Select Acquisition Form">
              Please Select Acquisition Form
            </MenuItem>

            {userForms &&
              userForms.map((data) => (
                <MenuItem
                  key={data.form_id}
                  value={data.form_reference}
                  onClick={() => setFormName(data.form_reference)}
                >
                  {data.form_name}
                </MenuItem>
              ))}
          </Select>
        </Box>

        <Container sx={{ textAlign: "center", color: "green" }}>
          {showSuccessMsg && <h2>Save {showSuccessMsg} data successfully </h2>}
        </Container>

        <Container sx={{ width: "100%", minHeight: "65vh" }}>
          {(() => {
            switch (formName) {
              case "AQ_DigForm0001":
                return (
                  <AQ_DigForm0001
                    setFormName={setFormName}
                    setShowSuccessMsg={setShowSuccessMsg}
                  />
                );
              case "AQ_DigForm0002":
                return (
                  <AQ_DigForm0002
                    setFormName={setFormName}
                    setShowSuccessMsg={setShowSuccessMsg}
                  />
                );
              case "AQ_DigForm0003":
                return (
                  <AQ_DigForm0003
                    setFormName={setFormName}
                    setShowSuccessMsg={setShowSuccessMsg}
                  />
                );
              case "AQ_DigForm0004":
                return (
                  <AQ_DigForm0004
                    setFormName={setFormName}
                    setShowSuccessMsg={setShowSuccessMsg}
                  />
                );
              case "AQ_DigForm0005":
                return (
                  <AQ_DigForm0005
                    setFormName={setFormName}
                    setShowSuccessMsg={setShowSuccessMsg}
                  />
                );
              case "AQ_DigForm0006":
                return (
                  <AQ_DigForm0006
                    setFormName={setFormName}
                    setShowSuccessMsg={setShowSuccessMsg}
                  />
                );
              default:
                return null;
            }
          })()}
        </Container>
      </Box>
      <BackDrop showBackDrop={showBackDrop} />
    </MasterLayout>
  );
};

export default Form;

/* <Box className={classes.form_page_header}>Daily Shell Slurry Tests</Box>
      <Stack spacing={4}>
        <Container>
          <Box className={classes.title_text}>1.Date *</Box>
          <DatePicker
            className={classes.datepicker}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </Container>

        <Container>
          <Box className={classes.title_text}>2.Question *</Box>
          <FormControl onChange={(e) => setQuestion1(e.target.value)}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              <FormControlLabel value="AM" control={<Radio />} label="AM" />
              <FormControlLabel
                value="Midday"
                control={<Radio />}
                label="Midday"
              />
              <FormControlLabel value="PM" control={<Radio />} label="PM" />
            </RadioGroup>
          </FormControl>
        </Container>

        <Container>
          <Box className={classes.title_text}>
            3.Identify Slurry to be tested *
          </Box>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={question2}
            onChange={(e) => setQuestion2(e.target.value)}
          >
            <MenuItem value="S1 - Prime Slurry">S1 - Prime Slurry</MenuItem>
            <MenuItem value="S2 - Secondary Slurry">
              S2 - Secondary Slurry
            </MenuItem>
            <MenuItem value="S3 - Back Up Slurry">S3 - Back Up Slurry</MenuItem>
          </Select>
        </Container>

        <Container>
          <Box className={classes.title_text}>4.Operator *</Box>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={question3}
            onChange={(e) => setQuestion3(e.target.value)}
          >
            <MenuItem value="Günay CANKURTARAN">Günay CANKURTARAN</MenuItem>
            <MenuItem value="Bülent BAHADIR">Bülent BAHADIR</MenuItem>
            <MenuItem value="Sertac ALPTEKIN">Sertac ALPTEKIN</MenuItem>
            <MenuItem value="Erdem GUNGOR">Erdem GUNGOR</MenuItem>
          </Select>
        </Container>

        <Container>
          <Box className={classes.title_text}>5.Temperature (Deg C) *</Box>
          <TextField
            type="number"
            placeholder="The value must be a number"
            sx={{ width: "100%" }}
            variant="outlined"
            onChange={(e) => setQuestion4(e.target.value)}
          />
        </Container>

        <Container>
          <Box className={classes.title_text}>6.Viscosity (Secs) *</Box>
          <TextField
            type="number"
            placeholder="The value must be a number"
            sx={{ width: "100%" }}
            variant="outlined"
            onChange={(e) => setQuestion5(e.target.value)}
          />
        </Container>

        <Container>
          <Box className={classes.title_text}>7.PH Level *</Box>
          <TextField
            type="number"
            placeholder="The value must be a number"
            sx={{ width: "100%" }}
            variant="outlined"
            onChange={(e) => setQuestion6(e.target.value)}
          />
        </Container>

        <Container>
          <Box className={classes.title_text}>8.Density (g/ml) *</Box>
          <TextField
            type="number"
            placeholder="The value must be a number"
            sx={{ width: "100%" }}
            variant="outlined"
            onChange={(e) => setQuestion7(e.target.value)}
          />
        </Container>

        <Container>
          <Box className={classes.title_text}>9.SiO2 (% g) *</Box>
          <TextField
            type="number"
            placeholder="The value must be a number"
            sx={{ width: "100%" }}
            variant="outlined"
            onChange={(e) => setQuestion8(e.target.value)}
          />
        </Container>

        <Container>
          <Box className={classes.title_text}>10.Bacteria Test (Level) *</Box>
          <TextField
            type="number"
            placeholder="The value must be a number"
            sx={{ width: "100%" }}
            variant="outlined"
            onChange={(e) => setQuestion9(e.target.value)}
          />
        </Container>

        <Container sx={{ textAlign: "center" }}>
          <Button className={classes.submit_button}>Submit</Button>
        </Container>
      </Stack> */
