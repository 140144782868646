import { React, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Card,
  Container,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MasterLayout from "../../components/common/masterLayout";
import BackDrop from "../../components/common/backDrop";
import visualise from "../../api/visualise/home";
import AQsummaryAPI from "../../api/visualise/AQsummary";

const useStyles = makeStyles(() => ({
  aq_summary_main_container: {
    width: "100%",
    height: "100%",
    background: `white !important`,
  },
  main_container: {
    marginTop: "30px",
    marginBottom: "30px",
    overflowX: "scroll !important",
  },
  table_head_color: {
    backgroundColor: "#E0EFFF",
  },
}));

const Summary = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [showBackDrop, setShowBackDrop] = useState(false);
  const [changeOptions, setChangeOptions] = useState([
    "Show By Part",
    // "Show By GRN",
    // "Show By Batch",
  ]);
  const [showChaneOption, setShowChaneOption] = useState(changeOptions[0]);
  const [menuOptions, setMenuOptions] = useState([]);
  const [showData, setShowData] = useState([]);
  const [showNext, setshowNext] = useState(5);
  const [showPrev, setshowPrev] = useState(0);
  const [filter, setFilter] = useState("PART");

  useEffect(() => {
    async function fetchData() {
      try {
        handleChangeOption("Show By Part");
      } catch (error) {
        navigate("/login");
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  // useEffect(() => {
  //   handleChangeOption("Show By Part");
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [navigate]);

  const handleshowNext = async () => {
    try {
      setShowBackDrop(true);
      await fetchData(menuOptions.slice(showNext, showNext + 5), filter);
      setshowNext(showNext + 5);
      setshowPrev(showPrev + 5);
    } catch (error) {}
  };

  const handleshowPrev = async () => {
    try {
      setShowBackDrop(true);
      await fetchData(menuOptions.slice(showPrev - 5, showPrev), filter);
      setshowNext(showNext - 5);
      setshowPrev(showPrev - 5);
    } catch (error) {}
  };

  const handleChangeOption = async (event) => {
    try {
      var value = event;
      var filter = "PART";
      setShowBackDrop(true);
      setShowChaneOption(value);
      setMenuOptions([]);
      // value == "Show By Part"
      //   ? (filter = "PART")
      //   : value == "Show By GRN"
      //   ? (filter = "GRN")
      //   : value == "Show By Batch"
      //   ? (filter = "BATCH")
      //   : (filter = "PART");
      setFilter(filter);
      const getParts = await visualise.getGoods(filter);
      let result = getParts.map((part) =>
        part.part_number
          ? part.part_number
          : part.grn_id
          ? part.grn_id
          : part.batch_number
      );
      setMenuOptions(result);
      await fetchData(result.slice(0, 5), filter);
      setshowNext(5);
    } catch (error) {}
  };

  const fetchData = async (itemNumbers, filter) => {
    try {
      var AQsummaryData = [];
      setShowData([]);
      for (const item of itemNumbers) {
        const calc = await calculateData(item, filter);
        AQsummaryData.push({
          item: item,
          poplation: calc.populationSize,
          totalOK: calc.totalOK,
          right1: calc.rightValues.right1,
          right2: calc.rightValues.right2,
          right3: calc.rightValues.right3,
          totalNOK: calc.totalNOK,
          totalRuns: calc.totalRuns ? calc.totalRuns : 0,
          additinalCapacity: calc.capacity ? calc.capacity : 0,
          totalFeature: calc.features,
          cp: calc.cp,
          cpk: calc.cpk,
        });
      }
      setShowData(AQsummaryData);
      setShowBackDrop(false);
    } catch (error) {}
  };

  const calculateData = async (itemNum, filter) => {
    try {
      const countLatestSerialItems =
        await visualise.getCountLatestSerialisedItems(filter, itemNum);
      const populationSize =
        (await visualise.getCountSerialisedItems(filter, itemNum)) -
        countLatestSerialItems;
      const totalOK = await visualise.getCountGoodSerialisedItems(
        filter,
        itemNum
      );
      const totalNOK = await visualise.getCountBadSerialisedItems(
        filter,
        itemNum
      );
      const rightValues = await AQsummaryAPI.getRightValues(itemNum, filter);
      const totalRuns = await AQsummaryAPI.getTotalRuns(itemNum, filter);
      const capacity = await AQsummaryAPI.getAdditinalCapacity(itemNum, filter);
      const features = await AQsummaryAPI.getCountFeatures(itemNum, filter);
      const cp = await AQsummaryAPI.getCountCPGTE(itemNum, filter);
      const cpk = await AQsummaryAPI.getCountCPKGTE(itemNum, filter);
      return {
        populationSize,
        totalOK,
        totalNOK,
        rightValues,
        totalRuns,
        capacity,
        features,
        cp,
        cpk,
      };
    } catch (error) {}
  };

  return (
    <MasterLayout>
      <Container maxWidth={false} className={classes.aq_summary_main_container}>
        <Box>
          <Select
            value={showChaneOption}
            onChange={(e) => handleChangeOption(e.target.value)}
          >
            {changeOptions.map((option, index) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          {/* <Select
            value={showSelectedOption}
            onChange={handleMenuOption}
            sx={{ marginLeft: "20px" }}
          >
            {menuOptions.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select> */}
        </Box>

        <Card className={classes.main_container} elevation={3}>
          <Table>
            <TableHead className={classes.table_head_color}>
              <TableRow>
                <TableCell>Item Number</TableCell>
                <TableCell>Population</TableCell>
                <TableCell>Total OK</TableCell>
                <TableCell>Right 1st</TableCell>
                <TableCell>Right 2nd</TableCell>
                <TableCell>Right 3rd</TableCell>
                <TableCell>Total NOK</TableCell>
                <TableCell>Total Runs</TableCell>
                <TableCell>Additional Capacity</TableCell>
                <TableCell>Total Features</TableCell>
                <TableCell>CP {">"} 1.33</TableCell>
                <TableCell>CPK {">"} 1</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {showData.map((key) => (
                <TableRow hover key={Math.random()}>
                  <TableCell>{key.item}</TableCell>
                  <TableCell>{key.poplation}</TableCell>
                  <TableCell>{key.totalOK}</TableCell>
                  <TableCell>{key.right1}</TableCell>
                  <TableCell>{key.right2}</TableCell>
                  <TableCell>{key.right3}</TableCell>
                  <TableCell>{key.totalNOK}</TableCell>
                  <TableCell>{key.totalRuns}</TableCell>
                  <TableCell>{key.additinalCapacity}</TableCell>
                  <TableCell>{key.totalFeature}</TableCell>
                  <TableCell>{key.cp}</TableCell>
                  <TableCell>{key.cpk}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
        {(showPrev > 0) & (showNext > 5) ? (
          <Button size="large" variant="contained" onClick={handleshowPrev}>
            Show Previous
          </Button>
        ) : (
          ""
        )}
        {showNext < menuOptions.length ? (
          <Button
            size="large"
            variant="contained"
            onClick={handleshowNext}
            sx={{ marginLeft: "10px" }}
          >
            Show Next
          </Button>
        ) : (
          "No More Items"
        )}
      </Container>
      <BackDrop showBackDrop={showBackDrop} />
    </MasterLayout>
  );
};

export default Summary;
