import axios from "axios";
import serverUrl from "../serverUrl";
axios.defaults.withCredentials = true;
var url = serverUrl;

const getJobCards = async () => {
  const response = await axios.get(`${url}/report/job_cards`, {});
  return response.data.data;
};

const getParts = async () => {
  const response = await axios.get(`${url}/report/parts`, {});
  return response.data.data;
};

const getJobCardDetails = async (id) => {
  try {
    const response = await axios.get(
      `${url}/report/job_card_details?id=${id}`,
      {}
    );
    return response.data.data;
  } catch (e) {
    return { success: false, code: e.response.status };
  }
};

const getPartDetails = async (id, feature, batchSerialBatch) => {
  try {
    const response = await axios.get(
      `${url}/report/part_details?id=${id}&feature=${feature}&compare_batch=${batchSerialBatch}`,
      {}
    );
    return response.data.data;
  } catch (e) {
    return { success: false, code: e.response.status };
  }
};

const getSerialItemDetails = async (id) => {
  const response = await axios.get(
    `${url}/report/serialized_item_details?id=${id}`,
    {}
  );
  return response.data.data;
};

const submitRequestFeature = async (data) => {
  const response = await axios.post(`${url}/report/feature_request`, data);
  return response.data;
};

export default {
  getJobCards,
  getJobCardDetails,
  getParts,
  getPartDetails,
  getSerialItemDetails,
  submitRequestFeature,
};
