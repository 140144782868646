import { Avatar, Chip, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import VisibilityIcon from "@mui/icons-material/Visibility";

const RightSideBar = (props) => {
  const show = props.showRightBar;
  const setShow = props.setShowRightBar;
  const items = props.items;
  const name = props.name;
  const [filter, setFilter] = useState("");

  const onChange = (e) => {
    setFilter(e.target.value);
  };

  const test_filter = (r) => {
    if (filter.length == 0) return true;
    if (r.serial_no.includes(filter)) return true;
    return false;
  };

  const handleDialogOpen = (item) => {
    window.location = "/report/jc/serialized-item/" + item.serial_no;
  };

  const yield_color = (val) => {
    return val == "GOOD" ? "#d2f4d7" : "#ff8b95";
  };

  const yieldf_color = (val) => {
    return val == "GOOD" ? "#128320" : "#870e0e";
  };

  return show ? (
    <div
      className="report-right-side-serialised-items-bar"
      onClick={(e) => {
        if (
          e.target.classList.contains("report-right-side-serialised-items-bar")
        )
          setShow(false);
      }}
    >
      <div>
        <h4 className="head">
          Serialised Items in Jobcard <strong>{name}</strong>
        </h4>
        <div className="right-sidebar-inner">
          <TextField
            fullWidth
            onChange={onChange}
            label="Filter by Serial number"
            placeholder="Type a Serial number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon fontSize="large" style={{ color: "#000" }} />
                </InputAdornment>
              ),
            }}
          />

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Serial Number</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Status</strong>
                  </TableCell>
                  <TableCell>
                    <strong>View</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((row) =>
                  test_filter(row) ? (
                    <TableRow
                      key={row.serial_no}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell scope="row">{row.serial_no}</TableCell>
                      <TableCell>
                        <Chip
                          label={row.umr_status > 0 ? "UMR" : "GOOD"}
                          color="success"
                          style={{
                            backgroundColor: yield_color(
                              row.umr_status > 0 ? "UMR" : "GOOD"
                            ),
                            color: yieldf_color(
                              row.umr_status > 0 ? "UMR" : "GOOD"
                            ),
                            fontWeight: "bold",
                            width: "90px",
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Avatar>
                          <VisibilityIcon
                            style={{ color: "#000", cursor: "pointer" }}
                            onClick={() => handleDialogOpen(row)}
                          />
                        </Avatar>
                      </TableCell>
                    </TableRow>
                  ) : null
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  ) : null;
};
export default RightSideBar;
