import { React, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Avatar,
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import MasterLayout from "../../components/common/masterLayout";
import BackDrop from "../../components/common/backDrop";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import InfoIcon from "@mui/icons-material/Info";
import reportApi from "../../api/report/reportApi";
import OverviewSerializedDetails1 from "../../components/report/overviewSerializedDetails1";

import VisibilityIcon from "@mui/icons-material/Visibility";
import ImageIcon from "@mui/icons-material/Image";
import MenuIcon from "@mui/icons-material/Menu";

const useStyles = makeStyles(() => ({
  home_main_container: {
    width: "100%",
    overflow: "auto",
  },
}));

const SerializedItemDetailsPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [showBackDrop, setShowBackDrop] = useState(false);

  const [allJobCards, setAllJobCards] = useState([]); // job cards for this client //

  const [allSerialItems, setAllSerialItems] = useState([]); // all serial items for this client //
  const [serialItemDetails, setSerialItemDetails] = useState(null);
  const [topAffectedCharacteristics, setTopAffectedCharacteristics] = useState(
    []
  );

  const [resultOverviewFilter, setResultOverviewFilter] = useState("repair");

  const { id } = useParams();

  const jcSearchChange = (val) => {
    let jc_id = val.target.innerText;
    navigate("/report/job-card/" + jc_id);
  };

  const handleBCClick = (event) => {
    event.preventDefault();
    window.location = event.target.href;
  };

  const handleDialogOpen = (row) => {
    console.log("feature not implemented");
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="#"
      onClick={handleBCClick}
    >
      Report
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="#"
      onClick={handleBCClick}
    >
      Part No: {serialItemDetails ? serialItemDetails.part_number : ""}
    </Link>,
    <Link
      underline="hover"
      key="3"
      color="inherit"
      href="#"
      onClick={handleBCClick}
    >
      GRN No: {serialItemDetails ? serialItemDetails.grn_id : ""}
    </Link>,
    <Link
      underline="hover"
      key="4"
      color="inherit"
      href={
        "/report/job-card/" + (serialItemDetails ? serialItemDetails.jc_id : "")
      }
      onClick={handleBCClick}
    >
      Job Card No: {serialItemDetails ? serialItemDetails.jc_id : ""}
    </Link>,
    <Link
      underline="hover"
      key="5"
      color="inherit"
      href={
        "/report/jc/serialized-item/" +
        (serialItemDetails ? serialItemDetails.serial_no : "")
      }
      onClick={handleBCClick}
    >
      Serial Number: {serialItemDetails ? serialItemDetails.serial_no : ""}
    </Link>,
    <Typography key="6" color="text.primary">
      Characteristics Details
    </Typography>,
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        getJobCardsData();
        getSerialItemDetails(id);
      } catch (error) {
        navigate("/login");
      }
    }
    fetchData();
  }, [navigate, id]);

  const getJobCardsData = async () => {
    setShowBackDrop(true);
    try {
      const jc_data = await reportApi.getJobCards();
      setAllJobCards(jc_data.job_cards);
      setAllSerialItems(jc_data.serialized_items);
    } catch (e) {
      console.log(e);
    }
    setShowBackDrop(false);
  };

  const getSerialItemDetails = async (id) => {
    setShowBackDrop(true);
    //try {
    const si_data = await reportApi.getSerialItemDetails(id);
    if (!si_data.success) {
      setSerialItemDetails(si_data.details);
      setTopAffectedCharacteristics(si_data.top_affected_characteristics);
    }
    //} catch (e) {
    //throw { error: e };
    //}
    setShowBackDrop(false);
  };

  return (
    <MasterLayout>
      <Box className={classes.home_main_container}>
        <Container maxWidth={false}>
          <Grid item className="margin-overview-row">
            <Card>
              <CardContent>
                <Autocomplete
                  sx={{
                    backgroundColor: "rgb(245, 254, 254);",
                    "& .MuiOutlinedInput-root": {
                      paddingRight: "10px!important",
                    },
                  }}
                  id="checkboxes-tags-demo"
                  options={allSerialItems}
                  onChange={jcSearchChange}
                  getOptionLabel={(option) => option.serial_no}
                  isOptionEqualToValue={(option, value) =>
                    option.serial_no === value.serial_no
                  }
                  style={{ minWidth: 350, display: "inline-flex" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search by Serial Number"
                      placeholder="Type a serial number"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon
                              fontSize="large"
                              style={{ color: "#000" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid container>
            <Grid item xs={8}>
              <Breadcrumbs
                className="bc-left-margin"
                separator="›"
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Grid>
            <Grid item xs={4} className="text-end custom-padding-10">
              <span>
                <strong>Delivery Date:</strong> 5th October 2023
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span>
                <DownloadIcon
                  className="download-icon-custom"
                  fontSize="small"
                />
                <strong>Download Report</strong>
              </span>
            </Grid>
          </Grid>

          <Grid container className="margin-overview-row">
            <OverviewSerializedDetails1
              serialItemDetails={serialItemDetails}
              fill="#F6F7F8"
              color="#000"
            />

            <Grid item xl={8} lg={8} sm={8} xs={12}>
              <Card className="overview-details-2 ps-20">
                <CardContent>
                  <h4>
                    <strong>Top affected characteristics</strong>
                  </h4>
                  <br />
                  <TableContainer component={Paper} style={{ height: "400px" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Element</TableCell>
                          <TableCell>Nominal</TableCell>
                          <TableCell>Actual</TableCell>
                          <TableCell>Tol_p</TableCell>
                          <TableCell>Tol_n</TableCell>
                          <TableCell>Dev</TableCell>
                          <TableCell>Details</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {topAffectedCharacteristics.map((row, idx) => (
                          <TableRow
                            key={idx}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell scope="row">{row.element}</TableCell>
                            <TableCell>{row.nominal}</TableCell>
                            <TableCell>{row.actual}</TableCell>
                            <TableCell>{row.tol_p}</TableCell>
                            <TableCell>{row.tol_n}</TableCell>
                            <TableCell>{row.dev}</TableCell>
                            <TableCell>
                              <Avatar>
                                <VisibilityIcon
                                  style={{ color: "#000", cursor: "pointer" }}
                                  onClick={() => handleDialogOpen(row)}
                                />
                              </Avatar>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid container className="report-overview-bar-row">
            <Grid item xs={10}>
              <InfoIcon sx={{ verticalAlign: "bottom" }} /> &nbsp;{" "}
              <strong>Feature Summary</strong>&nbsp; Most affected area is
              &nbsp;
              <strong>Nozzle Guide Vanes Pin Hole</strong>&nbsp; and most
              affected characteristics are &nbsp;
              {topAffectedCharacteristics.length > 1 ? (
                <span>
                  <strong>{topAffectedCharacteristics[0].element}</strong> and{" "}
                  <strong>{topAffectedCharacteristics[1].element}</strong>
                </span>
              ) : (
                ""
              )}
            </Grid>
          </Grid>

          <Grid container className="margin-overview-row">
            <Grid item xs={5}>
              <Card className="report-overview-chart-inner">
                <CardContent>
                  <h4 title="Hover to rotate">
                    <ImageIcon style={{ verticalAlign: "middle" }} />
                    &nbsp;
                    <strong>Part Image</strong>
                  </h4>
                  <br />
                  <div className="part-img">
                    <img className="rotating" src="/part-image.png" />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={7}>
              <Card>
                <CardContent className="job-card-comparison-chart-parent">
                  <Grid container>
                    <Grid item xs={8}>
                      <h4>
                        <MenuIcon style={{ verticalAlign: "middle" }} />
                        &nbsp;
                        <strong>
                          Results overview - Beyond Repair (Not OK)
                        </strong>
                      </h4>
                    </Grid>
                    <Grid item xs={4} className="text-end">
                      <FormControl>
                        <InputLabel id="demo-simple-select-label2">
                          Filter By
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select2"
                          value={resultOverviewFilter}
                          label="Filter By"
                          onChange={(x) => {
                            setResultOverviewFilter(x.target.value);
                          }}
                        >
                          <MenuItem value="repair">Repair</MenuItem>
                          <MenuItem value="out_tolerance">
                            Out Tolerance (Not OK)
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {resultOverviewFilter == "out_tolerance" ? (
                    <div className="circle-graphs">
                      <div className="big">
                        <h4>3_Hole24</h4>
                        <div>Deviated 26 times</div>
                      </div>
                      <div className="mid">
                        <h4>3_Hole19</h4>
                        <div>Deviated 15 times</div>
                      </div>
                      <div className="small">
                        <h4>3_Hole21</h4>
                        <div>Deviated 5 times</div>
                      </div>
                    </div>
                  ) : (
                    <div className="circle-graphs">
                      <div className="big2">
                        <h4>FRS3253 Action</h4>
                        <div>Apply to 57 Features</div>
                      </div>
                      <div className="mid2">
                        <h4>FRSD395 Action</h4>
                        <div>Apply to 30 Features</div>
                      </div>
                      <div className="small2">
                        <h4>FRSD011 Action</h4>
                        <div>Apply to 10 Features</div>
                      </div>
                    </div>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid container className="margin-overview-row">
              <Grid item xs={12}>
                <Card className="report-overview-chart-inner">
                  <CardContent>
                    <h4>
                      <ImageIcon style={{ verticalAlign: "middle" }} />
                      &nbsp;
                      <strong>Part 3D Design</strong>
                    </h4>
                    <br />
                    <div className="part-img" style={{ height: "600px" }}>
                      <model-viewer
                        class="part-img-3d"
                        src="/02268-0000001485-S03-HLD800-02_.glb"
                        ar
                        poster="/part-image.png"
                        shadow-intensity="1"
                        camera-controls
                        touch-action="pan-y"
                      ></model-viewer>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <BackDrop showBackDrop={showBackDrop} />
    </MasterLayout>
  );
};

export default SerializedItemDetailsPage;
