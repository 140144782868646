import { React, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Container,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import MasterLayout from "../../components/common/masterLayout";
import BackDrop from "../../components/common/backDrop";
import SearchIcon from "@mui/icons-material/Search";
// import DownloadIcon from "@mui/icons-material/Download";
import InfoIcon from "@mui/icons-material/Info";
import reportApi from "../../api/report/reportApi";
import OverviewSerializedDetails1 from "../../components/report/overviewSerializedDetails1";

import RightSideBar from "../../components/common/rightSideBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import RightSideBarLocationDetail from "../../components/common/rightSideBarLocationDetail";
import { PieChart } from "@mui/x-charts/PieChart";
import ImageIcon from "@mui/icons-material/Image";

const useStyles = makeStyles(() => ({
  home_main_container: {
    width: "100%",
    overflow: "auto",
  },
}));

const SerializedItemPage = () => {
  page_id = "v1_p7";
  const classes = useStyles();
  const navigate = useNavigate();

  const [showBackDrop, setShowBackDrop] = useState(false);
  const [showRightBar, setShowRightBar] = useState(false);
  const [locationDetailsOpen, setLocationDetailsOpen] = useState(false);
  const [serialisedItems, setSerialisedItems] = useState([]);
  const [jobCardDetails, setJobCardDetails] = useState(null);

  const [allJobCards, setAllJobCards] = useState([]); // job cards for this client //

  const [allSerialItems, setAllSerialItems] = useState([]); // all serial items for this client //
  const [serialItemDetails, setSerialItemDetails] = useState(null);

  const [noOfFeatures, setNoOfFeatures] = useState(5);

  const { id } = useParams();

  const jcSearchChange = (val) => {
    let jc_id = val.target.innerText;
    navigate("/report/jc/serialized-item/" + jc_id);
  };

  const handleBCClick = (event) => {
    event.preventDefault();
    window.location = event.target.href;
  };

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);

  const [s03Defects, setS03Defects] = useState({});
  const [s04Defects, setS04Defects] = useState({});

  const [siDefects, setSiDefects] = useState({});

  const images_sequence = ["A", "B", "C"];
  const trt_image_url = (x, stage) => {
    return `${process.env.REACT_APP_SERVER_URL}/report/serialized_item_details/serial_image?serial_number_stage_number=${id}/${id}-${stage}-${x}.JPG`;
  };

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      onClick: () =>
        setNoOfFeatures(
          data3.length > 0 ? (index == 5 ? 20 : 5) : index == 5 ? 20 : 5
        ),
    };
  };
  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component="span">{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="#"
      onClick={handleBCClick}
    >
      Report
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href={
        "/report/part-level-summary/" +
        (serialItemDetails ? serialItemDetails.part_number : "")
      }
      onClick={handleBCClick}
    >
      Part No: {serialItemDetails ? serialItemDetails.part_number : ""}
    </Link>,
    <Link
      underline="hover"
      key="3"
      color="inherit"
      href="#"
      onClick={handleBCClick}
    >
      GRN No: {serialItemDetails ? serialItemDetails.grn_id : ""}
    </Link>,
    <Link
      underline="hover"
      key="4"
      color="inherit"
      href={
        "/report/job-card/" + (serialItemDetails ? serialItemDetails.jc_id : "")
      }
      onClick={handleBCClick}
    >
      Batch No: {serialItemDetails ? serialItemDetails.jc_id : ""}
    </Link>,
    <Typography key="6" color="text.primary" component="span">
      Serial Number: {serialItemDetails ? serialItemDetails.serial_no : ""}
    </Typography>,
  ];

  useEffect(() => {
    const get_defect_class = (el) => {
      return {
        "FRONT OUTER FLANGE": "fof",
        "FRONT INNER FLANGE": "fif",
        "REAR INNER FLANGE": "rif",
        SLOT: "slot",
        LUG: "lug",
      }[el];
    };

    const getSerialItemDetails = async (id) => {
      setShowBackDrop(true);

      const si_data = await reportApi.getSerialItemDetails(id);
      if (!si_data.success) {
        setSerialItemDetails(si_data.details);

        setAllSerialItems(si_data.serial_items);
        setData2([
          {
            id: 0,
            value: si_data.details.s04.oot,
            label: si_data.details.s04.oot + " Out of Tolerence",
            color: "#d82c2c",
          },
          {
            id: 1,
            value: si_data.details.s04.it,
            label: si_data.details.s04.it + " In Tolerence",
            color: "#66bb71",
          },
        ]);
        setNoOfFeatures(5);
        if (si_data.details.s03) {
          setData3([
            {
              id: 0,
              value: si_data.details.s03.oot,
              label: si_data.details.s03.oot + " Out of Tolerence",
              color: "#d82c2c",
            },
            {
              id: 1,
              value: si_data.details.s03.it,
              label: si_data.details.s03.it + " In Tolerence",
              color: "#66bb71",
            },
          ]);
          setNoOfFeatures(5);
          //setValue(0);
        } else {
          setData3([]);
          //setValue(2);
        }
        if (si_data.details.sentence_inspection) {
          setData([
            {
              id: 0,
              value: si_data.details.sentence_inspection.in_repair,
              label:
                si_data.details.sentence_inspection.in_repair +
                " In Repair Tolerence",
              color: "#fab838",
            },
            {
              id: 1,
              value: si_data.details.sentence_inspection.beyond_repair,
              label:
                si_data.details.sentence_inspection.beyond_repair +
                " Beyond Repair",
              color: "#d82c2c",
            },
            {
              id: 2,
              value: si_data.details.sentence_inspection.in_tolreance,
              label:
                si_data.details.sentence_inspection.in_tolreance +
                " In Tolerence",
              color: "#66bb71",
            },
          ]);
          setSiDefects({
            defect_location:
              si_data.details.sentence_inspection.defect_location,
            defect_information:
              si_data.details.sentence_inspection.defect_information,
          });
        } else {
          setData([]);
          setSiDefects({});
        }
        let s04defect_temp = {};
        for (let i = 0; i < si_data.details.s04_defects.length; i++) {
          let d = si_data.details.s04_defects[i];
          if (!s04defect_temp[d.element_trt])
            s04defect_temp[d.element_trt] = {
              element: d.element_trt,
              class: get_defect_class(d.element_trt),
              data: [d],
            };
          else s04defect_temp[d.element_trt].data.push(d);
        }
        setS04Defects(s04defect_temp);

        let s03defect_temp = {};
        for (let i = 0; i < si_data.details.s03_defects.length; i++) {
          let d = si_data.details.s03_defects[i];
          if (!s03defect_temp[d.element_trt])
            s03defect_temp[d.element_trt] = {
              element: d.element_trt,
              class: get_defect_class(d.element_trt),
              data: [d],
            };
          else s03defect_temp[d.element_trt].data.push(d);
        }
        setS03Defects(s03defect_temp);

        const jc_data = await reportApi.getJobCardDetails(
          si_data.details.jc_id
        );
        if (!jc_data.success) setJobCardDetails(jc_data.details);
        setSerialisedItems(jc_data.serialised_items);
      }

      setShowBackDrop(false);
    };

    async function fetchData() {
      try {
        getJobCardsData();
        getSerialItemDetails(id);
      } catch (error) {
        navigate("/login");
      }
    }
    fetchData();
  }, [navigate, id]);

  const getJobCardsData = async () => {
    setShowBackDrop(true);
    try {
      const jc_data = await reportApi.getJobCards();
      setAllJobCards(jc_data.job_cards);
      //setAllSerialItems(jc_data.serialized_items);
    } catch (e) {
      console.log(e);
    }
    setShowBackDrop(false);
  };

  return (
    <MasterLayout>
      <Box className={classes.home_main_container}>
        <Container maxWidth={false}>
          <Grid item className="margin-overview-row">
            <Card>
              <CardContent>
                <Autocomplete
                  sx={{
                    backgroundColor: "rgb(245, 254, 254);",
                    "& .MuiOutlinedInput-root": {
                      paddingRight: "10px!important",
                    },
                  }}
                  id="checkboxes-tags-demo"
                  options={allSerialItems}
                  onChange={jcSearchChange}
                  getOptionLabel={(option) => option.serial_no}
                  isOptionEqualToValue={(option, value) =>
                    option.serial_no === value.serial_no
                  }
                  style={{ minWidth: 350, display: "inline-flex" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search by Serial Number"
                      placeholder="Type a serial number"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon
                              fontSize="large"
                              style={{ color: "#000" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid container>
            <Grid item xs={8}>
              <Breadcrumbs
                className="bc-left-margin"
                separator="›"
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Grid>
            {/* <Grid item xs={4} className="text-end custom-padding-10">
              <span>
                <strong>Delivery Date:</strong> 5th October 2023
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span>
                <DownloadIcon
                  className="download-icon-custom"
                  fontSize="small"
                />
                <strong>Download Report</strong>
              </span>
            </Grid> */}
          </Grid>

          <Grid container className="margin-overview-row">
            <OverviewSerializedDetails1
              serialItemDetails={serialItemDetails}
              fill="#F6F7F8"
              color="#000"
              setShowRightBar={setShowRightBar}
              setLocationDetailsOpen={setLocationDetailsOpen}
              noOfFeatures={noOfFeatures}
              measurement_stages={data3 && data3.length > 0 ? 3 : 2}
            />

            <Grid item xl={8} lg={8} sm={8} xs={12}>
              <Card className="overview-details-2">
                <h4 className="serial-key-summary-heading">
                  {/* Serial {serialItemDetails ? serialItemDetails.serial_no : ""}{" "}
                  key summary .... See details across stage below */}
                </h4>
                <CardContent>
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        {data3 && data3.length > 0 ? (
                          <Tab
                            icon={<InfoIcon />}
                            iconPosition="end"
                            label="S02"
                            title="S02 - Parts evaluated with vision before cleaning"
                            {...a11yProps(1)}
                          />
                        ) : null}
                        {data3 && data3.length > 0 ? (
                          <Tab
                            icon={<InfoIcon />}
                            iconPosition="end"
                            label="S03"
                            title="S03 - Parts evaluated dimensionally before cleaning"
                            {...a11yProps(2)}
                          />
                        ) : null}
                        <Tab
                          icon={<InfoIcon />}
                          iconPosition="end"
                          label="S04"
                          title="S04 - Parts evaluated dimensionally post cleaning"
                          {...a11yProps(3)}
                        />
                        {data3 && data3.length > 0 ? (
                          <Tab
                            icon={<InfoIcon />}
                            iconPosition="end"
                            label="S05"
                            title="S05 - Parts evaluated with vision post cleaning"
                            {...a11yProps(4)}
                          />
                        ) : null}
                        <Tab
                          icon={<InfoIcon />}
                          iconPosition="end"
                          label="Sentence Inspection"
                          title="Sentence Inspection at TRT"
                          {...a11yProps(5)}
                        />
                        {data3 && data3.length > 0 ? (
                          <Tab
                            icon={<InfoIcon />}
                            iconPosition="end"
                            label="S06"
                            title="S06 - Parts evaluated with vision post sentencing"
                            {...a11yProps(6)}
                          />
                        ) : null}
                      </Tabs>
                    </Box>
                    {data3.length > 0 ? (
                      <CustomTabPanel value={value} index={0}>
                        <ImageIcon style={{ verticalAlign: "middle" }} /> Parts
                        evaluated with vision before cleaning
                        <Grid container className="">
                          {images_sequence.map((x) => (
                            <Grid key={x} item xl={6} lg={6} sm={12} xs={12}>
                              <div className="hover-img margin-n relative">
                                <img
                                  className="img-responsive bordered"
                                  src={trt_image_url(x, "S02")}
                                />
                                <div
                                  className="img-hover-cover"
                                  style={{
                                    backgroundImage:
                                      "url('" + trt_image_url(x, "S02") + "')",
                                    backgroundPosition: "-190px -150px",
                                  }}
                                ></div>
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                      </CustomTabPanel>
                    ) : null}
                    {data3.length > 0 ? (
                      <CustomTabPanel value={value} index={1}>
                        <strong>GOM scan Before cleaning</strong>
                        <div className="pie-chart">
                          <div className="center-label cl-adjustment">
                            <strong>5</strong>
                            <br />
                            <span>Total Features</span>
                          </div>
                          <PieChart
                            series={[
                              {
                                data: data3,
                                innerRadius: 80,
                                outerRadius: 120,
                              },
                            ]}
                            height={250}
                          />
                        </div>
                        <strong>Defect Location(s) in (mm)</strong>
                        <div className="center">
                          <div className="defect-img-container">
                            <img
                              className="color-map-main-img upsidedown"
                              src="/part-green-back.png"
                            />
                            {s03Defects &&
                              Object.keys(s03Defects).map((key) => (
                                <div
                                  key={key}
                                  className={
                                    "defect-location-container " +
                                    s03Defects[key].class
                                  }
                                >
                                  <div className="defect-location">
                                    <div className="defect-location-line"></div>
                                    <strong>{key}</strong>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Nominal</th>
                                          <th>Actual</th>
                                          <th>Tol-</th>
                                          <th>Tol+</th>
                                          <th>Out</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {s03Defects[key].data.map((obj) => (
                                          <tr key={obj.id}>
                                            <td>{obj.nominal}</td>
                                            <td>{obj.s04_actual}</td>
                                            <td>{obj.tol_p}</td>
                                            <td>{obj.tol_n}</td>
                                            <td className="red-text">
                                              {obj.s03_out}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </CustomTabPanel>
                    ) : null}
                    <CustomTabPanel
                      value={value}
                      index={data3.length > 0 ? 2 : 0}
                    >
                      <strong>GOM scan Post cleaning</strong>
                      <div className="pie-chart">
                        <div className="center-label cl-adjustment">
                          <strong>5</strong>
                          <br />
                          <span>Total Features</span>
                        </div>
                        <PieChart
                          series={[
                            {
                              data: data2,
                              innerRadius: 80,
                              outerRadius: 120,
                            },
                          ]}
                          height={250}
                        />
                      </div>
                      <strong>Defect Location(s) in (mm)</strong>
                      <div className="center">
                        <div className="defect-img-container">
                          <img
                            className="color-map-main-img upsidedown"
                            src="/part-green-back.png"
                          />
                          {s04Defects &&
                            Object.keys(s04Defects).map((key) => (
                              <div
                                key={key}
                                className={
                                  "defect-location-container " +
                                  s04Defects[key].class
                                }
                              >
                                <div className="defect-location">
                                  <div className="defect-location-line"></div>
                                  <strong>{key}</strong>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Nominal</th>
                                        <th>Actual</th>
                                        <th>Tol-</th>
                                        <th>Tol+</th>
                                        <th>Out</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {s04Defects[key].data.map((obj) => (
                                        <tr key={obj.id}>
                                          <td>{obj.nominal}</td>
                                          <td>{obj.s04_actual}</td>
                                          <td>{obj.tol_p}</td>
                                          <td>{obj.tol_n}</td>
                                          <td className="red-text">
                                            {obj.s04_out}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </CustomTabPanel>
                    {data3.length > 0 ? (
                      <CustomTabPanel value={value} index={3}>
                        <ImageIcon style={{ verticalAlign: "middle" }} /> Parts
                        evaluated with vision post cleaning
                        <Grid container className="">
                          {images_sequence.map((x) => (
                            <Grid key={x} item xl={6} lg={6} sm={12} xs={12}>
                              <div className="hover-img margin-n relative">
                                <img
                                  className="img-responsive bordered"
                                  src={trt_image_url(x, "S05")}
                                />
                                <div
                                  className="img-hover-cover"
                                  style={{
                                    backgroundImage:
                                      "url('" + trt_image_url(x, "S05") + "')",
                                    backgroundPosition: "-190px -150px",
                                  }}
                                ></div>
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                      </CustomTabPanel>
                    ) : null}
                    <CustomTabPanel
                      value={value}
                      index={data3.length > 0 ? 4 : 1}
                    >
                      <strong>TRT Sentence Inspection</strong>
                      {data.length > 0 ? (
                        <div className="pie-chart">
                          <div className="center-label">
                            <strong>20</strong>
                            <br />
                            <span>Features Measured</span>
                          </div>
                          <PieChart
                            series={[
                              {
                                data,
                                innerRadius: 80,
                                outerRadius: 120,
                              },
                            ]}
                            height={250}
                          />
                        </div>
                      ) : (
                        <div className="data-na">Data Not Available</div>
                      )}
                      <strong>Defect Location(s) in (inches)</strong>
                      <div className="center">
                        <div className="defect-img-container">
                          <img
                            className={
                              "color-map-main-img " +
                              (siDefects.defect_location == "Leading Edge Area"
                                ? "upsidedown"
                                : "")
                            }
                            src={
                              siDefects.defect_location == "Leading Edge Area"
                                ? "/part-green-front.png"
                                : "/part-green-back.png"
                            }
                          />
                          {siDefects.defect_location && (
                            <div
                              className={"defect-location-container " + "lea"}
                            >
                              <div className="defect-location">
                                <div className="defect-location-line"></div>
                                <strong>{siDefects.defect_location}</strong>
                                <div>{siDefects.defect_information}</div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </CustomTabPanel>
                    {data3.length > 0 ? (
                      <CustomTabPanel value={value} index={5}>
                        <ImageIcon style={{ verticalAlign: "middle" }} /> Parts
                        evaluated with vision post sentencing
                        <Grid container className="">
                          {images_sequence.map((x) => (
                            <Grid key={x} item xl={6} lg={6} sm={12} xs={12}>
                              <div className="hover-img margin-n relative">
                                <img
                                  className="img-responsive bordered"
                                  src={trt_image_url(x, "S06")}
                                />
                                <div
                                  className="img-hover-cover"
                                  style={{
                                    backgroundImage:
                                      "url('" + trt_image_url(x, "S06") + "')",
                                    backgroundPosition: "-190px -150px",
                                  }}
                                ></div>
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                      </CustomTabPanel>
                    ) : null}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <RightSideBar
        showRightBar={showRightBar}
        setShowRightBar={setShowRightBar}
        items={serialisedItems}
        name={jobCardDetails ? jobCardDetails.jc_id : ""}
      />
      <RightSideBarLocationDetail
        showRightBar={locationDetailsOpen}
        setShowRightBar={setLocationDetailsOpen}
      />
      <BackDrop showBackDrop={showBackDrop} />
    </MasterLayout>
  );
};

export default SerializedItemPage;
