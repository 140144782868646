import React from "react";

const RightSideBarLegend = (props) => {
  const show = props.showRightBar;
  const setShow = props.setShowRightBar;

  return show ? (
    <div
      className="report-right-side-serialised-items-bar"
      onClick={(e) => {
        if (
          e.target.classList.contains("report-right-side-serialised-items-bar")
        )
          setShow(false);
      }}
    >
      <div>
        <h4 className="head">{props.title}</h4>
        <div className="right-sidebar-inner">
          {props.legends &&
            props.legends.map((obj) => (
              <div key={obj.title} className="legend-details-panel">
                <div>{obj.title}</div>
                <div>
                  {obj.data ? (
                    <table>
                      {obj.data.map((x) => (
                        <tr key={x.number}>
                          <td className="text-right">{x.number}</td>
                          <td>{x.value}</td>
                        </tr>
                      ))}
                    </table>
                  ) : (
                    obj.description
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  ) : null;
};
export default RightSideBarLegend;
