import React from "react";

const DisclaimerDialog = (props) => {
  const show = props.showRightBar;
  const setShow = props.setShowRightBar;

  return show ? (
    <div>
      <div
        className="request-feature-bar right"
        onClick={(e) => {
          if (e.target.classList.contains("request-feature-bar"))
            setShow(false);
        }}
      >
        <div>
          <h4 className="head">
            Disclaimer
            <span
              className="float-right pointer"
              onClick={(e) => {
                setShow(false);
              }}
            >
              X
            </span>
          </h4>
          {
            <div className="right-sidebar-inner">
              <h4>AddQual Copyright © 2024</h4>
              The designs remain AddQual proprietary design / intellectual
              property and under no circumstances can be copied or shared with
              any third party. <br />
              All data held in the site is in line with non-disclosure
              agreements and commercial agreements in place and remain the
              property of AddQual unless otherwise specified.
              <br />
              The website is currently in development and all data should be
              checked prior to post processing. AddQual take no responsibility
              for downstream actions based on the data.
            </div>
          }
        </div>
      </div>
    </div>
  ) : null;
};
export default DisclaimerDialog;
