import React from "react";
import { Grid } from "@mui/material";

const OverviewDetailsPart3 = (props) => {
  const part_yield = props.partDetails ? props.partDetails.yield : "";

  return (
    <Grid item xl={3} lg={3} sm={3} xs={12}>
      <Grid container className="overall-yield-part-tile">
        <Grid item xs={12}>
          <div
            className="float-right view-all-btn see-details-btn"
            onClick={() => props.setTimelineOpen(!props.timelineOpen)}
          >
            {props.timelineOpen ? "Hide" : "See"} Route
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="center part3-yield-pecent">{part_yield}%</div>
          <div className="overall-yield-subtitle">Overall Yield</div>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default OverviewDetailsPart3;
