import axios from "axios";
import serverUrl from "../serverUrl";
axios.defaults.withCredentials = true;
var url = serverUrl;

const getRightValues = async (item, filter) => {
  const response = await axios.get(`${url}/visualise/right_values`, {
    params: {
      part: filter == "PART" ? item : null,
      grn_id: filter == "GRN" ? item : null,
    },
  });
  return response.data.data;
};

const getTotalRuns = async (item, filter) => {
  const response = await axios.get(`${url}/visualise/total_runs`, {
    params: {
      part: filter == "PART" ? item : null,
      grn_id: filter == "GRN" ? item : null,
    },
  });
  return response.data.data[0].sum;
};

const getAdditinalCapacity = async (item, filter) => {
  const response = await axios.get(`${url}/visualise/addition_capacity`, {
    params: {
      part: filter == "PART" ? item : null,
      grn_id: filter == "GRN" ? item : null,
    },
  });
  return response.data.data[0].sum;
};

const getCountFeatures = async (item, filter) => {
  const response = await axios.get(`${url}/visualise/features/count`, {
    params: {
      part: filter == "PART" ? item : null,
      grn_id: filter == "GRN" ? item : null,
    },
  });
  return response.data.data[0].count;
};

const getCountCPKGTE = async (item, filter) => {
  const response = await axios.get(`${url}/visualise/cpk/count/GTE`, {
    params: {
      part: filter == "PART" ? item : null,
      grn_id: filter == "GRN" ? item : null,
      value: 1,
    },
  });
  return response.data.data[0].count;
};

const getCountCPGTE = async (item, filter) => {
  const response = await axios.get(`${url}/visualise/cp/count/GTE`, {
    params: {
      part: filter == "PART" ? item : null,
      grn_id: filter == "GRN" ? item : null,
      value: 1.33,
    },
  });
  return response.data.data[0].count;
};

export default {
  getRightValues,
  getTotalRuns,
  getAdditinalCapacity,
  getCountFeatures,
  getCountCPKGTE,
  getCountCPGTE,
};
