import React from "react";

const Logo = (props) => (
  <svg
    width="200"
    height="100%"
    viewBox="0 0 312 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_2651)">
      <path
        d="M42.5451 35.2244L45.0104 41.6672H50.9353C47.5792 33.0699 45.2175 27 45.2175 27H37.1174L38.4847 30.5425L21 75H27.1735L32.1869 62.0315C32.1869 62.0315 33.3677 62.0315 36.4338 62.0315L34.3207 56.5417L42.5451 35.2244Z"
        fill={props.className}
      />
      <path
        d="M72.8739 62.0522L70.6779 56.5624H56.7358L51.681 43.5732H45.7353L50.7073 56.5624H36.6201L38.7125 62.0522H52.8204L57.7716 74.9793H63.9451L58.8903 62.0522H72.8739Z"
        fill={props.className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.533 48.4688C176.262 48.0965 173.835 44.9649 168.889 44.9649C162.45 44.9649 157.371 50.4801 157.371 57.4831C157.371 64.4861 162.476 70.0013 168.889 70.0013C174.609 70.0013 176.918 65.9226 176.918 65.9226V69.36H180.868V33.3189H176.533V48.4688ZM169.556 65.9482C174.301 65.9482 176.559 61.8183 176.559 61.8183V53.0966C176.559 53.0966 174.301 48.9666 169.556 48.9666C164.81 48.9666 161.681 52.7631 161.681 57.4574C161.681 62.1518 164.707 65.9482 169.556 65.9482Z"
        fill={props.className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M195.517 44.9649C200.775 44.9649 203.187 48.5049 203.187 48.5049V33.3189H207.496V69.36H203.546V65.9226C203.546 65.9226 201.237 70.0013 195.517 70.0013C189.104 70.0013 183.999 64.4861 183.999 57.4831C183.999 50.4801 189.078 44.9649 195.517 44.9649ZM196.184 65.9482C200.929 65.9482 203.187 61.8183 203.187 61.8183V53.0966C203.187 53.0966 200.929 48.9666 196.184 48.9666C191.438 48.9666 188.308 52.7631 188.308 57.4574C188.308 62.1518 191.335 65.9482 196.184 65.9482Z"
        fill={props.className}
      />
      <path
        d="M266.059 62.0237C266.059 62.0237 264.161 65.9228 260.134 65.9228C256.953 65.9228 254.413 64.1785 254.413 60.0998V45.5551H250.078V60.8437C250.078 66.718 254.311 69.9502 259.031 69.9502C264.187 69.9502 266.341 65.6663 266.341 65.6663V69.3089H270.343V45.5551H266.008V62.0237H266.059Z"
        fill={props.className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M275.011 47.3508C275.011 47.3508 278.705 44.9395 283.912 44.9395C290.915 44.9395 294.276 49.2747 294.276 53.6612V69.3089H290.299V65.8715C290.299 65.8715 287.632 69.9502 282.219 69.9502C277.961 69.9502 273.369 66.9746 273.369 61.8185C273.369 57.1755 277.422 53.8921 282.783 53.8921C287.17 53.8921 289.94 55.8416 289.94 55.8416V54.559C289.94 49.6082 285.81 48.9412 283.912 48.9412C280.295 48.9412 276.807 50.9421 276.807 50.9421L275.011 47.3508ZM283.142 66.128C287.375 66.128 289.94 62.5368 289.94 62.5368V59.1507C289.94 59.1507 287.555 57.4577 283.707 57.4577C279.987 57.4577 277.679 59.3303 277.679 61.7929C277.679 64.8968 280.475 66.128 283.142 66.128Z"
        fill={props.className}
      />
      <path
        d="M297.741 33.3189H302.076V69.3343H297.741V33.3189Z"
        fill={props.className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M137.007 33.3189H143.112L157.169 69.36V69.3856H152.629V69.36H152.526L148.807 59.6379H138.213H136.494H133.313L129.568 69.36H124.925L138.059 35.9098L137.007 33.3189ZM141.112 39.5009L134.93 55.5078H147.191L141.112 39.5009Z"
        fill={props.className}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M246.358 51.3266V51.3523C246.358 56.3544 244.639 60.8435 241.715 64.1526L246.897 69.95L240.586 69.9756L238.149 67.2309C235.456 68.9752 232.172 69.9756 228.555 69.9756C218.166 69.9756 210.753 61.7414 210.753 51.3523V51.3266C210.753 40.9376 218.166 32.7033 228.555 32.7033C238.944 32.7033 246.358 40.9376 246.358 51.3266ZM215.37 51.3523C215.37 59.0222 220.732 65.8713 228.555 65.8713C231.069 65.8713 233.327 65.1531 235.276 63.9731L227.76 55.5079H234.019L238.714 60.7666C240.663 58.1757 241.792 54.841 241.792 51.3523V51.3266C241.792 43.6567 236.379 36.8076 228.555 36.8076C220.732 36.8076 215.37 43.6567 215.37 51.3266V51.3523Z"
        fill={props.className}
      />
      <path
        d="M305.18 34.0115H306.154V37.0641H306.847V34.0115H307.847V33.3189H305.18V34.0115Z"
        fill={props.className}
      />
      <path
        d="M311.336 33.3189L310.156 35.2428L309.002 33.3189H308.335V37.0641H309.028V34.7041L310.156 36.5767L311.285 34.7041V37.0641H312.003V33.3189H311.336Z"
        fill={props.className}
      />
      <path
        d="M99.4219 21.0989H101.371V80.4063H99.4219V21.0989Z"
        fill={props.className}
      />
    </g>
    <defs>
      <clipPath id="clip0_1_2651">
        <rect width="312" height="96" fill={props.className} />
      </clipPath>
    </defs>
  </svg>
);

export { Logo };
