import React from "react";
import { makeStyles } from "@mui/styles";
import { Button, Dialog, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  dailog_main_container: {
    "& .MuiBackdrop-root": {
      background: `rgba(255, 255, 255, 0.28) !important`,
    },
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "500px",
        height: "300px",
        maxWidth: "none",
        borderRadius: "20px",
        background: `#191B1F !important`,
        boxShadow: "none",
      },
    },
  },
  dailog_items_container: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dailog_text: {
    margin: "20px !important",
    fontSize: "20px !important",
    lineHeight: "142.1% !important",
    color: "#FB6B25",
    textAlign: "center",
  },
  dailog_button: {
    width: "80px",
    backgroundColor: "#294574 !important",
    color: "white !important",
  },
}));

const DialogBox = (props) => {
  const classes = useStyles();
  const { open, setOpenDailogBox, text } = props;

  const navigate = useNavigate();

  const handleClose = async () => {
    setOpenDailogBox(false);
    navigate("/login");
  };
  return (
    <div>
      <Dialog
        className={classes.dailog_main_container}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Stack className={classes.dailog_items_container} direction="column">
          <Typography className={classes.dailog_text}>{text}</Typography>
          <Button className={classes.dailog_button} onClick={handleClose}>
            Okay
          </Button>
        </Stack>
      </Dialog>
    </div>
  );
};
export default DialogBox;
