import { Grid, Tooltip } from "@mui/material";
import React from "react";
import InfoIcon from "@mui/icons-material/Info";

const ReportCardTile = (props) => {
  const onclick = props.onclick;
  const prop_item = props.item;

  const number_format = (number) => {
    const options = { maximumFractionDigits: 2 };
    const formattedNumber = Intl.NumberFormat("en-US", options).format(number);
    return formattedNumber;
  };
  return (
    <Grid
      item
      xs={12}
      sx={{ cursor: "pointer" }}
      onClick={() => (onclick ? onclick(props.column) : null)}
    >
      <div className="report-card-inner">
        <div
          className="report-card-inner-icon-side"
          style={{ backgroundColor: props.tile_bg_color, color: props.color }}
        >
          {props.icon}
        </div>
        <div className="report-card-inner-text-side">
          {props.tooltip ? (
            <span className="rct-tt" title={props.tooltip}>
              <InfoIcon fontSize="10px" />
            </span>
          ) : null}
          <h1>{props.main_heading}</h1>
          <span>{props.sub_heading}</span>{" "}
          {props.info ? (
            <span title={props.info}>
              <InfoIcon sx={{ verticalAlign: "bottom" }} />
            </span>
          ) : null}
          {props.click ? (
            <Tooltip id="button-report" onClick={props.click}>
              <InfoIcon sx={{ verticalAlign: "bottom" }} />
            </Tooltip>
          ) : null}
          &nbsp;
        </div>
      </div>
    </Grid>
  );
};
export default ReportCardTile;
