import { React, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MasterLayout from "../../components/common/masterLayout";
import BackDrop from "../../components/common/backDrop";
import HomeInsights from "../../components/home/homeInsights";
import HomeTable from "../../components/home/homeTable";
import visualise from "../../api/visualise/home";

const useStyles = makeStyles(() => ({
  home_main_container: {
    width: "100%",
    height: "100%",
    background: `white !important`,
    overflow: "auto",
  },
}));

const Home = () => {
  window.location.href = "/report/part-level-summary/FW35445-6";
  return;
  const classes = useStyles();
  const navigate = useNavigate();

  const [showBackDrop, setShowBackDrop] = useState(false);
  const [partData, setpartData] = useState([]);
  const [grnData, setgrnData] = useState([]);
  const [totalSerialised, setTotalSerialised] = useState();
  const [goodSerialised, setGoodSerialised] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        fetchgetGoodsData("PART");
        fetchgetGoodsData("GRN");
      } catch (error) {
        navigate("/login");
      }
    }
    fetchData();
  }, [navigate]);

  // useEffect(() => {
  //   fetchgetGoodsData("PART");
  //   fetchgetGoodsData("GRN");
  //   setShowBackDrop(false);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [navigate]);

  const fetchgetGoodsData = async (filter) => {
    try {
      setShowBackDrop(true);
      if (filter == "PART") {
        const getParts = await visualise.getGoods("PART");
        setpartData(getParts);
        const totalSerialised = await visualise.getCountSerialisedItems("ALL");
        const goodSerial = await visualise.getCountGoodSerialisedItems("ALL");
        setTotalSerialised(totalSerialised);
        setGoodSerialised(goodSerial);
      } else {
        const getGrns = await visualise.getGoods(filter);
        setgrnData(getGrns);
      }
      setShowBackDrop(false);
    } catch (error) {
      setShowBackDrop(false);
      console.log("error: ", error);
    }
  };

  return (
    <MasterLayout>
      <Box className={classes.home_main_container}>
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <HomeInsights
              key={"total1"}
              title={"Total Part Numbers"}
              value={partData.length}
              icon={"P"}
              color={"#c45850"}
            />
            <HomeInsights
              key={"total2"}
              title={"Total Grns"}
              value={grnData.length}
              icon={"G"}
              color={"#8e5ea2"}
            />
            <HomeInsights
              key={"total3"}
              title={"Total Serialized Items"}
              value={totalSerialised}
              icon={"S"}
              color={"#50AF95"}
            />
            <HomeInsights
              key={"total4"}
              title={"Total Good Serialized"}
              value={goodSerialised}
              icon={"G"}
              color={"#3e95cd"}
            />
          </Grid>
        </Container>
        <Container maxWidth={false} sx={{ maxWidth: "100%" }}>
          {partData.length != 0 ? <HomeTable data={partData}></HomeTable> : ""}
        </Container>
      </Box>
      <BackDrop showBackDrop={showBackDrop} />
    </MasterLayout>
  );
};

export default Home;
