import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Stack } from "@mui/system";
import auth from "../../api/auth/auth";
import DialogBox from "../common/dialogBox";
import BackDrop from "../common/backDrop";

const useStyles = makeStyles((theme) => ({
  input_label: {
    color: `${theme.palette.color.text_color_white} !important`,
    marginBottom: "8px !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "15px !important",
    lineHeight: "16px !important",
  },
  input_field: {
    "& .MuiOutlinedInput-root": {
      "& input": {
        color: `rgba(0, 0, 0, 0.87) !important`,
        backgroundColor: "#FFFFFF !important",
        borderRadius: "8px !important",
        "&::placeholder": {
          color: "rgba(0, 0, 0, 0.6) !important",
          opacity: 1,
        },
      },
      "&.Mui-focused fieldset": {
        borderWidth: "0px !important",
      },
    },
    "& .MuiFormHelperText-root": {
      color: "#FFFFFF",
      marginLeft: "0px !important",
    },
  },
  button_container: {
    "& button": {
      backgroundColor: `#606B8A !important`,
      color: "white !important",
      width: "100%",
      height: "50px",
      "&:hover": {
        backgroundColor: "#171B42 !important",
      },
    },
  },
}));

const VerifyOtp = (props) => {
  const { setSection } = props;
  const classes = useStyles();
  const [OTP, setOTP] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [openDailogBox, setOpenDailogBox] = useState(false);

  const checkFields = async () => {
    OTP === ""
      ? setErrorMsg("Security Code is missing")
      : await otpVerification(OTP);
  };

  const otpVerification = async (OTP) => {
    try {
      setShowBackDrop(true);
      await auth.accountVerify(OTP);
      setSection("updatePassword");
      setShowBackDrop(false);
    } catch (err) {
      setShowBackDrop(false);
      if (err.response.status == 404) {
        setErrorMsg("Incorrect OTP");
      }
      if (err.response.status == 401) {
        setErrorMsg("otp expired");
        setOpenDailogBox(true);
      }
    }
  };

  return (
    <>
      <Stack>
        <Typography className={classes.input_label}>Security code</Typography>
        <TextField
          placeholder="Enter OTP"
          className={classes.input_field}
          value={OTP}
          onChange={(e) => setOTP(e.target.value)}
          helperText={errorMsg ? errorMsg : ""}
          error={errorMsg ? true : false}
        />
      </Stack>

      <Stack className={classes.button_container}>
        <Button onClick={checkFields}>Verify Otp</Button>
      </Stack>

      {openDailogBox ? (
        <DialogBox
          open={openDailogBox}
          setOpenDailogBox={setOpenDailogBox}
          text="the session has been expired please regenerate OTP code"
        />
      ) : (
        ""
      )}
      <BackDrop showBackDrop={showBackDrop} />
    </>
  );
};
export default VerifyOtp;
