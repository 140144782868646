import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import BackDrop from "../../components/common/backDrop";
import {
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
// import { useNavigate } from "react-router-dom";
import MasterLayout from "../../components/common/masterLayout";
import statusApi from "../../api/visualise/status";
import StatusData from "../../components/status/statusData";

const useStyles = makeStyles(() => ({
  main_container: {
    display: "flex !important",
    justifyContent: "space-around",
    maxWidth: "100% !important",
    minHeight: "100vh",
    background: `white !important`,
  },
  parts_main_container: {
    width: "15%",
  },
  table_main_container: {
    width: "80%",
    overflowX: "scroll !important",
  },
  table_head_color: {
    backgroundColor: "#E0EFFF",
  },
  on_selected: {
    "&.Mui-selected": {
      backgroundColor: "#a8e2ff !important",
    },
  },
}));

const Status = () => {
  const navigate = useNavigate();
  const [showBackDrop, setShowBackDrop] = useState(false);
  const classes = useStyles();
  const [selectedPart, setSelectedPart] = useState("");
  const [selectedPartObject, setSelectedPartObject] = useState([]);
  const [partNumbers, setPartNumbers] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        await fetchPartsData();
      } catch (error) {
        navigate("/login");
      }
    }
    fetchData();
  }, [navigate]);

  const fetchPartsData = async () => {
    setShowBackDrop(true);
    try {
      const parts = await statusApi.parts();
      setPartNumbers(parts);
      if (parts) {
        const part = Object.keys(parts)[0];
        const part_details = await statusApi.partDetails(part);
        setSelectedPartObject(part_details);
        setSelectedPart(part);
      }
    } catch (error) {
      throw error;
    }
    setShowBackDrop(false);
  };

  const fetchPartsDetails = async (part) => {
    setShowBackDrop(true);
    try {
      const part_details = await statusApi.partDetails(part);
      setSelectedPartObject(part_details);
      setSelectedPart(part);
    } catch (error) {
      navigate("/login");
    }
    setShowBackDrop(false);
  };

  const hanldeSelected = async (e) => {
    try {
      setSelectedPart(e);
    } catch (error) {}
  };

  return (
    <MasterLayout>
      <Container className={classes.main_container}>
        <Card className={classes.parts_main_container} elevation={3}>
          <Table>
            <TableHead className={classes.table_head_color}>
              <TableRow>
                <TableCell>Part Numbers</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {partNumbers &&
                Object.keys(partNumbers).map((key) => (
                  <TableRow
                    className={classes.on_selected}
                    hover
                    key={key}
                    selected={selectedPart === key}
                    onClick={() => {
                      fetchPartsDetails(key);
                    }}
                  >
                    <TableCell>{key}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Card>

        {/* table data for parts */}

        <Card className={classes.table_main_container} elevation={3}>
          {selectedPartObject && (
            <StatusData selectedPartObject={selectedPartObject} />
          )}
        </Card>
      </Container>
      <BackDrop showBackDrop={showBackDrop} />
    </MasterLayout>
  );
};
export default Status;
