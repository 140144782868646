import React from "react";
import { Card, CardContent, Grid } from "@mui/material";
import ReportCardTile from "./report-card-tile";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ClearIcon from "@mui/icons-material/Clear";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";

const OverviewDetails2 = (props) => {
  const duration = props.jc ? props.jc.duration : "";
  const no_of_features = props.jc ? props.jc.no_of_features : "";
  const out_of_tol_features = props.jc ? props.jc.beyond_repair : "";
  let temp_total_beyond_repair = 0;
  const no_of_serial_items = props.jc ? props.jc.no_of_serial_items : 0;
  if (props.jc) temp_total_beyond_repair = props.jc.beyond_repair;
  return (
    <Grid item xl={8} lg={8} sm={8} xs={12}>
      <Card className="overview-details-2">
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <strong>Statistics</strong>
              <div
                className="float-right view-all-btn"
                onClick={() => props.setShowRightBar(true)}
              >
                View Serials
              </div>
            </Grid>
            <Grid item xs={4}>
              <ReportCardTile
                tile_bg_color="#efefef"
                color="#0D1444"
                icon={<AccessTimeIcon fontSize="large" />}
                main_heading={duration}
                sub_heading="Duration"
                tooltip={props.jc ? props.jc.duration_tooltip : null}
              />
            </Grid>
            <Grid item xs={4}>
              <ReportCardTile
                tile_bg_color={"#efefef"}
                color="#0D1444"
                icon={<DisplaySettingsIcon fontSize="large" />}
                main_heading={no_of_serial_items}
                sub_heading="No. of Parts in Batch"
                onclick={() => props.setShowRightBar(true)}
              />
            </Grid>
            <Grid item xs={4}>
              <ReportCardTile
                tile_bg_color={"#d82c2c"}
                color="#fff"
                icon={<ClearIcon fontSize="large" />}
                main_heading={out_of_tol_features}
                sub_heading="UMR Parts"
                onclick={() => props.setShowRightBar(true)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default OverviewDetails2;
