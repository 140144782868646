import { React, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import visualise from "../../api/visualise/home";
import { CategoryScale } from "chart.js";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";

Chart.register(CategoryScale);

// import PerfectScrollbar from "react-perfect-scrollbar";
const useStyles = makeStyles(() => ({
  main_container: {
    marginTop: "30px",
    marginBottom: "30px",
    overflowX: "scroll !important",
  },
  chart_main_container: {
    width: "100% !important",
    height: "100% !important",
  },
  table_head_color: {
    backgroundColor: "#E0EFFF",
  },
}));

const HomeTable = (props) => {
  const classes = useStyles();
  const partNumbers = props.data;
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [runOnlyOnce, setRunOnlyOnce] = useState(0);
  const [finalPartInfo, setFinalPartInfo] = useState([]);

  useEffect(() => {
    fetchPartDetils();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runOnlyOnce]);

  const fetchPartDetils = async () => {
    try {
      if (partNumbers.length != 0) {
        const partInfos = partNumbers.map((t) =>
          visualise.getPartData("PART", t.part_number)
        );
        const partInfosData = await Promise.all(partInfos);
        fetchPartPerformance(partInfosData);
      }
    } catch (error) {
      console.log("error://////////////", error);
    }
  };

  const fetchPartPerformance = async (partInfosData) => {
    try {
      var arry = [];
      for (const iterator of partInfosData) {
        const performance = await calculateFerformance(iterator[0].part_number);
        arry.push({
          part_number: iterator[0].part_number,
          description: `${iterator[0].engine} ${iterator[0].component_stage} ${iterator[0].component_type} ${iterator[0].state}`,
          name: `${iterator[0].first_name} ${iterator[0].last_name}`,
          uploadedAt: iterator[0].upload_date,
          population_size: Number(performance.populationSize.toFixed(2)),
          performance_actual: Number(performance.performanceActual.toFixed(2)),
          performance_latest: Number(performance.performanceLatest.toFixed(2)),
          performance_normalise: Number(
            performance.performanceNormalise.toFixed(2)
          ),
        });
      }
      setFinalPartInfo(arry);
    } catch (error) {
      console.log("error://////////////////", error);
    }
  };

  const calculateFerformance = async (partNo) => {
    try {
      const countLatestSerialItems =
        await visualise.getCountLatestSerialisedItems("PART", partNo);
      const populationSize =
        (await visualise.getCountSerialisedItems("PART", partNo)) -
        countLatestSerialItems;
      const performanceActual =
        (await visualise.getCountGoodSerialisedItems("PART", partNo)) -
        countLatestSerialItems / populationSize;
      const performanceLatest =
        (await visualise.getCountLatestGoodSerialisedItems("PART", partNo)) /
        countLatestSerialItems;
      const performanceNormalise = countLatestSerialItems / performanceActual;
      return {
        populationSize,
        performanceActual,
        performanceLatest,
        performanceNormalise,
      };
    } catch (error) {
      console.log("error://////////////////", error);
    }
  };

  return (
    <Card className={classes.main_container} elevation={3}>
      {/* <PerfectScrollbar> */}
      <Box>
        <Table>
          <TableHead className={classes.table_head_color}>
            <TableRow>
              <TableCell>Part Number</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Part Owner</TableCell>
              <TableCell>Uploaded At</TableCell>
              <TableCell>Population Size</TableCell>
              <TableCell>Performance Actual</TableCell>
              <TableCell>Performance Latest</TableCell>
              <TableCell>Normalized Performance</TableCell>
              <TableCell align="center">Chart</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {finalPartInfo.map((key) => (
              <TableRow hover key={key.part_number}>
                <TableCell>{key.part_number}</TableCell>
                <TableCell sx={{ width: "10%" }}>{key.description}</TableCell>
                <TableCell>{key.name}</TableCell>
                <TableCell>
                  {format(parseISO(key.uploadedAt), "PPPP")}
                </TableCell>
                <TableCell>{key.population_size}</TableCell>
                <TableCell>{key.performance_actual}%</TableCell>
                <TableCell>{key.performance_latest}%</TableCell>
                <TableCell>{key.performance_normalise}%</TableCell>
                <TableCell>
                  <Container
                    sx={{ width: "440px", height: "200px !important" }}
                  >
                    <Bar
                      className={classes.chart_main_container}
                      data={{
                        labels: ["P A", "P L", "N P"],
                        datasets: [
                          {
                            data: [
                              key.performance_actual,
                              key.performance_latest,
                              key.performance_normalise,
                            ],
                            backgroundColor: ["#ffbb11", "#8e5ea2", "#50AF95"],
                          },
                        ],
                      }}
                      options={{
                        indexAxis: "y",
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                      }}
                    />
                  </Container>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      {/* </PerfectScrollbar> */}
    </Card>
  );
};

export default HomeTable;
