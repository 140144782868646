import React from "react";
import { Grid } from "@mui/material";
import { Tile1Svg } from "../../assets/images/report_page_svg_images/tile1Svg";

const OverviewDetails1 = (props) => {
  const jobCardDetails = props.jobCardDetails;
  const fill = props.fill;
  const color = props.color;
  return (
    <Grid item xl={4} lg={4} sm={4} xs={12}>
      <div className="overview-details-1">
        <Tile1Svg fill={fill} color={color} />
        <div className="tile1-content-container" style={{ color: color }}>
          <h1 className="tile-1-heading">
            {props.batchSerialBatch
              ? props.batchSerialBatch
              : jobCardDetails
              ? jobCardDetails.jc_id
              : ""}
          </h1>
          <div className="tile-1-sub-heading">Batch No.</div>

          <Grid container className="tile1-extra-details">
            <Grid item xs={4}>
              <h3>{jobCardDetails ? jobCardDetails.part_number : ""}</h3>
              <span>Part No</span>
            </Grid>

            <Grid item xs={4}>
              <h3 title={jobCardDetails ? jobCardDetails.description : ""}>
                {jobCardDetails ? jobCardDetails.description : ""}
              </h3>
              <span>Part Description</span>
            </Grid>

            <Grid item xs={2}>
              <h3>{jobCardDetails ? jobCardDetails.grn_id : ""}</h3>
              <span>GRN</span>
            </Grid>

            <Grid item xs={2}>
              <h3>
                {jobCardDetails ? jobCardDetails.project : ""}AQ261
                {props.setShowRightBar ? (
                  <div
                    className="float-right view-all-btn view-all-btn2 view-next-btn"
                    onClick={() => props.setShowRightBar(true)}
                  >
                    View Batches
                  </div>
                ) : null}
              </h3>
              <span>Project</span>
            </Grid>
          </Grid>
        </div>
      </div>
    </Grid>
  );
};
export default OverviewDetails1;
