import { Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import reportApi from "../../api/report/reportApi";
import BackDrop from "./backDrop";

const RequestFeatureDialog = (props) => {
  const show = props.showRightBar;
  const setShow = props.setShowRightBar;

  const [data, setData] = useState({
    feature_name: "",
    feature_description: "",
    department: "Engineering",
    priority: "Low",
    page_id: "v1_p2",
  });
  const [error, setError] = useState({});
  const [showBD, setShowBD] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handle_change = (name, value) => {
    setData({
      ...data,
      [name]: value,
      page_id: page_id,
    });
  };
  const submit_form = async () => {
    data.page_id = page_id;
    setError({});
    let has_error = false;
    for (const attr in data)
      if (!data[attr]) {
        setError({ ...error, [attr]: true });
        has_error = true;
      }

    if (has_error) return;

    try {
      setShowBD(true);
      const response = await reportApi.submitRequestFeature(data);
      setSubmitted(true);
    } catch (ex) {}
    setShowBD(false);
  };

  const [departments] = useState([
    {
      label: "Engineering",
    },
    {
      label: "Commercial",
    },
    {
      label: "Quality",
    },
    {
      label: "Inspection",
    },
    {
      label: "Others",
    },
  ]);
  const [priority] = useState([
    {
      label: "Low",
    },
    {
      label: "Medium",
    },
    {
      label: "High",
    },
  ]);

  return show ? (
    <div>
      <div
        className="request-feature-bar"
        onClick={(e) => {
          if (e.target.classList.contains("request-feature-bar"))
            setShow(false);
        }}
      >
        <div>
          <h4 className="head">
            Request a Feature
            <span
              className="float-right pointer"
              onClick={(e) => {
                setShow(false);
              }}
            >
              X
            </span>
          </h4>
          {submitted ? (
            <div style={{ padding: "20px" }}>
              Thank You. Your Request is Received. We will respond to your
              request very soon.
              <div className="center">
                <button
                  className="request-feature-btn"
                  onClick={() => {
                    setSubmitted(false);
                    setData({
                      feature_name: "",
                      feature_description: "",
                      department: "Engineering",
                      priority: "Low",
                      page_id: "v1_p2",
                    });
                  }}
                >
                  Submit Another
                </button>
              </div>
            </div>
          ) : (
            <div className="right-sidebar-inner">
              <h4>Feature Name</h4>
              <TextField
                required
                label="What is the title of the feature?"
                size="small"
                placeholder="What is the title of the feature?"
                fullWidth
                value={data.feature_name}
                onChange={(e) => handle_change("feature_name", e.target.value)}
                error={error.feature_name}
              />
              <h4>Feature Description</h4>
              <TextField
                required
                label="Describe in detail what this feature should do"
                size="small"
                placeholder="Describe in detail what this feature should do"
                fullWidth
                value={data.feature_description}
                onChange={(e) =>
                  handle_change("feature_description", e.target.value)
                }
                error={error.feature_description}
                multiline
                rows={5}
              />
              <Grid container>
                <Grid item xl={6} lg={6} sm={6} xs={12}>
                  <h4>Department</h4>
                  <TextField
                    select
                    helperText="Please select department"
                    size="small"
                    value={data.department}
                    onChange={(e) =>
                      handle_change("department", e.target.value)
                    }
                    SelectProps={{
                      native: true,
                    }}
                  >
                    {departments.map((option) => (
                      <option key={option.label} value={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xl={6} lg={6} sm={6} xs={12}>
                  <h4>Priority</h4>
                  <TextField
                    select
                    helperText="Please select priority"
                    size="small"
                    value={data.priority}
                    onChange={(e) => handle_change("priority", e.target.value)}
                    SelectProps={{
                      native: true,
                    }}
                  >
                    {priority.map((option) => (
                      <option key={option.label} value={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <div className="center">
                <button className="request-feature-btn" onClick={submit_form}>
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <BackDrop showBackDrop={showBD} />
    </div>
  ) : null;
};
export default RequestFeatureDialog;
