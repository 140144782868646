import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";

import { makeStyles } from "@mui/styles";

import { useNavigate, NavLink } from "react-router-dom";
import { Logo } from "../../assets/images/svgimages";
import auth from "../../api/auth/auth";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";

//import HomeIcon from "@mui/icons-material/Home";
//import SummarizeIcon from "@mui/icons-material/Summarize";
//import SecurityUpdateGoodIcon from "@mui/icons-material/SecurityUpdateGood";
import DescriptionIcon from "@mui/icons-material/Description";
import RequestFeatureDialog from "../common/requestFeatureDialog";
//import AutoGraphIcon from "@mui/icons-material/AutoGraph";
//import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

const useStyles = makeStyles(() => ({
  pages_main_container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  navbar_item: {
    fontSize: "20px !important",
    fontWeight: "700 !important",
    letterSpacing: "1px",
    marginLeft: "5% !important",
    color: "black",
    textDecoration: "none",
    "&:hover": {
      color: "#333D94",
      cursor: "pointer",
    },
    "&.active": {
      color: "#333D94",
    },
    ["@media (max-width:1024px)"]: {
      margin: "5px 0 5px 5%",
    },
  },
  nav_bar_setting_button: {
    "&:hover": {
      color: "#333D94 !important",
      cursor: "pointer",
    },
  },
  navbar_item_company: {
    fontSize: "15px !important",
    fontWeight: "700 !important",
    letterSpacing: "1px",
    marginLeft: "5% !important",
    color: "#fff",
    borderBottom: "2px solid #000",
    textTransform: "uppercase",
    background: "#333",
    padding: "2px 10px",
    borderRadius: "5px",
  },
}));
const settings = ["Logout"];

const NavBar = () => {
  const classes = useStyles();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [user, setUser] = useState({});
  const [showFeature, setShowFeature] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function get_user() {
      try {
        setShowBackDrop(true);

        const user1 = await auth.isUserLoggedIn();
        setUser(user1.data.data.data);

        setShowBackDrop(false);
      } catch (error) {
        setShowBackDrop(false);
        navigate("/login");
      }
    }
    get_user();
  }, [navigate]);

  function first_word_company_name() {
    let first = "";
    if (user.company_name) first = user.company_name.split(" ")[0];
    return first;
  }

  function full_company_name() {
    return user.company_name;
  }

  function user_info() {
    return user.is_remote ? "Remote Access" : user.email;
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    try {
      setShowBackDrop(true);
      await auth.logout();
      setShowBackDrop(false);
      navigate("/login");
    } catch (error) {
      setShowBackDrop(false);
      navigate("/login");
    }
  };

  const openMobileMenu = async () => {
    setMobileMenu(!mobileMenu);
  };
  return (
    <>
      {/* left sidebar */}
      <nav className="leftside">
        <div className="nav-main-section">
          <Box sx={{ height: "80px" }}>
            <Logo className="#fff" />
          </Box>
        </div>

        {/* <a title={full_company_name()}>{first_word_company_name()}</a> */}
        {/* <NavLink to="/">
          <HomeIcon /> <span>Home</span>
        </NavLink>

        <NavLink to="/summary">
          <SummarizeIcon /> <span>Summary</span>
        </NavLink>
        <NavLink to="/status">
          <SecurityUpdateGoodIcon /> <span>Status</span>
        </NavLink>
        <NavLink to="/data">
          <DescriptionIcon /> <span>Data</span>
        </NavLink> */}
        <NavLink to="/report/part-level-summary/FW35445-6">
          <DescriptionIcon /> <span>Reports</span>
        </NavLink>
        <div className="request-feature-div">
          <a
            href="#"
            onClick={() => setShowFeature(true)}
            className="request-feature-btn"
          >
            <DataSaverOnIcon sx={{ verticalAlign: "bottom" }} /> Request a
            Feature
          </a>
        </div>
        {/* <NavLink to="/analysis">
          <AutoGraphIcon /> <span>Analysis</span>
        </NavLink>
        <NavLink to="/acquisition_forms">
          <FormatListBulletedIcon /> <span>Acquisition Form</span>
        </NavLink>
        <NavLink to="/client/register_3c">
          <FormatListBulletedIcon /> <span>3C Register</span>
        </NavLink> */}
      </nav>
      <RequestFeatureDialog
        showRightBar={showFeature}
        setShowRightBar={setShowFeature}
      />

      {/* show menu on web */}
      {/* <AppBar
        position="sticky"
        sx={{
          backgroundColor: "white",
          marginBottom: "50px",
          boxShadow: "0px 1px 4px rgba(100, 116, 139, 0.12)",
          borderBottom: "2px solid #333D94",
          ["@media (max-width:1024px)"]: {
            display: "none",
          },
        }}
      >
        <Container maxWidth="100vw">
          <Toolbar disableGutters>
            <Box sx={{ height: "80px" }}>
              <Logo className="#333D94" />
            </Box>
            <Box
              sx={{ height: "80px" }}
              className={classes.pages_main_container}
            >
              <NavLink to="/" className={classes.navbar_item}>
                Home
              </NavLink>
              <NavLink to="/summary" className={classes.navbar_item}>
                Summary
              </NavLink>
              <NavLink to="/status" className={classes.navbar_item}>
                Status
              </NavLink>
              <NavLink to="/data" className={classes.navbar_item}>
                Data
              </NavLink>
              <NavLink to="/report" className={classes.navbar_item}>
                Report
              </NavLink>
              <NavLink to="/analysis" className={classes.navbar_item}>
                Analysis
              </NavLink>
              <NavLink to="/acquisition_forms" className={classes.navbar_item}>
                Acquisition Forms
              </NavLink>
              <div
                className={classes.navbar_item_company}
                title={full_company_name()}
              >
                {first_word_company_name()}
              </div>
            </Box>

            <Box>
              <SettingsApplicationsRoundedIcon
                onClick={handleOpenUserMenu}
                sx={{ color: "black", width: "45px", height: "45px" }}
                className={classes.nav_bar_setting_button}
              />
              <Menu
                sx={{
                  mt: "45px",
                  "& .MuiPaper-root": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  key="-1"
                  sx={{
                    "&.Mui-focusVisible": {
                      backgroundColor: "transparent",
                    },
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.12)",
                    },
                  }}
                >
                  <Typography textAlign="center">{user_info()}</Typography>
                </MenuItem>
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={handleLogout}
                    sx={{
                      "&.Mui-focusVisible": {
                        backgroundColor: "transparent",
                      },
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.12)",
                      },
                    }}
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
        <BackDrop showBackDrop={showBackDrop} />
      </AppBar> */}

      {/* show menu on mobile */}
      {/* <AppBar
        position="sticky"
        sx={{
          marginBottom: "10px",
          height: "60px",
          display: "none",
          backgroundColor: "white",
          borderBottom: "2px solid #333D94",
          ["@media (max-width:1024px)"]: {
            display: "flex",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Logo className="#333D94" />
          <MenuIcon
            fontSize="large"
            onClick={openMobileMenu}
            sx={{ marginRight: "15px", color: "#333D94" }}
          />
        </Box>
      </AppBar> */}

      {/* <Drawer
        sx={{
          "& .MuiPaper-root": {
            width: "250px",
          },
        }}
        variant="persistent"
        anchor="left"
        open={mobileMenu}
      >
        <Box
          sx={{
            marginLeft: "auto",
            marginTop: "10px",
            marginRight: "10px",
            backgroundColor: "#333D94",
            color: "white",
            borderRadius: "50%",
            width: "50px",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={openMobileMenu}
        >
          X
        </Box>
        <Container
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <NavLink to="/" className={classes.navbar_item}>
            Home
          </NavLink>
          <Typography className={classes.navbar_item_company}>
            {full_company_name()}
          </Typography>
          <NavLink to="/summary" className={classes.navbar_item}>
            Summary
          </NavLink>
          <NavLink to="/status" className={classes.navbar_item}>
            Status
          </NavLink>
          <NavLink to="/data" className={classes.navbar_item}>
            Data
          </NavLink>
          <NavLink to="/report" className={classes.navbar_item}>
            Report
          </NavLink>
          <NavLink to="/analysis" className={classes.navbar_item}>
            Analysis
          </NavLink>
          <NavLink to="/acquisition_forms" className={classes.navbar_item}>
            Acquisition Forms
          </NavLink>
          <Typography className={classes.navbar_item} onClick={handleLogout}>
            Logout
          </Typography>
        </Container>
        <BackDrop showBackDrop={showBackDrop} />
      </Drawer> */}
    </>
  );
};
export default NavBar;
