import { Avatar, Chip, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import VisibilityIcon from "@mui/icons-material/Visibility";

const RightSideBarJC = (props) => {
  const show = props.showRightBar;
  const setShow = props.setShowRightBar;
  const items = props.items;
  const name = props.name;
  const [filter, setFilter] = useState("");

  const onChange = (e) => {
    setFilter(e.target.value);
  };

  const test_filter = (r) => {
    if (filter.length == 0) return true;
    if (r.jc_id.includes(filter)) return true;
    return false;
  };

  const yield_color = (percent) => {
    if (percent > 90) return "#d2f4d7";
    if (percent > 50) return "#fff2c3";
    return "#fbb0ba";
  };

  const yieldf_color = (percent) => {
    if (percent > 90) return "#128320";
    if (percent > 50) return "#a46e04";
    return "#d3223a";
  };

  const handleDialogOpen = (item) => {
    if (props.tab_values && props.tab_values.first == 2)
      props.updateBatchSerialitems(item.jc_id);
    else window.location = "/report/job-card/" + item.jc_id;
  };

  return show ? (
    <div
      className="report-right-side-serialised-items-bar"
      onClick={(e) => {
        if (
          e.target.classList.contains("report-right-side-serialised-items-bar")
        )
          setShow(false);
      }}
    >
      <div>
        <h4 className="head">
          Batches in Part number: <strong>{name}</strong>
        </h4>
        <div className="right-sidebar-inner">
          <TextField
            fullWidth
            onChange={onChange}
            label="Filter by Job Card Number"
            placeholder="Type a Job Card number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon fontSize="large" style={{ color: "#000" }} />
                </InputAdornment>
              ),
            }}
          />

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Batch</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Yield</strong>
                  </TableCell>
                  <TableCell>
                    <strong>View</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((row) =>
                  test_filter(row) ? (
                    <TableRow
                      key={row.jc_id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell scope="row">{row.jc_id}</TableCell>
                      <TableCell>
                        <Chip
                          label={
                            (
                              ((row.total - row.per_scrapped) * 100) /
                              row.total
                            ).toFixed(2) + "%"
                          }
                          color="success"
                          style={{
                            backgroundColor: yield_color(
                              ((row.total - row.per_scrapped) * 100) / row.total
                            ),
                            color: yieldf_color(
                              ((row.total - row.per_scrapped) * 100) / row.total
                            ),
                            fontWeight: "bold",
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Avatar>
                          <VisibilityIcon
                            style={{ color: "#000", cursor: "pointer" }}
                            onClick={() => handleDialogOpen(row)}
                          />
                        </Avatar>
                      </TableCell>
                    </TableRow>
                  ) : null
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  ) : null;
};
export default RightSideBarJC;
