import axios from "axios";
import serverUrl from "../serverUrl";
axios.defaults.withCredentials = true;
var url = serverUrl;

const getGoods = async (filter) => {
  const response = await axios.get(`${url}/visualise`, {
    params: {
      filter: filter,
    },
  });
  return response.data.data;
};

const getCountSerialisedItems = async (filter, id) => {
  const response = await axios.get(`${url}/visualise/serial/count`, {
    params: {
      filter: filter,
      id: id ? id : null,
    },
  });
  return response.data.data[0].count;
};

const getCountGoodSerialisedItems = async (filter, id) => {
  const response = await axios.get(`${url}/visualise/serial/count/good`, {
    params: {
      filter: filter,
      id: id ? id : null,
    },
  });
  return response.data.data[0].count;
};

const getCountBadSerialisedItems = async (filter, id) => {
  const response = await axios.get(`${url}/visualise/serial/count/bad`, {
    params: {
      filter: filter,
      id: id,
    },
  });
  return response.data.data[0].count;
};

const getCountLatestGoodSerialisedItems = async (filter, id) => {
  const response = await axios.get(
    `${url}/visualise/serial/count/latest/good`,
    {
      params: {
        filter: filter,
        id: id,
      },
    }
  );
  return response.data.data[0].count;
};

const getCountLatestSerialisedItems = async (filter, id) => {
  const response = await axios.get(`${url}/visualise/serial/count/latest`, {
    params: {
      filter: filter,
      id: id,
    },
  });
  return response.data.data[0].count;
};

const getPartData = async (filter, id) => {
  const response = await axios.get(`${url}/visualise/part/meta`, {
    params: {
      filter: filter,
      id: id,
    },
  });
  return response.data.data;
};

export default {
  getGoods,
  getCountSerialisedItems,
  getCountGoodSerialisedItems,
  getCountBadSerialisedItems,
  getCountLatestGoodSerialisedItems,
  getCountLatestSerialisedItems,
  getPartData,
};
