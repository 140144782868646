import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Logo } from "../../assets/images/svgimages";
import auth from "../../api/auth/auth";
import DialogBox from "../../components/common/dialogBox";
import BackDrop from "../../components/common/backDrop";

const useStyles = makeStyles((theme) => ({
  login_page_main_container: {
    backgroundColor: `${theme.palette.color.background_color} !important`,
    width: "100%",
    height: "100%",
    display: "flex",
    overflow: "auto",
  },
  login_page_inputs_group_box: {
    width: "24.4%",
    margin: "auto",
    [theme.breakpoints.down("lg")]: {
      width: "40%",
    },
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  login_page_input_label: {
    color: `${theme.palette.color.text_color_white} !important`,
    marginBottom: "8px !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "15px !important",
    lineHeight: "16px !important",
  },
  login_page_input_error_message: {
    color: (props) => (props.otpSuccess ? "#F43454" : "#00944A"),
    marginTop: "5px !important",
  },
  login_page_input_field: {
    "& .MuiOutlinedInput-root": {
      "& input": {
        color: `rgba(0, 0, 0, 0.87) !important`,
        backgroundColor: "#FFFFFF !important",
        borderRadius: "8px !important",
        "&::placeholder": {
          color: "rgba(0, 0, 0, 0.6) !important",
          opacity: 1,
        },
      },
      "&.Mui-focused fieldset": {
        borderWidth: "0px !important",
      },
    },
    "& .MuiFormHelperText-root": {
      color: "#FFFFFF",
      marginLeft: "0px !important",
    },
  },
  login_page_password_input: {
    backgroundColor: "#FFFFFF !important",
    borderRadius: "8px !important",
    "& .MuiOutlinedInput-root": {
      "& input": {
        color: `rgba(0, 0, 0, 0.87) !important`,
        "&::placeholder": {
          color: "rgba(0, 0, 0, 0.6) !important",
          opacity: 1,
        },
      },
      "& input[type=password]": {
        color: `rgba(0, 0, 0, 0.6) !important`,
      },
      "&.Mui-focused fieldset": {
        borderWidth: "0px !important",
      },
    },
  },
  login_page_logo: {
    alignItems: "center",
    height: "96px",
    "& svg": {
      width: "100%",
    },
  },
  login_page_login_here_link_container: {
    justifyContent: "center",
    "& .MuiTypography-root": {
      "& a": {
        color: `${theme.palette.color.text_color_white} !important`,
        textDecoration: "none",
        marginLeft: "7px",
      },
    },
  },
  password_err_forget_pass_conainer: {
    display: "flex",
    flexDirection: "row !important",
    justifyContent: "space-between",
    alignItems: "end",
  },
  forgot_password: {
    "& a": {
      color: `${theme.palette.color.text_color_white} !important`,
      textDecoration: "none",
      whiteSpace: "nowrap",
    },
  },
  login_page_buttons: {
    "& button": {
      backgroundColor: `#606B8A !important`,
      color: "white !important",
      width: "100%",
      height: "50px",
      "&:hover": {
        backgroundColor: "#171B42 !important",
      },
    },
  },
}));

const Login = () => {
  const [otpSuccess, setOtpSuccess] = useState(true);
  const classes = useStyles({ otpSuccess });
  const [showButton, setButton] = useState(true);
  const [passwordValues, setPasswordValues] = useState({
    password: "",
    showPassword: false,
  });
  const [email, setEmail] = useState("");
  const [securityCode, setSecurityCode] = useState("");
  const [emailMissing, setEmailMissing] = useState("");
  const [passwordMissing, setPasswordMissing] = useState("");
  const [securityCodeMissing, setSecurityCodeMissing] = useState("");
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [openDailogBox, setOpenDailogBox] = useState(false);

  const navigate = useNavigate();

  const handlePasswordChange = (value) => {
    setPasswordValues({ ...passwordValues, password: value });
  };
  const handleClickShowPassword = () => {
    setPasswordValues({
      ...passwordValues,
      showPassword: !passwordValues.showPassword,
    });
  };

  const handleSubmitLogin = async () => {
    email ? setEmailMissing("") : setEmailMissing("email missing");
    passwordValues.password
      ? setPasswordMissing("")
      : setPasswordMissing("password missing");

    if (email && passwordValues.password) {
      try {
        setShowBackDrop(!showBackDrop);
        const res = await auth.login(email, passwordValues.password);
        if (res.status == 200) {
          setButton(false);
          setSecurityCodeMissing(res.data.message);
          setShowBackDrop(false);
        }
      } catch (error) {
        setShowBackDrop(false);
        if (error.response.status == 400) {
          var errData = error.response.data.error;
          errData.includes("email")
            ? setEmailMissing(errData)
            : errData.includes("password")
            ? setPasswordMissing(errData)
            : "";
        }
        if (error.response.status == 404) {
          setEmailMissing(error.response.data.message);
          setPasswordMissing(error.response.data.message);
        }
        if (error.response.status == 401) {
          setEmailMissing("");
          setPasswordMissing(error.response.data.message);
        }
      }
    }
  };

  const handleVerify = async () => {
    securityCode
      ? setSecurityCodeMissing("")
      : setSecurityCodeMissing("security code missing");
    if (securityCode) {
      try {
        setShowBackDrop(!showBackDrop);
        const res = await auth.otpVerification(securityCode);
        if (res.status == 200) {
          setSecurityCodeMissing(res.data.message);
          setOtpSuccess(false);
          setShowBackDrop(false);
          navigate("/");
        }
      } catch (error) {
        setShowBackDrop(false);
        if (error.response.status == 400) {
          var errData = error.response.data.error;
          errData.includes("otp") ? setSecurityCodeMissing(errData) : "";
        }
        if (error.response.status == 401) {
          setSecurityCodeMissing("otp expired");
          setOpenDailogBox(true);
          setButton(true);
        }
        if (error.response.status == 404) {
          setSecurityCodeMissing(error.response.data.message);
        }
      }
    }
  };

  return (
    <Box
      className={classes.login_page_main_container}
      onKeyUp={(e) => {
        if (e.key == "Enter") {
          showButton ? handleSubmitLogin() : handleVerify();
        }
      }}
    >
      <Stack
        spacing={3}
        direction="column"
        className={classes.login_page_inputs_group_box}
      >
        <Stack className={classes.login_page_logo}>
          <Logo className="white" />
        </Stack>
        {showButton ? (
          <>
            <Stack>
              <Typography className={classes.login_page_input_label}>
                Email
              </Typography>
              <TextField
                placeholder="Enter email"
                id="outlined-start-adornment"
                className={classes.login_page_input_field}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Typography className={classes.login_page_input_error_message}>
                {emailMissing}
              </Typography>
            </Stack>
            <Stack>
              <Typography className={classes.login_page_input_label}>
                Password
              </Typography>
              <FormControl
                variant="outlined"
                className={classes.login_page_password_input}
              >
                <OutlinedInput
                  id="outlined-adornment-password"
                  placeholder="Enter password"
                  type={passwordValues.showPassword ? "text" : "password"}
                  value={passwordValues.password}
                  onChange={(e) => {
                    handlePasswordChange(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {passwordValues.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Stack className={classes.password_err_forget_pass_conainer}>
                <Typography className={classes.login_page_input_error_message}>
                  {passwordMissing}
                </Typography>
                <Typography className={classes.forgot_password}>
                  <Link to="/account_recovery">Forgot Password?</Link>
                </Typography>
              </Stack>
            </Stack>
          </>
        ) : (
          <Stack>
            <Typography className={classes.login_page_input_label}>
              Security code
            </Typography>
            <TextField
              placeholder="Enter code"
              id="outlined-start-adornment"
              className={classes.login_page_input_field}
              value={securityCode}
              onChange={(e) => setSecurityCode(e.target.value)}
            />
            <Typography className={classes.login_page_input_error_message}>
              {securityCodeMissing}
            </Typography>
          </Stack>
        )}

        <Stack className={classes.login_page_buttons}>
          {showButton ? (
            <Button className={classes.signin} onClick={handleSubmitLogin}>
              Login
            </Button>
          ) : (
            <Button className={classes.verify} onClick={handleVerify}>
              Verify
            </Button>
          )}
        </Stack>

        <Stack
          direction="row"
          className={classes.login_page_login_here_link_container}
        >
          <Typography variant="subtitle1" color="rgba(255, 255, 255, 0.6)">
            Not Registered Yet?
            <Link to="/signup">{"Create an account"}</Link>
          </Typography>
        </Stack>
      </Stack>
      {openDailogBox ? (
        <DialogBox
          open={openDailogBox}
          setOpenDailogBox={setOpenDailogBox}
          text="the session has been expired please regenerate OTP code"
        />
      ) : (
        ""
      )}
      <BackDrop showBackDrop={showBackDrop} />
    </Box>
  );
};
export default Login;
