import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Container } from "@mui/material";
import AQ_DigForm0001 from "../../components/acquisitionForm/AQ_DigForm0001";
import AQ_DigForm0002 from "../../components/acquisitionForm/AQ_DigForm0002";
import AQ_DigForm0003 from "../../components/acquisitionForm/AQ_DigForm0003";
import AQ_DigForm0004 from "../../components/acquisitionForm/AQ_DigForm0004";
import AQ_DigForm0005 from "../../components/acquisitionForm/AQ_DigForm0005";
import AQ_DigForm0006 from "../../components/acquisitionForm/AQ_DigForm0006";
import BackDrop from "../../components/common/backDrop";
import formAPI from "../../api/aquisitionForm/form";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles(() => ({
  select_form_container: {
    paddingLeft: "30px",
    marginBottom: "20px",
  },
  title_text: {
    height: "50px",
    display: "flex",
    alignItems: "center",
    fontSize: "24px",
    fontWeight: 600,
    ["@media (max-width:425px)"]: {
      fontSize: "16px",
    },
  },
}));

const RecordForm = () => {
  const classes = useStyles();

  const [showBackDrop, setShowBackDrop] = useState(false);
  const [formName, setFormName] = useState(null);
  const [showSuccessMsg, setShowSuccessMsg] = useState("");
  const [showError, setShowError] = useState(false);

  const [sp] = useSearchParams();
  let token = sp.get("token");
  const [companyName, setCompanyName] = useState("");
  const [operatorList, setOperatorList] = useState([]);
  const [dailyWeeklycheckList2, setDailyWeeklycheckList2] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await formAPI.getUserFormWithToken(token);
        setFormName(data.form_reference);
        setCompanyName(data.client_company);
        setOperatorList(data.operators);
        setDailyWeeklycheckList2(data.dailyWeeklycheckList);
      } catch (error) {
        setShowError(true);
      }
    };
    fetchData();
  }, [token]);

  return (
    <Box>
      <Box className={classes.select_form_container}>
        <Container
          sx={{ textAlign: "center", fontSize: "30px", fontWeight: "700" }}
        >
          {companyName}
        </Container>
      </Box>

      <Container sx={{ textAlign: "center", color: "green" }}>
        {showSuccessMsg && <h2>Saved {showSuccessMsg} data successfully </h2>}
      </Container>

      {showError ? (
        <Container sx={{ textAlign: "center", color: "red" }}>
          Unauthorised access! Please contact admin.
        </Container>
      ) : (
        ""
      )}

      <Container sx={{ width: "100%", minHeight: "65vh" }}>
        {(() => {
          switch (formName) {
            case "AQ_DigForm0001":
              return (
                <AQ_DigForm0001
                  setFormName={setFormName}
                  setShowSuccessMsg={setShowSuccessMsg}
                  operators={operatorList}
                  token={token}
                />
              );
            case "AQ_DigForm0002":
              return (
                <AQ_DigForm0002
                  setFormName={setFormName}
                  setShowSuccessMsg={setShowSuccessMsg}
                  operators={operatorList}
                  token={token}
                />
              );
            case "AQ_DigForm0003":
              return (
                <AQ_DigForm0003
                  setFormName={setFormName}
                  setShowSuccessMsg={setShowSuccessMsg}
                  operators={operatorList}
                  token={token}
                />
              );
            case "AQ_DigForm0004":
              return (
                <AQ_DigForm0004
                  setFormName={setFormName}
                  setShowSuccessMsg={setShowSuccessMsg}
                  operators={operatorList}
                  dailyWeeklycheckList2={dailyWeeklycheckList2}
                  token={token}
                />
              );
            case "AQ_DigForm0005":
              return (
                <AQ_DigForm0005
                  setFormName={setFormName}
                  setShowSuccessMsg={setShowSuccessMsg}
                  operators={operatorList}
                  dailyWeeklycheckList2={dailyWeeklycheckList2}
                  token={token}
                />
              );
            case "AQ_DigForm0006":
              return (
                <AQ_DigForm0006
                  setFormName={setFormName}
                  setShowSuccessMsg={setShowSuccessMsg}
                  operators={operatorList}
                  dailyWeeklycheckList2={dailyWeeklycheckList2}
                  token={token}
                />
              );
            default:
              return null;
          }
        })()}
      </Container>
      <BackDrop showBackDrop={showBackDrop} />
    </Box>
  );
};

export default RecordForm;
