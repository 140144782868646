import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Logo } from "../../assets/images/svgimages";
import VerifyEmail from "../../components/accountRecovery/verifyEmail copy";
import VerifyOtp from "../../components/accountRecovery/verifyOtp";
import UpdatePassword from "../../components/accountRecovery/updatePassword";

const useStyles = makeStyles((theme) => ({
  main_container: {
    backgroundColor: `${theme.palette.color.background_color} !important`,
    width: "100%",
    height: "100%",
    display: "flex",
    overflow: "auto",
  },
  inputs_group_box: {
    width: "24.4%",
    margin: "auto",
    [theme.breakpoints.down("lg")]: {
      width: "40%",
    },
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  logo: {
    alignItems: "center",
    height: "96px",
    "& svg": {
      width: "100%",
    },
  },
  link_container: {
    justifyContent: "center",
    "& .MuiTypography-root": {
      "& a": {
        color: `${theme.palette.color.text_color_white} !important`,
        textDecoration: "none",
        marginLeft: "7px",
      },
    },
  },
}));

const AccountRecovery = () => {
  const classes = useStyles();
  const [section, setSection] = useState("email");

  return (
    <Box className={classes.main_container}>
      <Stack
        spacing={3}
        direction="column"
        className={classes.inputs_group_box}
      >
        <Stack className={classes.logo}>
          <Logo className="white" />
        </Stack>

        {(() => {
          switch (section) {
            case "email":
              return <VerifyEmail setSection={setSection} />;
            case "otp":
              return <VerifyOtp setSection={setSection} />;
            case "updatePassword":
              return <UpdatePassword setSection={setSection} />;
            default:
              return null;
          }
        })()}

        <Stack direction="row" className={classes.link_container}>
          <Typography variant="subtitle1" color="rgba(255, 255, 255, 0.6)">
            Already have an account?
            <Link to="/login">Login here</Link>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
export default AccountRecovery;
