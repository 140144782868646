import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Logo } from "../../assets/images/svgimages";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import auth from "../../api/auth/auth";
import DialogBox from "../../components/common/dialogBox";
import BackDrop from "../../components/common/backDrop";

const useStyles = makeStyles((theme) => ({
  signup_page_main_container: {
    backgroundColor: `${theme.palette.color.background_color} !important`,
    width: "100%",
    height: "100%",
    display: "flex",
    overflow: "auto",
  },
  signup_page_inputs_group_box: {
    width: "24.4%",
    margin: "auto",
    [theme.breakpoints.down("lg")]: {
      width: "40%",
    },
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  signup_page_input_label: {
    color: `${theme.palette.color.text_color_white} !important`,
    marginBottom: "8px !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "15px !important",
    lineHeight: "16px !important",
  },
  signup_page_input_error_message: {
    color: "#F43454",
    marginTop: "5px !important",
  },
  signup_page_first_last_name_input_container: {
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column !important",
      marginTop: "0px !important",
    },
    "& > *": {
      width: "45%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginTop: "24px",
      },
    },
  },
  signup_page_input_field: {
    "& .MuiOutlinedInput-root": {
      "& input": {
        color: `rgba(0, 0, 0, 0.87) !important`,
        backgroundColor: "#FFFFFF !important",
        borderRadius: "8px !important",
        "&::placeholder": {
          color: "rgba(0, 0, 0, 0.6) !important",
          opacity: 1,
        },
      },
      "&.Mui-focused fieldset": {
        borderWidth: "0px !important",
      },
    },
    "& .MuiFormHelperText-root": {
      color: "#FFFFFF",
      marginLeft: "0px !important",
    },
  },
  signup_page_password_input: {
    backgroundColor: "#FFFFFF !important",
    borderRadius: "8px !important",
    "& .MuiOutlinedInput-root": {
      "& input": {
        color: `rgba(0, 0, 0, 0.87) !important`,
        "&::placeholder": {
          color: "rgba(0, 0, 0, 0.6) !important",
          opacity: 1,
        },
      },
      "& input[type=password]": {
        color: `rgba(0, 0, 0, 0.6) !important`,
      },
      "&.Mui-focused fieldset": {
        borderWidth: "0px !important",
      },
    },
  },
  signup_page_logo: {
    alignItems: "center",
    height: "96px",
    "& svg": {
      width: "100%",
    },
  },
  signup_page_signin_user_img: {
    marginBottom: "10px",
  },
  signup_page_with_email_container: {
    textAlign: "center",
    margin: "36px 0px 36px 0px !important",
  },
  signup_page_checkbox_forget_container: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  signup_page_bottom_create_account_link: {
    justifyContent: "center",
    "& .MuiTypography-root": {
      "& a": {
        color: `${theme.palette.color.text_color_white} !important`,
        textDecoration: "none",
        marginLeft: "7px",
      },
    },
  },
  signup_page_signin_button: {
    backgroundColor: `#606B8A !important`,
    color: "white !important",
    width: "100%",
    height: "50px",
    "&:hover": {
      backgroundColor: "#171B42 !important",
    },
  },
}));

const Signup = () => {
  const classes = useStyles();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [clientID, setClientID] = useState("");
  const [passwordValues, setPasswordValues] = useState({
    password: "",
    showPassword: false,
  });
  const [confirmPasswordValues, setConfirmPasswordValues] = useState({
    confirmPassword: "",
    showConfirmPassword: false,
  });
  const [firstNameMissing, setFirstNameMissing] = useState("");
  const [lastNameMissing, setLastNameMissing] = useState("");
  const [emailMissing, setEmailMissing] = useState("");
  const [passwordMissing, setPasswordMissing] = useState("");
  const [confirmPassMissing, setConfirmPassMissing] = useState("");
  const [clientIDMissing, setClientIDMissing] = useState("");
  const [openDailogBox, setOpenDailogBox] = useState(false);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [signupMessage, setSignupMessage] = useState("message");

  const handlePasswordChange = (value) => {
    setPasswordValues({ ...passwordValues, password: value });
  };

  const handleClickShowPassword = () => {
    setPasswordValues({
      ...passwordValues,
      showPassword: !passwordValues.showPassword,
    });
  };

  const handleConfirmPasswordChange = (value) => {
    setConfirmPasswordValues({
      ...confirmPasswordValues,
      confirmPassword: value,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setConfirmPasswordValues({
      ...confirmPasswordValues,
      showConfirmPassword: !confirmPasswordValues.showConfirmPassword,
    });
  };

  const handleSubmitSignup = async () => {
    firstName
      ? setFirstNameMissing("")
      : setFirstNameMissing("first name missing");
    lastName ? setLastNameMissing("") : setLastNameMissing("last name missing");
    email ? setEmailMissing("") : setEmailMissing("email missing");
    clientID ? setClientIDMissing("") : setClientIDMissing("client ID missing");
    passwordValues.password
      ? setPasswordMissing("")
      : setPasswordMissing("password missing");
    confirmPasswordValues.confirmPassword
      ? setConfirmPassMissing("")
      : setConfirmPassMissing("confirm password missing");
    if (passwordValues.password !== confirmPasswordValues.confirmPassword) {
      setPasswordMissing("password should match with confirm password");
      setConfirmPassMissing("confirm password should match with password");
    }

    if (
      firstName &&
      lastName &&
      email &&
      clientID &&
      passwordValues.password &&
      confirmPasswordValues.confirmPassword &&
      passwordValues.password == confirmPasswordValues.confirmPassword
    ) {
      try {
        setShowBackDrop(!showBackDrop);
        const res = await auth.signup(
          firstName,
          lastName,
          email,
          passwordValues.password,
          confirmPasswordValues.confirmPassword,
          clientID
        );
        if (res.status == 200) {
          setSignupMessage(res.data.message);
          setShowBackDrop(false);
          setOpenDailogBox(true);
        }
      } catch (error) {
        setShowBackDrop(false);
        if (error.response.status == 400) {
          var errData = error.response.data.error;
          errData.includes("email")
            ? setEmailMissing(errData)
            : errData.includes("password")
            ? setPasswordMissing(errData)
            : errData.includes("clientID")
            ? setClientIDMissing(errData)
            : "";
        }
        if (error.response.status == 404) {
          setEmailMissing(error.response.data.message);
          setClientIDMissing(error.response.data.message);
        }
        if (error.response.status == 500) {
          alert("System has an internel error. Please contact with admin");
        }
      }
    }
  };
  return (
    <Box className={classes.signup_page_main_container}>
      <Stack
        spacing={3}
        direction="column"
        className={classes.signup_page_inputs_group_box}
      >
        <Stack className={classes.signup_page_logo}>
          <Logo className="white" />
        </Stack>

        <Stack
          direction="row"
          className={classes.signup_page_first_last_name_input_container}
        >
          <Stack>
            <Typography className={classes.signup_page_input_label}>
              First name
            </Typography>
            <TextField
              placeholder="Enter first name"
              value={firstName}
              id="fname"
              className={classes.signup_page_input_field}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <Typography className={classes.signup_page_input_error_message}>
              {firstNameMissing}
            </Typography>
          </Stack>
          <Stack>
            <Typography className={classes.signup_page_input_label}>
              Last name
            </Typography>
            <TextField
              placeholder="Enter last name"
              value={lastName}
              id="lname"
              className={classes.signup_page_input_field}
              onChange={(e) => setLastName(e.target.value)}
            />
            <Typography className={classes.signup_page_input_error_message}>
              {lastNameMissing}
            </Typography>
          </Stack>
        </Stack>

        <Stack>
          <Typography className={classes.signup_page_input_label}>
            Email
          </Typography>
          <TextField
            placeholder="Enter email"
            value={email}
            id="email"
            className={classes.signup_page_input_field}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Typography className={classes.signup_page_input_error_message}>
            {emailMissing}
          </Typography>
        </Stack>
        <Stack className="relative">
          <Typography className={classes.signup_page_input_label}>
            Password
          </Typography>
          <FormControl
            variant="outlined"
            className={classes.signup_page_password_input}
          >
            <OutlinedInput
              id="password"
              placeholder="Enter password"
              type={passwordValues.showPassword ? "text" : "password"}
              value={passwordValues.password}
              onChange={(e) => {
                handlePasswordChange(e.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {passwordValues.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Typography className={classes.signup_page_input_error_message}>
            {passwordMissing}
          </Typography>
          <Typography className="password-help" variant="div">
            <ul>
              <li>Password must be between 8 and 20 characters in length</li>
              <li>Password must contain at least 1 upper case letter</li>
              <li>Password must contain at least 1 lower case letter</li>
              <li>Password must contain at least one number</li>
              <li>Password must contain at least one symbol</li>
            </ul>
          </Typography>
        </Stack>
        <Stack>
          <Typography className={classes.signup_page_input_label}>
            Confirm Password
          </Typography>
          <FormControl
            variant="outlined"
            className={classes.signup_page_password_input}
          >
            <OutlinedInput
              id="confirmPassword"
              placeholder="Enter password"
              type={
                confirmPasswordValues.showConfirmPassword ? "text" : "password"
              }
              value={confirmPasswordValues.confirmPassword}
              onChange={(e) => {
                handleConfirmPasswordChange(e.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    edge="end"
                  >
                    {confirmPasswordValues.showConfirmPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Typography className={classes.signup_page_input_error_message}>
            {confirmPassMissing}
          </Typography>
        </Stack>
        <Stack>
          <Typography className={classes.signup_page_input_label}>
            Client ID
          </Typography>
          <TextField
            placeholder="Enter client ID"
            value={clientID}
            id="client_id"
            className={classes.signup_page_input_field}
            onChange={(e) => setClientID(e.target.value)}
          />
          <Typography className={classes.signup_page_input_error_message}>
            {clientIDMissing}
          </Typography>
        </Stack>
        <Stack>
          <Button
            className={classes.signup_page_signin_button}
            onClick={handleSubmitSignup}
          >
            Signup
          </Button>
        </Stack>

        <Stack
          direction="row"
          className={classes.signup_page_bottom_create_account_link}
        >
          <Typography variant="subtitle1" color="rgba(255, 255, 255, 0.6)">
            Already have an account?
            <Link to="/login">{"Login here"}</Link>
          </Typography>
        </Stack>
      </Stack>
      {openDailogBox ? (
        <DialogBox
          open={openDailogBox}
          setOpenDailogBox={setOpenDailogBox}
          text={signupMessage}
        />
      ) : (
        ""
      )}
      <BackDrop showBackDrop={showBackDrop} />
    </Box>
  );
};
export default Signup;
