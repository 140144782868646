import React, { useState } from "react";

const RightSideBarLocationDetail = (props) => {
  const show = props.showRightBar;
  const setShow = props.setShowRightBar;
  const items = props.items;
  const name = props.name;
  const [filter, setFilter] = useState("");

  const onChange = (e) => {
    setFilter(e.target.value);
  };

  const test_filter = (r) => {
    if (filter.length == 0) return true;
    if (r.jc_id.includes(filter)) return true;
    return false;
  };

  const handleDialogOpen = (item) => {
    window.location = "/report/job-card/" + item.jc_id;
  };

  return show ? (
    <div
      className="report-right-side-serialised-items-bar"
      onClick={(e) => {
        if (
          e.target.classList.contains("report-right-side-serialised-items-bar")
        )
          setShow(false);
      }}
    >
      <div>
        <h4 className="head">Count of Locations Measured across 5 Features</h4>
        <div className="right-sidebar-inner">
          <div className="rsblocation-legend">
            <span>n</span> = Number of Locations
          </div>
          <div>
            <img style={{ width: "100%" }} src="/cl1.png" />
          </div>
          <div>
            <img style={{ width: "100%" }} src="/cl2.png" />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
export default RightSideBarLocationDetail;
