import React, { useState } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import DisclaimerDialog from "../common/disclaimerDialog";

const Footer = () => {
  const [show, setShow] = useState(false);
  return (
    <Box textAlign="center" component="footer">
      <Typography variant="subtitle1" sx={{ margin: "10px 0 10px 0" }}>
        &copy; 2024 AddQual, Version 1.0, All Rights Reserved,{" "}
        <a href="#" className="no-formatting" onClick={() => setShow(true)}>
          Read Disclaimer
        </a>
      </Typography>
      <DisclaimerDialog showRightBar={show} setShowRightBar={setShow} />
    </Box>
  );
};
export default Footer;
