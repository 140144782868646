import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Container,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import formAPI from "../../api/aquisitionForm/form";
import BackDrop from "../common/backDrop";

const useStyles = makeStyles(() => ({
  form_page_header: {
    fontSize: "38px",
    color: "white",
    backgroundColor: "#333D94",
    padding: "10px",
    marginBottom: "20px",
    ["@media (max-width:425px)"]: {
      fontSize: "18px",
    },
  },
  form_inner_header: {
    border: "1px solid gray",
    borderRadius: "15px",
    marginBottom: "32px",
  },
  datepicker_main_container: {
    "& .react-datepicker__tab-loop": {
      "& .react-datepicker-popper": {
        zIndex: "2",
      },
    },
  },
  datepicker: {
    height: "57px",
    fontSize: "20px",
    paddingLeft: "10px",
    ["@media (max-width:425px)"]: {
      fontSize: "14px",
    },
  },
  title_text: {
    height: "50px",
    display: "flex",
    alignItems: "center",
    fontSize: "24px",
    fontWeight: 600,
    ["@media (max-width:425px)"]: {
      fontSize: "16px",
    },
  },
  submit_button: {
    width: "200px",
    height: "60px",
    backgroundColor: "#333D94 !important",
    marginBottom: "32px !important",
    color: "white !important",
  },
}));

const AQ_DigForm0003 = (props) => {
  const { setFormName, setShowSuccessMsg, operators, token } = props;
  const classes = useStyles();
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [defaultOperator, setDefaultOperator] = useState(
    "Please Select Operator"
  );
  const [operatorList, setOperatorList] = useState([]);
  const [formData, setFormData] = useState({
    form_id: 3,
    form_name: "Shell Slurry Back Up",
    form_reference: "AQ_DigForm0003",
    date: new Date(),
    operator: "",
    temperature: "",
    viscosity: "",
    PH: "",
    density: "",
    siO2: "",
    bacteria: "",
  });

  useEffect(() => {
    const getOperators = async () => {
      try {
        setShowSuccessMsg("");
        const operatorList = await formAPI.getOperators();
        setOperatorList(operatorList);
      } catch (error) {
        alert(error.msg);
      }
    };
    if (operators == null) getOperators();
    else setOperatorList(operators);
  }, [setShowSuccessMsg, operators]);

  const handleSubmit = async () => {
    try {
      setShowBackDrop(true);
      let url = "";
      if (token != null) {
        url = "/save_user_form_with_token?token=" + token;
      }
      await formAPI.submitShellSlurryData(formData, url);
      setErrorMsg("");
      setShowBackDrop(false);
      setShowSuccessMsg("Shell Slurry Back Up");
      setFormName(null);
    } catch (error) {
      setErrorMsg(error.msg);
      setShowBackDrop(false);
    }
  };

  return (
    <>
      <Box className={classes.form_page_header}>Shell Slurry Back Up</Box>
      <Stack spacing={4} className={classes.form_inner_header}>
        <Container className={classes.datepicker_main_container}>
          <Box className={classes.title_text}>1.Date *</Box>
          <DatePicker
            className={classes.datepicker}
            selected={formData.date}
            onChange={(date) => {
              setFormData({ ...formData, date: date });
            }}
          />
        </Container>

        <Container>
          <Box className={classes.title_text}>4.Operator *</Box>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            error={errorMsg == "operator" ? true : false}
            value={defaultOperator}
            onChange={(e) => setDefaultOperator(e.target.value)}
          >
            <MenuItem value="Please Select Operator">
              Please Select Operator
            </MenuItem>
            {operatorList &&
              operatorList.map((data) => (
                <MenuItem
                  key={data.id}
                  value={data.id}
                  onClick={() => {
                    formData.operator = data.operator_name;
                  }}
                >
                  {data.operator_name}
                </MenuItem>
              ))}
          </Select>
        </Container>

        <Container>
          <Box className={classes.title_text}>5.Temperature (Deg C) *</Box>
          <TextField
            error={errorMsg == "temperature" ? true : false}
            label="Min 19 and Max 26"
            type="number"
            placeholder="The value must be a number"
            helperText={errorMsg == "temperature" ? "Incorrect entry" : ""}
            sx={{ width: "100%" }}
            variant="outlined"
            onChange={(e) => {
              formData.temperature = e.target.value;
            }}
          />
        </Container>

        <Container>
          <Box className={classes.title_text}>6.Viscosity (Secs) *</Box>
          <TextField
            error={errorMsg == "viscosity" ? true : false}
            label="This field is required"
            type="number"
            placeholder="The value must be a number"
            helperText={errorMsg == "viscosity" ? "Incorrect entry" : ""}
            sx={{ width: "100%" }}
            variant="outlined"
            onChange={(e) => {
              formData.viscosity = e.target.value;
            }}
          />
        </Container>

        <Container>
          <Box className={classes.title_text}>7.PH Level *</Box>
          <TextField
            error={errorMsg == "PH" ? true : false}
            label="This field is required"
            type="number"
            placeholder="The value must be a number"
            helperText={errorMsg == "PH" ? "Incorrect entry" : ""}
            sx={{ width: "100%" }}
            variant="outlined"
            onChange={(e) => {
              formData.PH = e.target.value;
            }}
          />
        </Container>

        <Container>
          <Box className={classes.title_text}>8.Density (g/ml) *</Box>
          <TextField
            error={errorMsg == "density" ? true : false}
            label="This field is required"
            type="number"
            placeholder="The value must be a number"
            helperText={errorMsg == "density" ? "Incorrect entry" : ""}
            sx={{ width: "100%" }}
            variant="outlined"
            onChange={(e) => {
              formData.density = e.target.value;
            }}
          />
        </Container>

        <Container>
          <Box className={classes.title_text}>9.SiO2 (% g) *</Box>
          <TextField
            error={errorMsg == "siO2" ? true : false}
            label="This field is required"
            type="number"
            placeholder="The value must be a number"
            helperText={errorMsg == "siO2" ? "Incorrect entry" : ""}
            sx={{ width: "100%" }}
            variant="outlined"
            onChange={(e) => {
              formData.siO2 = e.target.value;
            }}
          />
        </Container>

        <Container>
          <Box className={classes.title_text}>10.Bacteria Test (Level) *</Box>
          <TextField
            error={errorMsg == "bacteria" ? true : false}
            label="Min 1 and Max 5"
            type="number"
            // InputProps={{ inputProps: { min: 1, max: 5 } }}
            placeholder="The value must be a number"
            helperText={errorMsg == "bacteria" ? "Incorrect entry" : ""}
            sx={{ width: "100%" }}
            variant="outlined"
            onChange={(e) => {
              formData.bacteria = e.target.value;
            }}
          />
        </Container>

        <Container sx={{ textAlign: "center" }}>
          <Button className={classes.submit_button} onClick={handleSubmit}>
            Submit
          </Button>
        </Container>
      </Stack>
      <BackDrop showBackDrop={showBackDrop} />
    </>
  );
};

export default AQ_DigForm0003;
