import React from "react";
import { Card, CardContent, Chip, Grid } from "@mui/material";
import { Tile1Svg } from "../../assets/images/report_page_svg_images/tile1Svg";
import ReportCardTile from "./report-card-tile";
import LayersIcon from "@mui/icons-material/Layers";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ReplayIcon from "@mui/icons-material/Replay";

const OverviewSerializedDetails1 = (props) => {
  const serialItemDetails = props.serialItemDetails;
  const setShowRightBar = props.setShowRightBar;

  const fill = props.fill;
  const color = props.color;

  const yield_color = (val) => {
    return val == "GOOD" ? "#d2f4d7" : "#ff8b95";
  };

  const yieldf_color = (val) => {
    return val == "GOOD" ? "#128320" : "#870e0e";
  };
  return (
    <Grid item xl={4} lg={4} sm={4} xs={12}>
      <Card>
        <CardContent className="padding-0">
          <div className="overview-details-1">
            <Tile1Svg fill={fill} color={color} />
            <div className="tile1-content-container" style={{ color: color }}>
              <button
                className="float-right view-all-btn"
                onClick={() => setShowRightBar(true)}
              >
                View All
              </button>
              <h1 className="tile-1-heading">
                {serialItemDetails ? serialItemDetails.serial_no : ""}{" "}
                {serialItemDetails ? (
                  <Chip
                    label={serialItemDetails.cycle2_umr ? "UMR" : "GOOD"}
                    color="success"
                    style={{
                      backgroundColor: yield_color(
                        serialItemDetails.cycle2_umr ? "UMR" : "GOOD"
                      ),
                      color: yieldf_color(
                        serialItemDetails.cycle2_umr ? "UMR" : "GOOD"
                      ),
                      fontWeight: "bold",
                      width: "90px",
                    }}
                  />
                ) : null}
              </h1>

              <div className="tile-1-sub-heading">Serial No</div>

              <Grid container className="tile1-extra-details">
                <Grid item xs={4}>
                  <h2>{serialItemDetails ? serialItemDetails.jc_id : ""}</h2>
                  <span>Batch</span>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="overview-serialized-item-sub-details">
            <div className="worst-item-inner">
              <div className="worst-item-inner-text-side">
                <h2>
                  {serialItemDetails ? serialItemDetails.part_number : ""}
                </h2>
                <span>Part Number</span>
              </div>
              <div className="worst-item-inner-text-side">
                <h2>{serialItemDetails ? serialItemDetails.grn_id : ""}</h2>
                <span>GRN ID</span>
              </div>
              <div className="worst-item-inner-text-side">
                <h2>{serialItemDetails ? serialItemDetails.project : ""}</h2>
                <span>Project Number</span>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Additional Data */}
      <Card>
        <CardContent className="">
          <ReportCardTile
            tile_bg_color={"#E6E6EB"}
            color="#0D1444"
            icon={<LayersIcon fontSize="large" color="#000" />}
            main_heading={props.measurement_stages}
            sub_heading="Total Measurement stages"
          />
          <ReportCardTile
            tile_bg_color={"#E6E6EB"}
            color="#0D1444"
            icon={<FormatListBulletedIcon fontSize="large" color="#000" />}
            main_heading={props.noOfFeatures}
            sub_heading="No. of Features Measured"
            click={
              props.noOfFeatures == 5
                ? () => props.setLocationDetailsOpen(true)
                : null
            }
          />
          <ReportCardTile
            tile_bg_color={"#E6E6EB"}
            color="#0D1444"
            icon={<ReplayIcon fontSize="large" color="#000" />}
            main_heading={"N/A"}
            sub_heading="No of Reworks"
          />
          <div className="custom-height-sip"></div>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default OverviewSerializedDetails1;
