import axios from "axios";
import serverUrl from "../serverUrl";
axios.defaults.withCredentials = true;
var url = serverUrl;

const getForms = async () => {
  try {
    const response = await axios.get(`${url}/acquisition_form`);
    return response.data.data;
  } catch (error) {
    throw { msg: "Server error please try again later!" };
  }
};

const getUserFormWithToken = async (token) => {
  try {
    const response = await axios.post(`${url}/get_user_form_with_token`, {
      token: token,
    });
    return response.data.data;
  } catch (error) {
    throw { msg: "Server error please try again later!" };
  }
};

const getOperators = async () => {
  try {
    const response = await axios.get(`${url}/acquisition_form/operators`);
    return response.data.data;
  } catch (error) {
    throw { msg: "Server error please try again later!" };
  }
};

const getAddQualOperators = async () => {
  try {
    const response = await axios.get(
      `${url}/acquisition_form/addqual_operators`
    );
    return response.data.data;
  } catch (error) {
    throw { msg: "Server error please try again later!" };
  }
};

const getAllParts = async () => {
  try {
    const response = await axios.get(`${url}/acquisition_form/all_parts`);
    return response.data.data;
  } catch (error) {
    throw { msg: "Server error please try again later!" };
  }
};

const getProjectList = async (part) => {
  try {
    const response = await axios.get(
      `${url}/acquisition_form/project_list?part=${part}`
    );
    return response.data.data;
  } catch (error) {
    throw { msg: "Server error please try again later!" };
  }
};

const dailyWeeklyCheck = async (form_reference) => {
  try {
    const response = await axios.get(
      `${url}/acquisition_form/daily_weekly_check`,
      {
        params: {
          form_reference: form_reference,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    throw { msg: "Server error please try again later!" };
  }
};

const submitShellSlurryData = async (newData, newUrl = "") => {
  try {
    const data = {
      form_type: "ShellSlurry",
      form_id: newData.form_id,
      form_name: newData.form_name,
      form_reference: newData.form_reference,
      date: newData.date,
      operator: newData.operator,
      temperature: newData.temperature,
      viscosity: newData.viscosity,
      PH: newData.PH,
      density: newData.density,
      siO2: newData.siO2,
      bacteria: newData.bacteria,
    };

    const minMaxValues = {
      temperature: { min: 19, max: 26 },
      bacteria: { min: 1, max: 5 },
    };

    for (const [key, value] of Object.entries(data)) {
      if (!value) {
        throw { msg: key };
      }

      if (minMaxValues[key]) {
        const { min, max } = minMaxValues[key];
        if (value < min || value > max) {
          throw { msg: key };
        }
      }
    }
    let full_url = url;
    if (newUrl.length > 1) full_url += newUrl;
    else full_url += "/acquisition_form/submit_form_data";
    const response = await axios.post(full_url, data);
    return response.data.data;
  } catch (error) {
    if (error.response && error.response.status == 400) {
      throw { msg: error.response.data.error };
    } else {
      throw error;
    }
  }
};

const submitMetrologyData = async (newData, newUrl = "") => {
  try {
    var data = {
      form_type: "Metrology",
      form_id: newData.form_id,
      form_name: newData.form_name,
      form_reference: newData.form_reference,
      date: newData.date,
      operator: newData.operator,
      temperature1: newData.temperature1,
      temperature2: newData.temperature2,
    };

    const minMaxValues = {
      temperature1: { min: 19, max: 26 },
      temperature2: { min: 19, max: 26 },
    };

    for (const [key, value] of Object.entries(data)) {
      if (!value) {
        throw { msg: key, type: newData.form };
      }

      if (minMaxValues[key]) {
        const { min, max } = minMaxValues[key];
        if (value < min || value > max) {
          throw { msg: key, type: newData.form };
        }
      }
    }
    data.weekly_checks = newData.weekly_checks ? newData.weekly_checks : false;
    data.daily_checks = newData.daily_checks ? newData.daily_checks : false;
    let full_url = url;
    if (newUrl.length > 1) full_url += newUrl;
    else full_url += "/acquisition_form/submit_form_data";
    const response = await axios.post(full_url, data);
    return response.data.data;
  } catch (error) {
    if (error.response && error.response.status == 400) {
      throw { msg: error.response.data.error };
    } else {
      throw error;
    }
  }
};

const submit3cData = async (newData) => {
  try {
    const data = newData;
    const minMaxValues = {
      quantity: { min: 1, max: 100 },
    };

    const lenValues = {
      area: { min: 1 },
      issue_type: { min: 1 },
      issue_files: { min: 0 },
      containment_files: { min: 0 },
    };

    for (const [key, value] of Object.entries(data)) {
      if (key == "containment_action" || key == "countermeasure_action")
        continue; // optional field !
      if (!value) {
        throw { msg: key };
      }

      if (minMaxValues[key]) {
        const { min, max } = minMaxValues[key];
        if (value < min || value > max) {
          throw { msg: key };
        }
      }

      if (lenValues[key]) {
        if (value.length < lenValues[key].min) throw { msg: key };
      }
    }
    let multidata = new FormData();
    multidata.append("fields", JSON.stringify(data));

    for (let i = 0; i < data.issue_files.length; i++)
      multidata.append("issue_files", data.issue_files[i].file);

    for (let i = 0; i < data.containment_files.length; i++)
      multidata.append("containment_files", data.containment_files[i].file);

    const response = await axios.post(
      `${url}/acquisition_form/save_3c_form_data`,
      multidata,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status == 400) {
      throw { msg: error.response.data.error };
    } else {
      throw error;
    }
  }
};

export default {
  getForms,
  getOperators,
  getAddQualOperators,
  getAllParts,
  getProjectList,
  dailyWeeklyCheck,
  submitShellSlurryData,
  submitMetrologyData,
  submit3cData,
  getUserFormWithToken,
};
