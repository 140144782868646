import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import App from "./App";
import Theme from "./theme";

function Root() {
  const [hide_pre_content, set_hide_pre_content] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      set_hide_pre_content(false);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="inner_root_container">
      {hide_pre_content ? <div className="hide-pre-content"></div> : null}
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.createRoot(rootElement).render(<Root />);
