import axios from "axios";
import serverUrl from "../serverUrl";
axios.defaults.withCredentials = true;
var url = serverUrl;

const parts = async () => {
  const response = await axios.get(`${url}/visualise/status_parts`);
  return response.data.data;
};

const partDetails = async (part_number) => {
  const response = await axios.get(`${url}/visualise/status_part_details`, {
    params: {
      part: part_number,
    },
  });
  return response.data.data;
};

export default {
  parts,
  partDetails,
};
