import React from "react";
import { Grid } from "@mui/material";
import { Tile1Svg } from "../../assets/images/report_page_svg_images/tile1Svg";

const OverviewDetailsPart = (props) => {
  const jobCardDetails = props.partDetails;
  const fill = props.fill;
  const color = props.color;
  return (
    <Grid item xl={4} lg={4} sm={4} xs={12}>
      <div className="overview-details-1">
        <Tile1Svg fill={fill} color={color} />
        <div className="tile1-content-container" style={{ color: color }}>
          <h1 className="tile-1-heading">
            {jobCardDetails ? jobCardDetails.jc_id : ""}
          </h1>

          <Grid container className="tile1-extra-details">
            <Grid item xs={12} className="pb-2">
              <h3>{jobCardDetails ? jobCardDetails.part_number : ""}</h3>
              <span>Part No.</span>
            </Grid>
            <Grid item xs={9}>
              <h3 title={jobCardDetails ? jobCardDetails.description : ""}>
                {jobCardDetails ? jobCardDetails.description.substr(0, 30) : ""}
              </h3>
              <span>Part Description</span>
            </Grid>
            <Grid item xs={3}>
              <div style={{ width: "122px" }}>
                <h3>
                  {props.batches ? props.batches : ""}
                  {props.viewBtn != "hide" ? (
                    <div
                      className="float-right view-all-btn view-all-btn2"
                      onClick={() => props.setShowRightBar(true)}
                    >
                      View Batches
                    </div>
                  ) : null}
                </h3>
                <span>No. of Batches</span>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </Grid>
  );
};
export default OverviewDetailsPart;
